<template>
  <!-- Desktop -->
  <div
    class="hidden flex-col items-center justify-start self-stretch rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)] md:flex"
  >
    <!-- Model Avatar, Name, Share Button and Model Action Button -->
    <div
      class="flex w-full flex-1 items-center justify-start gap-2 border-b-2 border-slate-100 pb-2"
    >
      <!-- Model Avatar -->
      <img :src="modelImageSrc" @error="onModelImageError" class="h-10 w-10 rounded-md" />

      <!-- Model Name -->
      <div class="inline-flex flex-col pl-2 font-semibold text-slate-700">
        <div class="text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400">
          AI Model
        </div>
        <div class="text-bold pb-1 text-[14px]">
          {{ model.name }}
        </div>
      </div>

      <!-- Share Button -->
      <div class="flex flex-1 items-center justify-end gap-2">
        <Button
          variant="text"
          @click="showShareModal = true"
          class="flex items-center justify-start gap-1 overflow-hidden !py-0 px-0 hover:bg-transparent"
        >
          <i class="pi pi-share-alt text-xs text-slate-500"></i>
          <div class="text-xs font-medium leading-none text-slate-700">Share</div>
        </Button>
      </div>

      <!-- Model Action Button -->
      <div class="flex items-center justify-end gap-4">
        <ModelActionButton :model="model" btnClass="h-[32px] px-7" />
      </div>
    </div>

    <!-- Model builder, event sub type, Badges and "How This Model Works" button -->
    <div class="mb-4 flex min-h-[70px] items-center justify-between gap-5 self-stretch py-1 pt-2">
      <!-- Event sub type -->
      <div class="flex">
        <div
          class="h-10 w-10 rounded-md"
          :style="{
            backgroundImage: `url(${modelType?.logo_url ?? '/images/4C_Logo.png'})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        ></div>

        <div class="pl-4">
          <div
            class="pb-1 text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400"
          >
            This AI model predicts
          </div>
          <div class="text-xs font-medium leading-none text-slate-700">
            {{ modelType?.name ?? "All events" }}
          </div>
        </div>
      </div>
      <!-- </div> -->

      <!-- Badges and "How This Model Works" button -->
      <!-- <div class="flex w-full items-center justify-end gap-10"> -->
      <div>
        <ModelAccuracyBadge :model="model" />
      </div>

      <div class="flex items-center justify-center gap-2">
        <Last10PredictionsBadge :last10PredictionResults="model.last_ten_prediction" />
      </div>

      <div class="flex items-center justify-center gap-2">
        <div class="inline-flex flex-col items-center justify-start gap-1 md:items-center">
          <div class="inline-flex items-center justify-start gap-2 overflow-hidden">
            <Button
              class="h-8 w-full border-sky-500 bg-transparent px-5"
              @click="router.push({ name: 'single-model', params: { modelId: model.id } })"
            >
              <span class="leading-0 text-xs font-semibold text-sky-500">How This Model Works</span>
            </Button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Subscribers, likes and views -->
    <div class="flex w-full items-center justify-between gap-2 border-t-2 border-slate-100 pt-3">
      <div class="flex items-center justify-start gap-2 overflow-hidden px-0.5">
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.subscribers)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >subscribers</span
          >
        </div>
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.views)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >views</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div
    class="flex-col items-center justify-start gap-4 self-stretch rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_1px_rgba(0,0,0,0.10)] md:hidden"
  >
    <div
      class="mb-4 flex min-h-[70px] flex-col items-center justify-between gap-4 self-stretch py-1"
    >
      <!-- Model Avatar, Name and Share Button -->
      <div class="flex w-full items-center justify-between border-b border-slate-200 pb-3">
        <div class="flex w-full flex-1 items-center justify-start gap-2">
          <!-- Model Avatar -->
          <img :src="modelImageSrc" @error="onModelImageError" class="h-10 w-10 rounded-md" />

          <!-- Model Name -->
          <div class="inline-flex flex-col pl-2 font-semibold text-slate-700">
            <div class="text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400">
              AI Model
            </div>
            <div class="text-bold pb-1 text-[14px]">
              {{ model.name }}
            </div>
          </div>
        </div>

        <!-- Share Button -->
        <div class="flex items-center gap-2">
          <Button
            variant="text"
            @click="showShareModal = true"
            class="flex items-center justify-start gap-1 overflow-hidden !py-0 px-0 hover:bg-transparent"
          >
            <i class="pi pi-share-alt text-xs text-slate-500"></i>
            <div class="text-xs font-medium leading-none text-slate-700">Share</div>
          </Button>
        </div>
      </div>

      <!-- Model builder and event sub type -->
      <div class="flex w-full items-center border-b border-slate-200 pb-3">
        <div
          class="h-10 w-10 rounded-md"
          :style="{
            backgroundImage: `url(${modelType?.logo_url ?? '/images/4C_Logo.png'})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        ></div>

        <div class="flex-1 pl-4">
          <div
            class="pb-1 text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400"
          >
            This AI model predicts
          </div>
          <div class="text-xs font-medium leading-none text-slate-700">
            {{ modelType?.name ?? "All events" }}
          </div>
        </div>
      </div>

      <!-- Badges -->
      <div class="flex w-full items-center justify-between gap-2">
        <div class="">
          <ModelAccuracyBadge :model="model" />
        </div>

        <div class="">
          <Last10PredictionsBadge :last10PredictionResults="model.last_ten_prediction" />
        </div>
      </div>

      <!-- Model Action Button and "How This Model Works" button -->
      <div class="flex w-full flex-1 flex-col items-center justify-between gap-3">
        <Button
          class="h-8 w-full border-sky-500 bg-transparent"
          @click="router.push({ name: 'single-model', params: { modelId: model.id } })"
        >
          <span class="leading-0 text-xs font-semibold text-sky-500">How This Model Works</span>
        </Button>
        <div class="w-full">
          <ModelActionButton :model="model" height="h-[32px]" />
        </div>
      </div>
    </div>

    <!-- Subscribers, likes and views -->
    <div class="flex w-full items-center justify-between gap-2 border-t-2 border-slate-100 pt-3">
      <div class="flex items-center justify-start gap-2 overflow-hidden px-0.5">
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.subscribers)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >subscribers</span
          >
        </div>
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.views)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >views</span
          >
        </div>
      </div>
    </div>
  </div>

  <SocialShareModal
    v-model:isVisible="showShareModal"
    :title="shareTitle"
    :description="shareDescription"
    :imageUrl="shareImageUrl"
    :url="shareUrl"
    type="model"
  />
</template>

<script lang="ts" setup>
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Model } from "@/types/model";
import SocialShareModal from "@/ui/components/SocialShareModal.vue";
import { formatCount } from "@/utils/numberFormater";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Last10PredictionsBadge from "./Last10PredictionsBadge.vue";
import ModelAccuracyBadge from "./ModelAccuracyBadge.vue";
import ModelActionButton from "./ModelActionButton.vue";

const eventTypeStore = useEventTypesStore();
const router = useRouter();
const shareTitle = ref(null);
const shareDescription = ref(null);
const shareImageUrl = ref(null);
const shareUrl = ref(null);
const showShareModal = ref(false);
const modelType = ref(null);

const modelImageSrc = ref(null);
const userImageSrc = ref(null);
const modelImagePlaceholderSrc = "/images/placeholder.svg";
// const userImagePlaceholderSrc = "/images/avatar.png";

const onModelImageError = () => {
  modelImageSrc.value = modelImagePlaceholderSrc;
};
// const onUserImageError = () => {
//   userImageSrc.value = userImagePlaceholderSrc;
// };

const props = defineProps<{
  model: Model;
}>();

onMounted(() => {
  modelType.value = eventTypeStore.getChildTypeById(props.model.event_sub_type_id);

  const metadataItem = props.model.metadata.find((metadata) => metadata.key === "Avatar");
  modelImageSrc.value = metadataItem?.value;

  userImageSrc.value = props.model.creator?.avatar;

  shareImageUrl.value = modelType.value?.logo_url ?? null;
  shareTitle.value = props.model.name;
  shareDescription.value = props.model.description;
  shareUrl.value =
    window.location.origin +
    router.resolve({
      name: "single-model",
      params: { modelId: props.model.id },
    }).fullPath;
});
</script>
