<template>
  <PlainLayout>
    <DashboardTopCards />
    <EventList />
  </PlainLayout>
  <Dialog :visible="actionDialogVisible" :modal="true" :closable="false" :closeOnEscape="false">
    <template #header>
      <div class="flex items-center gap-2 text-lg font-medium leading-loose text-slate-700">
        <span>{{ actionDialogTitle }}</span>
      </div>
    </template>
    <div class="flex items-center gap-2">
      <i class="pi pi-spin pi-spinner text-lg"></i>
      <span class="font-['Inter'] text-sm font-normal leading-tight text-slate-700">
        {{ actionDialogMessage }}...
      </span>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { useAuthStore } from "@/stores/authStore";
import DashboardTopCards from "@/ui/components/DashboardTopCards.vue";
import { showGlobalToast } from "@/utils/toast";
import { inject, ref } from "vue";
import { useRoute } from "vue-router";
import EventList from "../components/events/EventList.vue";
import PlainLayout from "../layouts/PlainLayout.vue";

const authStore = useAuthStore();
const route = useRoute();

const updateMeta = inject("updateMeta") as (meta) => void;
const actionDialogVisible = ref(false);
const actionDialogTitle = ref("");
const actionDialogMessage = ref("");

if (route.meta?.action) {
  switch (route.meta?.action) {
    case "verify":
      verifyEmail();
      actionDialogVisible.value = true;
      break;
    case "reset":
      if (route.params.hash && route.query.email) {
        authStore.setActiveModal("reset");
        authStore.setAuthModalVisible(true);
      }
      break;
  }
}

async function verifyEmail() {
  actionDialogTitle.value = "Verifying account";
  actionDialogMessage.value = "Please wait while we verify your account";

  try {
    const id = route.params.id as string;
    const hash = route.params.hash as string;
    const query = route.query as Record<string, string>;
    const result = await authApi.emailVerify(id, hash, query);
    if (result.data) {
      authStore.setUser(result.data);
    }

    showGlobalToast("success", "Success", "Account verified successfully");
  } finally {
    actionDialogVisible.value = false;
    window.location.reload();
  }
}

updateMeta({
  title: "4CPredictions - Home",
});
</script>
