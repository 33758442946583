<template>
  <form @submit.prevent="doLogin">
    <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
      <!-- Email Field -->
      <div class="inline-flex w-full flex-col items-start justify-start gap-[7px]">
        <div class="text-xs font-semibold leading-none text-slate-700">Email</div>
        <InputText
          v-model="emailAddress"
          @blur="v$.emailAddress.$touch()"
          :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
          placeholder="Enter your email"
          class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
        />
        <div
          v-if="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
          class="p-error mt-1 text-xs text-red-600"
        >
          <div v-if="(v$.emailAddress.required as any)?.$invalid">Email is required.</div>
          <div v-else-if="(v$.emailAddress.email as any)?.$invalid">
            Please enter a valid email address.
          </div>
        </div>
      </div>

      <!-- Password Field -->
      <div class="inline-flex w-full flex-col items-start justify-start gap-[7px]">
        <div class="text-xs font-semibold leading-none text-slate-700">Password</div>
        <Password
          v-model="password"
          @blur="v$.password.$touch()"
          :feedback="false"
          :class="{ 'p-invalid': v$.password.$invalid && v$.password.$dirty }"
          toggleMask
          placeholder="Enter your password"
          class="h-[33px] w-full border-slate-300 bg-white text-sm font-normal text-slate-500"
        />
        <div
          v-if="v$.password.$dirty && v$.password.$invalid"
          class="p-error mt-1 text-xs text-red-600"
        >
          <div v-if="(v$.password.required as any)?.$invalid">Password is required.</div>
          <div v-else-if="(v$.password.minLength as any)?.$invalid">
            Password must be at least 3 characters long.
          </div>
        </div>
      </div>
    </div>

    <!-- Display Backend Error Message -->
    <div v-if="backendError" class="p-error mt-2 text-xs text-red-600">
      {{ backendError }}
    </div>

    <Button
      @click="authStore.setActiveModal('forgot')"
      text
      label="Forgot Password?"
      class="!px-0 text-sm !text-sky-500 hover:bg-transparent hover:underline"
    />
    <Button type="submit" class="my-2 w-full px-[10.50px] py-[7px]">
      <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
      <div class="text-sm font-medium text-slate-700">Sign In</div>
    </Button>
  </form>
</template>

<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { paymentApi } from "@/api/paymentApi";
import { useAuthStore } from "@/stores/authStore";
import { showGlobalToast } from "@/utils/toast";
import { useVuelidate } from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { computed, ref } from "vue";

const authStore = useAuthStore();

const emailAddress = ref("");
const password = ref("");
const backendError = ref("");

const rules = computed(() => ({
  emailAddress: { required, email },
  password: { required, minLength: minLength(3) },
}));

const v$ = useVuelidate(rules, { emailAddress, password });

const downloadFile = (filePath: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = filePath;
  link.setAttribute("download", fileName);
  link.click();
  showGlobalToast("success", "", `${fileName} downloaded`);
};

const requestPaymentLink = async () => {
  const link = window.location.origin + (authStore.redirectUrl ?? '/events/march-madness');

  const result = await paymentApi.requestLink(authStore.user, link);
  authStore.setRedirectUrl(null);
  authStore.setAuthAndGoPay(false);
  window.location.href = result.payment_url;
};

const doLogin = async () => {
  v$.value.$touch();
  const isFormCorrect = await v$.value.$validate();

  if (isFormCorrect) {
    backendError.value = "";
    authStore.setIsLoading(true);
    try {
      const userData = await authApi.login({
        email: emailAddress.value,
        password: password.value,
      });
      if (userData) {
        authStore.setUser(userData);
        if (authStore.downloadOnAuth) {
          downloadFile(authStore.downloadOnAuth.filePath, authStore.downloadOnAuth.fileName);
          authStore.setDownloadOnAuth(null);
          authStore.setActiveModal(null);
          authStore.setAuthModalVisible(false);
        } else if (!authStore.user?.has_access && authStore.authAndGoPay) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "initiateCheckout"
          });
          authStore.setAuthAndGoPay(false);
          requestPaymentLink();
        } else if (authStore.user?.has_access) {
          authStore.setSelectedModel(null);
          authStore.setAuthModalVisible(false);
        } else {
          authStore.setAuthModalVisible(false);
        }
      }
    } catch (error: unknown) {
      const err = error as { response?: { data?: { errors?: string } } };
      backendError.value = err.response?.data?.errors || "Login failed.";
    } finally {
      authStore.setIsLoading(false);
    }
  }
};
</script>
