import { ref, watch } from 'vue';

interface MetaInfo {
    title: string;
    description: string;
    image: string;
    url: string;
}

const metaInfo = ref<MetaInfo>({
    title: '',
    description: '',
    image: '',
    url: '',
});

export function useMeta() {
    const updateMeta = (newMeta: Partial<MetaInfo>) => {
        Object.assign(metaInfo.value, newMeta);
    };

    watch(metaInfo, (newMeta) => {
        document.title = newMeta.title;

        const metaTags = [
            { name: 'description', content: newMeta.description },
            { property: 'og:title', content: newMeta.title },
            { property: 'og:description', content: newMeta.description },
            { property: 'og:image', content: newMeta.image },
            { property: 'og:url', content: newMeta.url },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: newMeta.title },
            { name: 'twitter:description', content: newMeta.description },
            { name: 'twitter:image', content: newMeta.image },
        ];

        metaTags.forEach(({ name, property, content }) => {
            const selector = name ? `meta[name="${name}"]` : `meta[property="${property}"]`;
            let tag = document.querySelector(selector);

            if (tag) {
                tag.setAttribute('content', content);
            } else {
                tag = document.createElement('meta');
                if (name) tag.setAttribute('name', name);
                if (property) tag.setAttribute('property', property);
                tag.setAttribute('content', content);
                document.head.appendChild(tag);
            }
        });
    }, { deep: true });

    return {
        updateMeta,
    };
}

