<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import type { Prediction } from "@/types/prediction";
import LockedPrediction from "./sub-components/LockedPrediction.vue";

const props = defineProps<{
  prediction: Prediction;
}>();

const authStore = useAuthStore();

const reviewPrediction = (): boolean => {
  return (
    (authStore.user && authStore.user?.has_access) ||
    props.prediction.status?.toLowerCase() == "completed" ||
    props.prediction.event?.is_free ||
    (authStore.user && props.prediction.model?.price == 0)
  );
};
</script>
<template>
  <div
    class="inline-flex w-full flex-col items-start justify-start self-stretch rounded-lg border border-slate-200 bg-slate-100 p-4"
  >
    <div
      class="inline-flex h-5 items-center justify-start gap-4 self-stretch border-b border-slate-200 py-1"
    >
      <div
        class="justify-start text-[10px] font-bold uppercase leading-3 tracking-tight text-slate-500"
      >
        {{ prediction.model?.name }} Prediction
      </div>
    </div>
    <div class="result-row inline-flex items-center justify-between self-stretch">
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">Winner</div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <img class="h-6 w-6" :src="prediction.winner.image" />
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.abbreviation }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.probability"
      class="result-row inline-flex items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">
        Winner Probability
      </div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.probability }}%
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.best_bet_odds"
      class="result-row inline-flex items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">
        Winner Best Bet Odds
      </div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.best_bet_odds }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.over_under"
      class="result-row inline-flex items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">Over/Under</div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.over_under }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.over_under_probability"
      class="result-row inline-flex items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">
        Over/Under Probability
      </div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.over_under_probability }}%
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.over_under_best_bet_odds"
      class="result-row inline-flex h-7 items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">
        Over/Under Best Bet Odds
      </div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.over_under_best_bet_odds }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.spread"
      class="result-row inline-flex h-7 items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">Spread</div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.spread }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
    <div
      v-if="prediction.winner && prediction.winner.spread_odds"
      class="result-row inline-flex h-7 items-center justify-between self-stretch"
    >
      <div class="justify-start text-xs font-medium leading-tight text-slate-700">
        Spread Best Bet Odds
      </div>
      <div class="flex flex-1 items-center justify-end gap-2">
        <div v-if="reviewPrediction()" class="flex items-center justify-start gap-2">
          <div class="inline-flex flex-col items-start justify-center">
            <div class="justify-start text-xs font-bold leading-tight text-slate-700">
              {{ prediction.winner.spread_odds }}
            </div>
          </div>
        </div>
        <LockedPrediction v-else />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.result-row {
  @apply min-h-[33px] py-1;
}
.result-row:not(:last-child) {
  @apply border-b border-slate-200;
}

.result-row:last-child {
  @apply pb-0;
}
</style>
