<template>
  <div class="min-w-screen flex min-h-screen flex-col items-center justify-center w-full">
    <!-- HEADER -->
    <header class="bg-black w-full">
      <div class="container mx-auto max-w-[1024px] flex items-center justify-between px-4 py-2">
        <!-- Left Side: Logo + Tagline -->
        <div class="flex items-center space-x-2">
          <SiteLogo />
        </div>

        <!-- Desktop Nav -->
        <nav class="hidden md:flex space-x-6">
          <a :href="`/landing-v2/${currentSlug}#why-4c`" class="text-white hover:text-primary">Why</a>
          <a :href="`/landing-v2/${currentSlug}#features`" class="text-white hover:text-primary">Benefits</a>
          <a :href="`/landing-v2/${currentSlug}#explore-more`" class="text-white hover:text-primary">How It Works</a>
          <a :href="`/landing-v2/${currentSlug}#testimonials`" class="text-white hover:text-primary">Testimonials</a>
        </nav>

        <!-- "Start Today" Button (Desktop) -->
        <router-link :to="`/events/${currentSlug}`"
          class="hidden md:inline-block bg-primary text-black font-semibold px-4 py-2 rounded-md hover:opacity-90">
          Start Today
        </router-link>

        <!-- Hamburger Button (Mobile) -->
        <button class="md:hidden p-2 text-white" @click="toggleMenu" aria-label="Open menu">
          <!-- Minimal hamburger icon (3 lines). You can replace with an SVG icon. -->
          <div class="space-y-1">
            <span class="block w-6 h-0.5 bg-white"></span>
            <span class="block w-6 h-0.5 bg-white"></span>
            <span class="block w-6 h-0.5 bg-white"></span>
          </div>
        </button>
      </div>

      <!-- Mobile Nav (collapsible) -->
      <!--
        v-if="menuOpen" toggles this on/off.
        We also wrap it in a <transition> (optional) for a fade-in effect.
      -->
      <transition name="fade">
        <nav v-if="menuOpen" class="md:hidden bg-black px-4 pb-4 flex flex-col space-y-3">
          <a :href="`/landing-v2/${currentSlug}#why-4c`" class="text-white hover:text-primary">Why</a>
          <a :href="`/landing-v2/${currentSlug}#features`" class="text-white hover:text-primary">Benefits</a>
          <a :href="`/landing-v2/${currentSlug}#explore-more`" class="text-white hover:text-primary">How It Works</a>
          <a :href="`/landing-v2/${currentSlug}#testimonials`" class="text-white hover:text-primary">Testimonials</a>

          <!-- "Start Today" Button (Mobile) -->
          <button class="mt-2 bg-primary text-black font-semibold px-4 py-2 rounded-md hover:opacity-90">
            Start Today
          </button>
        </nav>
      </transition>
    </header>

    <!-- MAIN CONTENT -->
    <component v-if="loaded" :is="activePage" />
    <i v-else class="pi pi-spin pi-spinner text-[2rem]"></i>

    <!-- FOOTER -->
    <footer class="bg-black px-4 py-8 text-sm text-white w-full">
  <div class="container mx-auto max-w-[1024px] flex flex-col gap-6">
    <!-- Row 1: Footer Nav (same approach as header) -->
    <div class="flex flex-col md:flex-row items-center justify-center md:justify-between gap-4">
      <!-- Left side: (optional) short tagline or brand text -->
      <div class="hidden md:block">
        <!-- If you'd like to display a small tagline on the left, you can do so here.
             For instance:
             <div class="text-gray-400 text-xs">
               The Worlds <span class="text-primary">#1</span> Prediction Market
             </div>
        -->
      </div>

      <!-- Center/Right: Footer nav links, same anchor approach as the header -->
      <nav class="flex flex-wrap items-center justify-center space-x-6">
        <a
          :href="`/landing-v2/${currentSlug}#why-4c`"
          class="hover:text-primary"
        >
          Why 4C?
        </a>
        <a
          :href="`/landing-v2/${currentSlug}#features`"
          class="hover:text-primary"
        >
          Benefits
        </a>
        <a
          :href="`/landing-v2/${currentSlug}#explore-more`"
          class="hover:text-primary"
        >
          How It Works
        </a>
        <a
          :href="`/landing-v2/${currentSlug}#testimonials`"
          class="hover:text-primary"
        >
          Testimonials
        </a>
      </nav>

      <!-- (Optional) Social icons or any additional icons on the far right -->
      <!--
      <div class="flex space-x-4">
        <a href="#" aria-label="Facebook" class="hover:text-primary">
          <i class="pi pi-facebook"></i>
        </a>
        <a href="#" aria-label="Twitter" class="hover:text-primary">
          <i class="pi pi-twitter"></i>
        </a>
        <a href="#" aria-label="Instagram" class="hover:text-primary">
          <i class="pi pi-instagram"></i>
        </a>
      </div>
      -->
    </div>

    <!-- Row 2: Disclaimer Text -->
    <div class="text-gray-300 leading-relaxed">
      <span class="text-primary font-semibold">Disclaimer: </span>
      <span>
        The 4C predictions provides predictive models from experts,
        but these models may not always be accurate and should not be solely relied upon
        for decision-making. Predictions are uncertain and outcomes can vary. Users should
        use their judgment and consult multiple sources when making decisions. 4C is not
        responsible for any actions taken based on these predictions.
      </span>
    </div>

    <!-- Row 3: Copyright & Policy Links -->
    <div
      class="flex flex-col md:flex-row items-center justify-center md:justify-between gap-2 text-gray-400"
    >
      <div>
        &copy; 2024 4C Predictions. All rights reserved.
      </div>
      <!-- <div class="flex space-x-4">
        <a href="#" class="hover:text-primary">Privacy Policy</a>
        <a href="#" class="hover:text-primary">Terms of Service</a>
        <a href="#" class="hover:text-primary">Cookies Settings</a>
      </div> -->
    </div>

    <!-- Row 4: Badges or Regulatory Logos (Optional) -->
    <!--
      If you have images like GambleAware, GamStop, IBIA, etc., place them here.
      Example:
    -->
    <!-- <div class="flex flex-wrap justify-center items-center gap-4">
      <img
        src="/images/footer/gambleaware.svg"
        alt="GambleAware"
        class="h-6 object-contain"
      />
      <img
        src="/images/footer/gambling-commission.svg"
        alt="Gambling Commission"
        class="h-6 object-contain"
      />
      <img
        src="/images/footer/gamstop.svg"
        alt="GamStop"
        class="h-6 object-contain"
      />
      <img
        src="/images/footer/begambleaware.svg"
        alt="BeGambleAware"
        class="h-6 object-contain"
      />
      <img
        src="/images/footer/ibia.svg"
        alt="International Betting Integrity Association"
        class="h-6 object-contain"
      />
    </div> -->
  </div>
</footer>

  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef, computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

import SiteLogo from "@/ui/components/SiteLogo.vue";
import DefaultV2View from "./pages-v2/DefaultV2View.vue";
import CryptoV2View from "./pages-v2/CryptoV2View.vue";
import SoccerV2View from "./pages-v2/SoccerV2View.vue";
import BasketballV2View from "./pages-v2/BasketballV2View.vue";
import BaseballV2View from "./pages-v2/BaseballV2View.vue";

// Track whether the mobile menu is open
const menuOpen = ref(false);
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

const route = useRoute();
const loaded = ref(false);

const allowedPages = {
  crypto: CryptoV2View,
  soccer: SoccerV2View,
  basketball: BasketballV2View,
  baseball: BaseballV2View,
  default: DefaultV2View,
};

const activePage = shallowRef(null);

const isValidPage = computed(() =>
  Object.keys(allowedPages).includes(route.params.slug as string)
);

const updateSlug = async () => {
  const slug = isValidPage.value
    ? (route.params.slug as string).toLowerCase()
    : "default";
  activePage.value = allowedPages[slug];
  loaded.value = true;
};

const currentSlug = computed(() => {
  const slugParam = route.params.slug;
  let slug: string = "default";

  if (typeof slugParam === "string") {
    slug = slugParam;
  } else if (Array.isArray(slugParam) && slugParam.length > 0) {
    slug = slugParam[0];
  }

  slug = slug.toLowerCase();

  return slug === "default" ? "" : slug;
});


onMounted(updateSlug);
watch(() => route.params.topic, updateSlug);
</script>

<style scoped>
.bg-primary {
  background-color: #a4df1c;
  /* Example brand color */
}

.text-primary {
  color: #a4df1c;
  /* Example highlight color */
}

/* Optional fade transition for the mobile menu */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
