import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

export function useScreenSize() {
  const { width } = useWindowSize();

  const isMobile = computed(() => width.value < 640);
  const isTablet = computed(() => width.value >= 640 && width.value < 1024);
  const isDesktop = computed(() => width.value >= 1024);

  return { width, isMobile, isTablet, isDesktop };
}
