<script setup lang="ts">
import { paymentApi } from "@/api/paymentApi";
import { useAuthStore } from "@/stores/authStore";
import BlackCheckIcon from "@/ui/components/icons/BlackCheckIcon.vue";
import MarchMadnessLayout from "@/ui/layouts/MarchMadnessLayout.vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const authStore = useAuthStore();
const router = useRouter();
const loading = ref(false);

const requestPaymentLink = async () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "initiateCheckout"
  });


  loading.value = true;
  const link = `${window.location.origin}/events/march-madness`;
  const result = await paymentApi.requestLink(authStore.user, link);
  window.location.href = result.payment_url;
};

onMounted(() => {
  if (!authStore.user) {
    router.push({ name: "march-madness-landing" });
  }
});
</script>

<template>
  <MarchMadnessLayout variant="get-predictions">
    <div class="justify-start self-stretch text-center">
      <span class="text-[32px] font-medium leading-10 text-slate-950"
        >Don't Just Watch - Win with AI!</span
      >
      <!--      <span class="text-[32px] font-medium leading-10 text-slate-950">Don't just watch - </span-->
      <!--      ><span class="text-[32px] font-bold leading-10 text-slate-950">get in the game</span-->
      <!--      ><span class="text-[32px] font-medium leading-10 text-slate-950">!</span>-->
    </div>
    <div class="mx-auto w-full max-w-[570px] justify-center self-stretch text-center">
      <span class="text-sm font-normal leading-[21px] text-slate-950"
        >Get the <b>March Madness Special</b> and join over 50,000 satisfied users crushing the odds
        and <b>WINNING</b> with <b>real-time insights</b>.
      </span>
    </div>
    <div
      class="flex w-full max-w-[400px] flex-col items-end justify-start overflow-hidden rounded-xl bg-white shadow-xl outline outline-1 outline-offset-[-1px] outline-slate-200"
      style="background-color: rgb(163, 230, 53)"
    >
      <div
        class="flex flex-col items-start justify-start gap-6 self-stretch px-6 pt-6"
        style="background-color: rgb(163, 230, 53)"
      >
        <div class="flex flex-col items-start justify-start gap-1 self-stretch">
          <div
            class="justify-start self-stretch text-xl font-semibold leading-relaxed text-slate-950"
          >
            March Madness Special
          </div>
          <div class="justify-start self-stretch text-sm font-normal leading-[21px] text-slate-950">
            Full access to 4C's Real-time analysis and advanced features
          </div>
        </div>
        <div class="flex flex-col items-start justify-center gap-1 self-stretch">
          <div class="inline-flex items-center justify-start gap-1">
            <div class="justify-start text-[40px] font-semibold leading-10 text-slate-950">$19</div>
            <div class="justify-end text-sm font-normal leading-none text-slate-950">/ month</div>
          </div>
          <div class="inline-flex items-center justify-start gap-2">
            <div
              class="justify-start text-sm font-normal leading-[21px] text-slate-950 line-through"
            >
              $199 / month
            </div>
            <div
              class="flex items-center justify-center gap-2 rounded-[100px] bg-slate-950 px-4 py-1"
            >
              <div class="justify-start text-xs font-normal leading-[18px] text-white">
                For the first 777 Customers.
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start justify-start gap-2 self-stretch">
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-sm font-semibold leading-normal text-slate-950">78% </span
              ><span class="text-sm font-normal leading-normal text-slate-950">Mega Model </span
              ><span class="text-sm font-semibold leading-normal text-slate-950">win rate</span
              ><span class="text-sm font-normal leading-normal text-slate-950">.</span>
            </div>
          </div>
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start text-sm font-semibold leading-normal text-slate-950">
              Unlimited Access.
            </div>
          </div>
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-sm font-semibold leading-normal text-slate-950">Every </span
              ><span class="text-sm font-normal leading-normal text-slate-950">Sport</span
              ><span class="text-sm font-semibold leading-normal text-slate-950">, Every </span
              ><span class="text-sm font-normal leading-normal text-slate-950">Prediction</span
              ><span class="text-sm font-semibold leading-normal text-slate-950">.</span>
            </div>
          </div>
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-sm font-semibold leading-normal text-slate-950">Instant Picks</span
              ><span class="text-sm font-normal leading-normal text-slate-950">, No Waiting.</span>
            </div>
          </div>
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-sm font-normal leading-normal text-slate-950">Get the edge </span
              ><span class="text-sm font-semibold leading-normal text-slate-950"
                >sports books fear.</span
              >
            </div>
          </div>
          <div class="inline-flex h-6 w-[491px] items-start justify-start gap-1">
            <div class="flex items-center justify-center gap-2.5 p-1">
              <div class="relative h-4 w-4 overflow-hidden">
                <BlackCheckIcon class="absolute left-0 top-[2.29px] h-[11.43px] w-4" />
              </div>
            </div>
            <div class="flex-1 justify-start">
              <span class="text-sm font-semibold leading-normal text-slate-950">Sharp bettors </span
              ><span class="text-sm font-normal leading-normal text-slate-950">use AI.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start gap-4 self-stretch p-6">
        <Button
          @click="requestPaymentLink"
          :disabled="loading"
          class="mt-3 w-full bg-black px-[10.50px] py-[10px] shadow"
        >
          <i v-if="loading" class="pi pi-spin pi-spinner"></i>
          <div class="text-xl font-bold" style="color: #ffffff">
            Get 4C's AI Mega Model NOW and BOOST your winning rate!
          </div>
        </Button>
        <div
          class="justify-start self-stretch text-center text-xs font-normal leading-[18px] text-slate-950"
        >
          Billed Monthly | Cancel Anytime
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: 'events', params: { type: 'march-madness' } }"
      class="justify-start self-stretch pb-5 text-center text-sm font-normal leading-[21px] text-slate-950 md:pb-0"
    >
      Skip
    </router-link>
  </MarchMadnessLayout>
</template>
