import axiosInstance from "@/api/axiosInstance";
import type { AxiosInstance } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export interface EventType {
  id: string;
  name: string;
  slug: string;
  logo_url?: string;
  parent?: EventType;
  is_sport: boolean;
  is_crypto: boolean;
  children?: EventType[];
}

const api: AxiosInstance = axiosInstance;

export const useEventTypesStore = defineStore("event_types_store", () => {
  const types = ref<EventType[]>([]);
  const currentActiveType = ref<EventType | null>(null);
  const loading = ref(false);
  const error = ref<string | null>(null);

  const setCurrentActiveType = (type: EventType) => {
    currentActiveType.value = type;
  };

  const loadEventTypes = async () => {
    if (types.value.length > 0) return;

    loading.value = true;

    try {
      const { data } = await api.get("/event-types");
      types.value = data.data;
    } catch (err) {
      error.value = (err as Error).message;
    } finally {
      loading.value = false;
    }
  };

  const getTypeChildren = (id: string) => {
    return types.value.find((type) => type.id == id)?.children || null;
  };

  const getAllChildren = () => {
    return types.value.flatMap((type) =>
      type.children.map((child) => ({
        id: child.id,
        name: child.name,
        slug: child.slug,
        logo_url: child.logo_url,
        parent: type,
        is_sport: type.is_sport,
        is_crypto: type.is_crypto
      })),
    );
  };

  const getChildTypeById = (id: string) => {
    return getAllChildren().find((child) => child.id === id) || null;;
  }

  return {
    types,
    currentActiveType,
    loading,
    error,
    setCurrentActiveType,
    loadEventTypes,
    getTypeChildren,
    getAllChildren,
    getChildTypeById,
  };
});
