<template>
  <div>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_990_22588)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1282 10.2564C13.1282 13.65 15.8901 16.4102 19.2821 16.4102C22.6741 16.4102 25.4359 13.65 25.4359 10.2564C25.4359 6.86274 22.6741 4.10254 19.2821 4.10254C15.8901 4.10254 13.1282 6.86274 13.1282 10.2564ZM15.5897 10.2564C15.5897 8.21987 17.2472 6.56408 19.2821 6.56408C21.3169 6.56408 22.9744 8.21987 22.9744 10.2564C22.9744 12.2929 21.3169 13.9487 19.2821 13.9487C17.2472 13.9487 15.5897 12.2929 15.5897 10.2564Z" fill="white"/>
    <path d="M29.5385 26.6666C29.5385 27.346 30.0898 27.8974 30.7692 27.8974C31.4486 27.8974 32 27.3477 32 26.6666C32 18.8718 23.0876 18.8718 19.2821 18.8718C15.4765 18.8718 6.56411 18.8718 6.56411 26.6666C6.56411 27.346 7.11549 27.8974 7.79488 27.8974C8.47426 27.8974 9.02565 27.346 9.02565 26.6666C9.02565 23.465 10.7684 21.3333 19.2821 21.3333C27.7941 21.3333 29.5385 23.465 29.5385 26.6666Z" fill="white"/>
    <path d="M9.95592 17.7476C9.79675 17.7476 9.63757 17.7394 9.47511 17.7246C8.16721 17.5999 6.98403 16.9731 6.14875 15.9573C5.31182 14.9431 4.91962 13.6647 5.04433 12.3552C5.16905 11.0457 5.79757 9.86413 6.81172 9.0272C7.82587 8.19028 9.10259 7.80136 10.4121 7.92279C11.0882 7.98843 11.5855 8.58905 11.5215 9.26515C11.4558 9.94289 10.8421 10.4385 10.1791 10.3728C9.52926 10.3105 8.88597 10.5058 8.3789 10.9242C7.87018 11.3427 7.55839 11.9335 7.49603 12.5882C7.43203 13.243 7.62895 13.883 8.04741 14.3901C8.46587 14.8972 9.05664 15.2106 9.71141 15.2729C10.2447 15.3238 10.7764 15.2024 11.2392 14.9185C11.8185 14.5607 12.575 14.7429 12.9311 15.3189C13.2888 15.8982 13.1083 16.6547 12.5307 17.0124C11.7479 17.4982 10.8651 17.7476 9.95592 17.7476Z" fill="white"/>
    <path d="M3.8147e-06 25.4359C3.8147e-06 26.1153 0.551388 26.6666 1.23077 26.6666C1.91016 26.6666 2.46154 26.1169 2.46154 25.4359C2.46154 21.7419 3.11303 20.5128 6.97436 20.5128C7.65375 20.5128 8.20513 19.9614 8.20513 19.282C8.20513 18.6026 7.65375 18.0513 6.97436 18.0513C1.18647 18.0513 3.8147e-06 21.0035 3.8147e-06 25.4359Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_990_22588">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  </div>
</template>
