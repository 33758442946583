import type { ComputedRef, Ref } from 'vue';
import { computed, onUnmounted, ref } from 'vue';

interface CountdownTimer {
    timeRemaining: Ref<number>;
    isRunning: Ref<boolean>;
    formattedTime: ComputedRef<string>;
    startTimer: () => void;
    pauseTimer: () => void;
    resetTimer: (seconds?: number) => void;
    setTime: (hours: number, minutes: number, seconds: number) => void;
}

export function useCountdownTimer(initialSeconds: number = 0): CountdownTimer {
    const timeRemaining = ref<number>(initialSeconds);
    const isRunning = ref<boolean>(false);
    let intervalId: number | null = null;

    const formattedTime = computed<string>(() => {
        const hours = Math.floor(timeRemaining.value / 3600);
        const minutes = Math.floor((timeRemaining.value % 3600) / 60);
        const seconds = timeRemaining.value % 60;
        return [hours, minutes, seconds]
            .map(v => v.toString().padStart(2, '0'))
            .join(':');
    });

    const startTimer = (): void => {
        if (timeRemaining.value > 0 && !isRunning.value) {
            isRunning.value = true;
            intervalId = window.setInterval(() => {
                if (timeRemaining.value > 0) {
                    timeRemaining.value--;
                } else {
                    pauseTimer();
                }
            }, 1000);
        }
    };

    const pauseTimer = (): void => {
        isRunning.value = false;
        if (intervalId !== null) {
            clearInterval(intervalId);
        }
    };

    const resetTimer = (seconds: number = initialSeconds): void => {
        pauseTimer();
        timeRemaining.value = seconds;
    };

    const setTime = (hours: number, minutes: number, seconds: number): void => {
        resetTimer(hours * 3600 + minutes * 60 + seconds);
    };

    onUnmounted(() => {
        if (intervalId !== null) {
            clearInterval(intervalId);
        }
    });

    return {
        timeRemaining,
        isRunning,
        formattedTime,
        startTimer,
        pauseTimer,
        resetTimer,
        setTime
    };
}
