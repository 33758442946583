<template>
  <PlainLayout>
    <div className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-lg md:px-10">
        <h1 class="mb-6 text-center text-3xl font-bold">Contact Us</h1>
        <form @submit.prevent="submit">
          <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
            <div
              class="inline-flex w-full flex-col items-start justify-start gap-2.5 self-stretch md:flex-row"
            >
              <div
                class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  First Name
                </div>
                <InputText
                  v-model="firstName"
                  :class="{ 'p-invalid': v$.firstName.$invalid && v$.firstName.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
              <div
                class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  Last Name
                </div>
                <InputText
                  v-model="lastName"
                  :class="{ 'p-invalid': v$.lastName.$invalid && v$.lastName.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
            </div>

            <div
              class="mt-4 inline-flex w-full flex-col items-start justify-start gap-2.5 self-stretch md:flex-row"
            >
              <div
                class="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-bold leading-none text-slate-700">
                  Email Address
                </div>
                <InputText
                  v-model="emailAddress"
                  :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
                  type="email"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
              <div
                class="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  Phone number
                </div>
                <InputText
                  v-model="phone"
                  :class="{ 'p-invalid': v$.phone.$invalid && v$.phone.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
            </div>
            <div
              class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
            >
              <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                How can we help?
              </div>
              <Textarea
                v-model="message"
                rows="5"
                :class="{ 'p-invalid': v$.message.$invalid && v$.message.$dirty }"
                placeholder="Type your message..."
                class="w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
              />
            </div>
            <div class="mt-2 flex items-start gap-[7px]">
              <Checkbox
                v-model="acceptTerms"
                :class="{ 'p-invalid': v$.acceptTerms.$invalid && v$.acceptTerms.$dirty }"
                inputId="acceptTerms"
                name="acceptTerms"
                :binary="true"
              />
              <label
                for="acceptTerms"
                class="cursor-pointer text-xs font-bold leading-[17.50px] text-slate-700"
              >
                You agree to our friendly
                <router-link to="/privacy-policy" class="underline">privacy policy.</router-link>
              </label>
            </div>
          </div>
          <Button type="submit" class="mt-4 w-full px-12 py-[7px] md:w-auto">
            <i v-if="loading" class="pi pi-spin pi-spinner"></i>
            <div class="text-sm font-medium text-slate-700">Send message</div>
          </Button>
        </form>
      </div>
    </div>
  </PlainLayout>
</template>
<script setup lang="ts">
import PlainLayout from "../layouts/PlainLayout.vue";

import { userApi } from "@/api/userApi";
import { showGlobalToast } from "@/utils/toast";
import { useVuelidate } from "@vuelidate/core";
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { computed, ref } from "vue";

const loading = ref(false);
const firstName = ref("");
const lastName = ref("");
const emailAddress = ref("");
const phone = ref("");
const message = ref("");
const acceptTerms = ref(null);

const rules = computed(() => ({
  firstName: { required, minLength: minLength(2), maxLength: maxLength(50) },
  lastName: { required, minLength: minLength(2), maxLength: maxLength(50) },
  emailAddress: { required, email },
  phone: { required, minLength: minLength(3) },
  message: { required, minLength: minLength(3) },
  acceptTerms: { required },
}));

const v$ = useVuelidate(rules, {
  firstName,
  lastName,
  emailAddress,
  phone,
  message,
  acceptTerms,
});

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    loading.value = true;
    try {
      const result = await userApi.contactUs({
        first_name: firstName.value,
        last_name: lastName.value,
        email: emailAddress.value,
        phone: phone.value,
        message: message.value,
      });

      if (result) {
        showGlobalToast("success", "Thank you", "Your message has be submitted");
      }
    } finally {
      loading.value = false;
    }
  }
};
</script>
