<template>
  <div class="hidden w-full flex-col items-start justify-start gap-4 p-4 pl-0 pr-4 lg:flex">
    <div class="inline-flex items-center justify-between gap-2 self-stretch">
      <div class="flex-1 truncate text-base font-semibold leading-7 text-slate-700">
        Upcoming Events
      </div>
      <div class="flex items-center justify-start gap-2 overflow-hidden">
        <router-link
          :to="{ name: 'events' }"
          class="cursor-pointer text-nowrap text-sm font-medium leading-tight text-slate-500 hover:text-slate-800 hover:underline"
        >
          View All
        </router-link>
      </div>
    </div>
    <div class="flex basis-0 flex-col items-start gap-3 self-stretch" v-if="!eventStore.isLoading">
      <EventCard v-for="(event, index) in eventStore.upcomingEvents" :key="index" :event="event" />
    </div>
    <div
      v-if="eventStore.upcomingEventsLoading"
      class="flex basis-0 flex-col items-start gap-3 self-stretch"
    >
      <EventCardSkeleton v-for="(item, index) in Array.from({ length: 3 })" :key="index" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventStore } from "@/stores/eventStore";
import EventCardSkeleton from "@/ui/components/events/EventCardSkeleton.vue";
import { onMounted } from "vue";
import EventCard from "./EventCard.vue";

const eventStore = useEventStore();

const init = async () => await eventStore.fetchUpcomingEvents();

onMounted(init);
</script>
