import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";

const presets = definePreset(Aura, {
  semantic: {
    primary: {
      "50": "#F7FEE7",
      "100": "#ECFCCB",
      "200": "#D9F99D",
      "300": "#BEF264",
      "400": "#A3E635",
      "500": "#A3E635",
      "600": "#84CC16",
      "700": "#4D7C0F",
      "800": "#3F6212",
      "900": "#365314",
      "950": "#1A2E05"
    },
    slate: {
      "50": "#F8FAFC",
      "100": "#F1F5F9",
      "200": "#E2E8F0",
      "300": "#CBD5E1",
      "400": "#94A3B8",
      "500": "#64748B",
      "600": "#475569",
      "700": "#334155",
      "800": "#1E293B",
      "900": "#0F172A",
      "950": "#020617"
    }
  }
});

export const CustomTheme = {
  theme: {
    preset: presets,
    options: {
      darkModeSelector: "class",
      cssLayer: {
        name: "custom-base",
        order: "tailwind-base, custom-base, tailwind-utilities"
      }
    }
  }
};
