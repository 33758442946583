<template>
  <div>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_990_22639)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16 2.66655C8.63614 2.66655 2.66655 8.63614 2.66655 16C2.66655 23.3639 8.63614 29.3335 16 29.3335C23.3639 29.3335 29.3335 23.3639 29.3335 16C29.3335 8.63614 23.3639 2.66655 16 2.66655ZM0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM15.9993 8.22186C11.7039 8.22186 8.22186 11.7039 8.22186 15.9993C8.22186 20.2947 11.7039 23.7767 15.9993 23.7767C20.2947 23.7767 23.7767 20.2947 23.7767 15.9993C23.7767 11.7039 20.2947 8.22186 15.9993 8.22186ZM5.55531 15.9993C5.55531 10.2312 10.2312 5.55531 15.9993 5.55531C21.7673 5.55531 26.4433 10.2312 26.4433 15.9993C26.4433 21.7673 21.7673 26.4433 15.9993 26.4433C10.2312 26.4433 5.55531 21.7673 5.55531 15.9993ZM13.7772 15.9993C13.7772 14.772 14.772 13.7772 15.9993 13.7772C17.2265 13.7772 18.2214 14.772 18.2214 15.9993C18.2214 17.2265 17.2265 18.2214 15.9993 18.2214C14.772 18.2214 13.7772 17.2265 13.7772 15.9993ZM15.9993 11.1106C13.2994 11.1106 11.1106 13.2994 11.1106 15.9993C11.1106 18.6992 13.2994 20.888 15.9993 20.888C18.6992 20.888 20.888 18.6992 20.888 15.9993C20.888 13.2994 18.6992 11.1106 15.9993 11.1106Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_990_22639">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
