<script setup lang="ts">
import LogoImage from "@/assets/images/4c-image.png";
import SpImage from "@/assets/images/march-madness/sp-image.png";
import BgImage from "@/assets/images/march-madness/trophy-bg.png";
import VsImage from "@/assets/images/march-madness/vs-img.png";
import { computed } from "vue";

const formatNumber = (num) => (Number.isInteger(num) ? num : num.toFixed(2));

const totalGames = Number(import.meta.env.VITE_TOTAL_GAMES);
const correct4C = Number(import.meta.env.VITE_4C_CORRECT);
const incorrect4C = Number(import.meta.env.VITE_4C_INCORRECT);
const correctSP = Number(import.meta.env.VITE_SP_CORRECT);
const incorrectSP = Number(import.meta.env.VITE_SP_INCORRECT);

const accuracy4C = computed(() => formatNumber((correct4C / (correct4C + incorrect4C)) * 100));
const accuracySP = computed(() => formatNumber((correctSP / (correctSP + incorrectSP)) * 100));

const correctPercentage4C = computed(() => formatNumber((correct4C / totalGames) * 100));
const incorrectPercentage4C = computed(() => formatNumber((incorrect4C / totalGames) * 100));
const correctPercentageSP = computed(() => formatNumber((correctSP / totalGames) * 100));
const incorrectPercentageSP = computed(() => formatNumber((incorrectSP / totalGames) * 100));
</script>

<template>
  <div class="inline-flex w-full flex-col items-center justify-start gap-2.5">
    <div
      :style="{
        backgroundImage: `url(${BgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
      class="relative flex w-full flex-col items-center justify-center gap-4 overflow-hidden rounded-xl p-4 md:p-6 lg:p-8"
    >
      <div
        class="absolute inset-0 rounded-xl bg-gradient-to-b from-slate-950/0 to-slate-950/90"
      ></div>

      <div class="relative flex w-full flex-grow flex-col items-center justify-between">
        <div class="text-center text-sm font-medium text-white md:text-base">
          Million Dollar Challenge
        </div>

        <div class="flex flex-grow items-center justify-center gap-4">
          <div class="flex flex-col items-center gap-2">
            <div
              :style="{
                backgroundImage: `url(${SpImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
              class="flex h-[42px] w-[42px] items-center justify-center rounded-md bg-gray-700"
            ></div>
            <div class="ext-sm font-medium text-white md:text-base">Sean Perry</div>
          </div>
          <div
            :style="{
              backgroundImage: `url(${VsImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
            class="relative h-[63px] w-11"
          ></div>

          <div class="flex flex-col items-center gap-2">
            <div
              :style="{
                backgroundImage: `url(${LogoImage})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
              class="flex h-[42px] w-[42px] items-center justify-center rounded-md bg-gray-700"
            ></div>
            <div class="text-sm font-medium text-white md:text-base">4C Mega Model</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-start justify-start gap-2 self-stretch rounded-md bg-white p-4 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10)]"
    >
      <div class="flex flex-col items-start justify-start gap-1 self-stretch">
        <div class="inline-flex h-6 w-full items-center justify-between">
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ accuracySP }}%
            </div>
          </div>
          <div class="flex items-center justify-center gap-2.5 rounded-md">
            <div
              class="justify-start text-center text-sm font-medium leading-normal text-slate-700"
            >
              Overall Accuracy (%)
            </div>
          </div>
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ accuracy4C }}%
            </div>
          </div>
        </div>
        <div class="inline-flex h-2 w-full items-center justify-center gap-2">
          <div
            class="rela inline-flex h-2 flex-1 flex-col items-end justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-green-500"
              :style="{ width: accuracySP + '%' }"
            ></div>
          </div>
          <div
            class="rela inline-flex h-2 flex-1 flex-col items-start justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-green-500"
              :style="{ width: accuracy4C + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <div class="relative h-px self-stretch bg-slate-200"></div>
      <div class="flex flex-col items-start justify-start gap-1 self-stretch">
        <div class="inline-flex h-6 w-full items-center justify-between">
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ correctSP }}
            </div>
          </div>
          <div class="flex items-center justify-center gap-2.5 rounded-md">
            <div
              class="justify-start text-center text-sm font-medium leading-normal text-slate-700"
            >
              Correct
            </div>
          </div>
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ correct4C }}
            </div>
          </div>
        </div>
        <div class="inline-flex h-2 w-full items-center justify-center gap-2">
          <div
            class="inline-flex h-2 flex-1 flex-col items-end justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-green-500"
              :style="{ width: correctPercentageSP + '%' }"
            ></div>
          </div>
          <div
            class="inline-flex h-2 flex-1 flex-col items-start justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-green-500"
              :style="{ width: correctPercentage4C + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <div class="relative h-px self-stretch bg-slate-200"></div>
      <div class="flex flex-col items-start justify-start gap-1 self-stretch">
        <div class="inline-flex h-6 w-full items-center justify-between">
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ incorrectSP }}
            </div>
          </div>
          <div class="flex items-center justify-center gap-2.5 rounded-md">
            <div
              class="justify-start text-center text-sm font-medium leading-normal text-slate-700"
            >
              Incorrect
            </div>
          </div>
          <div class="inline-flex flex-col items-center justify-center gap-4 rounded-md">
            <div class="justify-start text-base font-bold leading-normal text-slate-700">
              {{ incorrect4C }}
            </div>
          </div>
        </div>
        <div class="inline-flex h-2 w-full items-center justify-center gap-2">
          <div
            class="inline-flex h-2 flex-1 flex-col items-end justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-[#DC2626]"
              :style="{ width: incorrectPercentageSP + '%' }"
            ></div>
          </div>
          <div
            class="inline-flex h-2 flex-1 flex-col items-start justify-center gap-2.5 rounded-[100px] bg-slate-200"
          >
            <div
              class="relative h-full rounded-[100px] bg-[#DC2626]"
              :style="{ width: incorrectPercentage4C + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
