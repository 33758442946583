<template>
  <div class="flex h-screen w-screen flex-col items-center justify-center">
    <img src="/images/placeholder.svg" class="mb-8 rounded-lg" />
    <h1 class="text-3xl font-bold">We'll be back soon!</h1>
    <p class="text-md mt-2 text-center">
      Our site is currently undergoing scheduled maintenance<br />
      We appreciate your patience and will be back online shortly
    </p>
  </div>
</template>
