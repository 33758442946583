<template>
  <IconField class="header-search mx-auto flex max-w-[464px]">
    <InputIcon class="pi pi-search" />
    <AutoComplete
      ref="autoComplete"
      :model="selectedResult"
      :suggestions="filteredSearchResults"
      @complete="search"
      optionLabel="label"
      optionGroupLabel="label"
      appendTo="self"
      optionGroupChildren="items"
      placeholder="Search Predictions..."
      :minLength="3"
    >
      <template #optiongroup="slotProps">
        <div class="country-item flex items-center justify-between text-sm">
          <div>{{ slotProps.option.label }}</div>
          <div
            text
            v-if="slotProps.option.count > 5"
            @click="goToList(slotProps.option)"
            class="cursor-pointer text-sm font-normal !text-sky-500 hover:underline"
          >
            View all {{ slotProps.option.count }} {{ slotProps.option.label.toLowerCase() }}
          </div>
        </div>
      </template>
      <template #option="slotProps">
        <router-link :to="getItemLink(slotProps.option)" class="flex items-center gap-3 py-[5px]">
          <div
            class="h-6 w-6 rounded-[14px]"
            :style="{
              backgroundImage: `url(${slotProps.option.image ?? '/images/avatar.png'})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
            }"
          />
          <div class="text-sm">{{ slotProps.option.name }}</div>
        </router-link>
      </template>
    </AutoComplete>
  </IconField>
</template>

<script setup lang="ts">
import { commonApi } from "@/api/common";
import { ref } from "vue";
import { useRouter } from "vue-router";

const autoComplete = ref();
const router = useRouter();
const selectedResult = ref();
const searchTerm = ref("");
const filteredSearchResults = ref();

const search = async (event) => {
  const input = event.query;
  searchTerm.value = input;
  const result = await commonApi.searchGlobal(input);
  if (result) {
    filteredSearchResults.value = result;
  }
};

const goToList = (item) => {
  autoComplete.value.hide();
  router.replace({ name: item.label.toLowerCase(), query: { q: searchTerm.value } });
};

const getItemLink = (option) => {
  if (option.type.toLowerCase() == "models") {
    return `/models/${option.id}/details`;
  } else {
    return `/events/${option.id}/details`;
  }
};
</script>
