<template>
  <div class="">
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div class="rounded-md bg-gray-100 p-4 text-center text-gray-700">
        Pay using saved card <strong>{{ props.cc_number_used }}</strong>
      </div>

      <div v-if="error" class="text-sm text-red-600" role="alert">
        {{ error }}
      </div>

      <Button type="submit" :disabled="isProcessing" class="w-full text-sm font-semibold" :aria-busy="isProcessing">
        {{ isProcessing ? "Processing ..." : "Pay Now" }}
      </Button>
    </form>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import { ref } from "vue";

const props = defineProps<{
  cc_number_used?: string;
}>();

const emit = defineEmits<{
  (e: "token-received", token: string): void;
  (e: "error", error: unknown): void;
}>();

const error = ref("");
const isProcessing = ref(false);

const handleSubmit = () => {
  error.value = "";
  isProcessing.value = true;
  emit("token-received", "EXISTING_CUSTOMER");
};
</script>

<style scoped></style>
