export class ModelHelper {
  static getCleanLastPredictions = (predictions: boolean[] | null) => {
    const count =
      !predictions || predictions.length == 0
        ? 10
        : predictions.length < 10
          ? 10 - predictions.length
          : 0;
    return new Array(count).fill(null).concat(predictions);
  };

  static getAccuracyColor = (percentage: number): string => {
    if (percentage >= 55) {
      return "bg-green-500";
    }

    if (percentage >= 47) {
      return "bg-orange-500";
    }

    return "bg-[#DC2626]";
  };

  static getAccuracyOutlineColor = (percentage: number): string => {
    if (percentage >= 55) {
      return "border-green-500 bg-green-100 animate-pulseLight text-green-500";
    }

    if (percentage >= 47) {
      return "border-orange-500 bg-orange-100 text-orange-500";
    }

    return "border-red-500 bg-red-100 text-red-500";
  };
}
