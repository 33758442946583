<template>
  <PlainLayout>
    <div class="mx-auto flex max-w-[1440px] flex-col gap-5 px-6 py-6 md:py-12">
      <div class="flex items-center gap-5 border-b-2 border-gray-200 pb-5">
        <Avatar
          shape="circle"
          size="large"
          class="h-[60px] w-[60px] text-lg font-semibold"
          :label="`${authStore.user.first_name[0]}${authStore.user.last_name[0]}`"
        />
        <div class="leading-2 flex flex-col gap-0">
          <span class="leading-2 text-lg font-semibold"
            >{{ authStore.user.first_name }} {{ authStore.user.last_name }}</span
          >
          <span class="text-sm text-gray-400">Joined {{ authStore.user.joined_date }}</span>
        </div>
      </div>
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="flex flex-1 flex-col rounded-lg bg-white p-6 shadow-sm">
          <div
            class="mb-3 flex h-[35px] w-[35px] items-center justify-center rounded-md bg-[#A855F7]"
          >
            <div class="h-4 w-4">
              <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.2678 2.5466L4.39229 1.42211L5.51678 2.5466C5.7836 2.81342 6.2162 2.81342 6.48302 2.5466L7.60751 1.42211L8.732 2.5466C8.99883 2.81342 9.43143 2.81342 9.69824 2.5466L10.2199 2.02496V11.975L9.69824 11.4534C9.43142 11.1866 8.99882 11.1866 8.732 11.4534L7.60751 12.5779L6.48302 11.4534C6.2162 11.1866 5.7836 11.1866 5.51678 11.4534L4.39229 12.5779L3.2678 11.4534C3.00098 11.1866 2.56838 11.1866 2.30156 11.4534L1.77993 11.975V2.02496L2.30156 2.5466C2.56838 2.81342 3.00098 2.81342 3.2678 2.5466ZM4.87541 0.200099C4.60859 -0.066719 4.17599 -0.0667213 3.90917 0.200099L2.78468 1.32459L1.74057 0.28048C1.31016 -0.149936 0.574219 0.154907 0.574219 0.7636V13.2364C0.574219 13.8451 1.31016 14.1499 1.74057 13.7195L2.78468 12.6754L3.90917 13.7999C4.17599 14.0667 4.60859 14.0667 4.87541 13.7999L5.9999 12.6754L7.12439 13.7999C7.39121 14.0667 7.82381 14.0667 8.09063 13.7999L9.21512 12.6754L10.2592 13.7195C10.6896 14.1499 11.4256 13.8451 11.4256 13.2364V0.7636C11.4256 0.154905 10.6896 -0.149935 10.2592 0.28048L9.21512 1.32459L8.09063 0.200099C7.82381 -0.066719 7.39121 -0.0667213 7.12439 0.200099L5.9999 1.32459L4.87541 0.200099ZM4.39229 3.98575C4.05935 3.98575 3.78944 4.25565 3.78944 4.5886C3.78944 4.92155 4.05935 5.19146 4.39229 5.19146H7.60751C7.94046 5.19146 8.21037 4.92155 8.21037 4.5886C8.21037 4.25565 7.94046 3.98575 7.60751 3.98575H4.39229ZM3.78944 7.00002C3.78944 6.66707 4.05935 6.39716 4.39229 6.39716H7.60751C7.94046 6.39716 8.21037 6.66707 8.21037 7.00002C8.21037 7.33296 7.94046 7.60287 7.60751 7.60287H4.39229C4.05935 7.60287 3.78944 7.33296 3.78944 7.00002ZM4.39229 8.80858C4.05935 8.80858 3.78944 9.07849 3.78944 9.41143C3.78944 9.74438 4.05935 10.0143 4.39229 10.0143H7.60751C7.94046 10.0143 8.21037 9.74438 8.21037 9.41143C8.21037 9.07849 7.94046 8.80858 7.60751 8.80858H4.39229Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <span :class="`text-lg font-semibold ${loading ? 'text-gray-300' : ''}`"
            >${{ loading || !subscriptions ? 0 : subscriptions.total.toFixed(2) }}p/m</span
          >
          <span class="text-sm text-gray-400">Current Subscription</span>
        </div>
        <div class="flex flex-1 flex-col rounded-lg bg-white p-6 shadow-sm">
          <div
            class="mb-2 flex h-[35px] w-[35px] items-center justify-center rounded-md bg-[#22C55E]"
          >
            <div class="h45 w-4">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2 0C8.10063 0 6.4 1.70063 6.4 3.8V5.6H2.2C0.984974 5.6 0 6.58497 0 7.8V11.8C0 13.015 0.984974 14 2.2 14H7.8C9.01503 14 10 13.015 10 11.8V7.8C10 6.58497 9.01503 5.6 7.8 5.6H7.6V3.8C7.6 2.36337 8.76337 1.2 10.2 1.2C11.6366 1.2 12.8 2.36337 12.8 3.8C12.8 4.13137 13.0686 4.4 13.4 4.4C13.7314 4.4 14 4.13137 14 3.8C14 1.70063 12.2994 0 10.2 0ZM7 6.8H2.2C1.64772 6.8 1.2 7.24771 1.2 7.8V11.8C1.2 12.3523 1.64772 12.8 2.2 12.8H7.8C8.35229 12.8 8.8 12.3523 8.8 11.8V7.8C8.8 7.24771 8.35229 6.8 7.8 6.8H7Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <span class="text-lg font-semibold">{{
            loading || !subscriptions ? 0 : subscriptions.models.length
          }}</span>
          <span class="text-sm text-gray-400">Currently Unlocked AI Models</span>
        </div>
      </div>
      <div class="inline-flex h-7 items-center justify-start gap-2">
        <div class="text-base font-bold leading-7 text-slate-700">Current Unlocked AI Models</div>
        <Avatar
          shape="circle"
          :label="`${loading || !subscriptions ? '0' : subscriptions.models.length.toString()}`"
          class="h-7 w-7 bg-green-500 !text-sm text-white"
        />
      </div>
      <div v-if="!loading && subscriptions && subscriptions.models.length > 0">
        <ModelCard v-for="(model, index) in subscriptions.models" :key="index" :model="model" />
      </div>
      <div v-if="loading" class="flex flex-col gap-3">
        <ModelCardSkeleton v-for="(item, index) in Array.from({ length: 1 })" :key="index" />
      </div>
    </div>
  </PlainLayout>
</template>

<script setup lang="ts">
import { userApi } from "@/api/userApi";
import { useAuthStore } from "@/stores/authStore";
import type { Subscriptions } from "@/types/user";
import ModelCard from "@/ui/components/models/ModelCard.vue";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import { showGlobalToast } from "@/utils/toast";
import { onMounted, ref } from "vue";

const authStore = useAuthStore();
const loading = ref(false);
const subscriptions = ref<Subscriptions>(null);

const getSubscriptions = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    subscriptions.value = await userApi.subscriptions();
  } catch (err) {
    console.log(err);
    showGlobalToast("error", "", "An error occurred, please try again.");
  } finally {
    loading.value = false;
  }
};

if (!authStore.user) {
  window.location.href = "/";
}

onMounted(() => {
  getSubscriptions();
});
</script>
