import axiosInstance from '@/api/axiosInstance';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;

export const predictionsApi = {
    async getPredictions(id: string) {
        const response = await api.get(`/predictions/get-model-predictions/${id}`);
        return response;
    },
};
