<template>
  <!-- Desktop -->
  <div
    class="hidden flex-col items-start justify-start self-stretch rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)] md:flex"
  >
    <div
      class="mb-3 flex w-full flex-col items-center justify-start gap-2 border-b border-slate-200"
    >
      <div
        class="h-12 w-12 rounded-[14px]"
        :style="{
          backgroundImage: `url(${builder.avatar ?? '/images/avatar.png'})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
        }"
      ></div>
      <div class="inline-flex flex-col items-start justify-center font-semibold text-slate-700">
        <span class="text-bold text-[14px]">{{ builder.first_name }} {{ builder.last_name }}</span>
      </div>
    </div>

    <!-- Bio on its own row -->
    <div class="mb-2 flex w-full items-start text-center">
      <p class="text-slate-600">{{ StringHelper.truncateText(builder.bio, 85) }}</p>
    </div>

    <!-- View Models Button -->
    <!-- <div> -->
    <div class="mt-2 mt-auto w-full pb-2">
      <div class="mb-2 mt-auto w-full border-b border-slate-200 pb-2"></div>
      <Button
        class="mt-auto h-8 w-full border-purple-500 bg-purple-500"
        @click="selectBuilder(builder)"
      >
        <span class="leading-0 text-xs font-semibold text-white">View Models</span>
      </Button>
    </div>
    <!-- </div> -->
    <!-- Subscribers, likes and views -->
    <div class="flex w-full items-center justify-between gap-2 pt-3">
      <div class="flex items-center justify-start gap-2 overflow-hidden px-0.5">
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(builder.subscribers)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >subscribers</span
          >
        </div>

        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(builder.views)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >views</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div
    class="flex-col items-start justify-start gap-4 self-stretch rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)] md:hidden"
  >
    <!-- Avatar and name -->
    <!-- <div class="mb-4 flex min-h-[70px] flex-col items-start justify-between gap-4 self-stretch py-1"> -->
    <div
      class="mb-3 flex w-full flex-1 flex-col items-center justify-start gap-2 border-b border-slate-200"
    >
      <div
        class="h-12 w-12 rounded-[14px]"
        :style="{
          backgroundImage: `url(${builder.avatar ?? '/images/avatar.png'})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
        }"
      ></div>
      <div class="inline-flex flex-col items-start justify-center font-semibold text-slate-700">
        <span class="text-bold text-[14px]">{{ builder.first_name }} {{ builder.last_name }}</span>
      </div>
    </div>
    <!-- </div> -->

    <!-- Bio -->
    <div class="flex w-full items-start border-b border-slate-200 pb-3 text-center">
      <p class="text-slate-600">{{ StringHelper.truncateText(builder.bio, 100) }}</p>
    </div>

    <!-- View Models Button -->
    <!-- <div> -->
    <Button class="mt-1 h-8 w-full border-purple-500 bg-purple-500" @click="selectBuilder(builder)">
      <span class="leading-0 text-xs font-semibold text-white">View Models</span>
    </Button>
    <!-- </div> -->

    <!-- Subscribers, likes and views -->
    <div class="flex w-full items-center justify-between gap-2 border-t-2 border-slate-100 pt-3">
      <div class="flex items-center justify-start gap-2 overflow-hidden px-0.5">
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(builder.subscribers)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >subscribers</span
          >
        </div>

        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(builder.views)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >views</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { User } from "@/types/user";
// import SvgIcon from "@/ui/components/SvgIcon.vue";
import { formatCount } from "@/utils/numberFormater";
import StringHelper from "@/utils/stringHelper";
import { useRouter } from "vue-router";

const router = useRouter();

defineProps<{
  builder: User;
}>();

const selectBuilder = (builder: User) => {
  router.push({ name: "creator.details", params: { builderId: builder.id } });
};
</script>
