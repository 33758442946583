<template>
  <div v-if="authStore.user?.stripe_product_id" class="leading-7">
    Because you were one of our earliest supporters, we're giving you something BIG: <br />
    <div class="my-3">
      <span class="font-semibold"
        >🔥 You now have access to every AI model currently on the platform - at no extra
        cost!</span
      ><br />
      <span class="font-semibold"
        >💰 Your monthly fee stays the same, but now you get full access.</span
      ><br />
      <span class="font-semibold">🚀 More accurate predictions across sports, crypto and more.</span
      ><br />
    </div>
    <span>Enjoy VIP access to everything 4C Predictions has to offer - dive in now!</span>
    <div class="mt-4 flex w-full justify-center">
      <Button @click="action()">
        <span class="px-4 !text-sm !font-semibold md:px-8"
          >Start Using My Premium AI Predictions</span
        >
      </Button>
    </div>
  </div>
  <div v-else>
    Because you were one of our earliest supporters, we're giving you something BIG:
    <div class="py-3">
      <span class="block font-semibold"
        >🔥 Free access to the current top-performing AI models in sports, crypto, and more!</span
      >
      <span class="block font-semibold">💡 More accuracy, smarter insights, better decisions.</span>
      <span class="block font-semibold"
        >🚀 No payments, no subscriptions - just log in and start using them.</span
      >
    </div>
    Your free AI predictions are ready to explore!
    <div class="mt-4 flex w-full justify-center">
      <Button @click="action()">
        <span class="px-4 !text-sm !font-semibold md:px-8">Check Out My Free AI Models</span>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

const router = useRouter();
const authStore = useAuthStore();

const action = () => {
  authStore.setActiveModal(null);
  authStore.setAuthModalVisible(false);
  router.push("/models?filter=subscribed");
};
</script>
