<template>
  <Carousel
    :value="cards"
    :numVisible="4"
    :numScroll="1"
    :responsiveOptions="responsiveOptions"
    :showNavigators="false"
    :showDots="false"
    :showIndicators="false"
    :itemClass="'carousel-item'"
  >
    <template #item="slotProps">
      <div
        class="dashboard-banner relative m-2 flex h-56 flex-col items-center justify-between overflow-hidden rounded-xl md:m-4 md:mb-2 md:h-64"
      >
        <div
          :style="{
            backgroundImage: `url(${slotProps.data.image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
          class="dashboard-banner-image relative z-0 inline-flex h-[160px] w-full items-center justify-center overflow-hidden rounded-xl bg-black md:h-[200px]"
        ></div>

        <div
          :class="`absolute bottom-0 mb-2 flex h-[106px] w-[90%] flex-col items-start justify-center gap-[17.50px] overflow-hidden rounded-xl bg-gradient-to-r md:h-[130.50px] ${slotProps.data.gradient} p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.30)]`"
        >
          <div class="flex h-[30px] flex-col items-start justify-center gap-[7px] md:h-[45px]">
            <div class="text-md self-stretch font-semibold text-black md:text-lg">
              {{ slotProps.data.title }}
            </div>
            <div class="inline-flex items-start justify-start gap-[7px] self-stretch">
              <div class="shrink grow basis-0 text-xs font-normal text-black md:text-sm">
                {{ slotProps.data.description }}
              </div>
            </div>
          </div>
          <router-link
            :to="slotProps.data.link"
            :class="`inline-flex min-w-[80px] items-center justify-center gap-[7px] rounded-md border ${slotProps.data.actionStyle} px-[10.50px] py-[5px]`"
          >
            <div class="font-['Inter'] text-sm font-medium text-white">
              {{ slotProps.data.actionText }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </Carousel>
</template>

<script setup lang="ts">
import BaseballImage from "@/assets/images/banner/videos/baseball.webp";
import BasketballImage from "@/assets/images/banner/videos/basketball.webp";
import MmadnessImage from "@/assets/images/banner/videos/m_madness.webp";
import SoccerImage from "@/assets/images/banner/videos/soccer.webp";
import { ref } from "vue";

const cards = ref([
  {
    image: MmadnessImage,
    title: "Sean Perry vs Ai",
    description: "Challenge",
    actionText: "View",
    actionStyle: "border-blue-500 bg-blue-500",
    link: "/events/march-madness",
    gradient: "from-sky-300 to-amber-300",
  },
  {
    image: BasketballImage,
    title: "Basketball",
    description: "Who will win the Championship",
    actionText: "View",
    actionStyle: "border-blue-800 bg-blue-800",
    link: "/events/basketball",
    gradient: "from-sky-300 to-pink-300",
  },
  {
    image: SoccerImage,
    title: "English Premier League",
    description: "Who will win the title?",
    actionText: "View",
    actionStyle: "border-violet-500 bg-violet-500",
    link: "/events/soccer",
    gradient: "from-violet-300 to-pink-300",
  },
  {
    image: BaseballImage,
    title: "Major League Baseball",
    description: "Favorites to win the 2025 World Series?",
    actionText: "View",
    actionStyle: "border-red-600 bg-red-600",
    link: "/events/baseball",
    gradient: "from-red-200 to-sky-200",
  },
]);

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2.1,
    numScroll: 2,
  },
  {
    breakpoint: "575px",
    numVisible: 1.2,
    numScroll: 1,
  },
]);
</script>
