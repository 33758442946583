<template>
  <div>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_990_22571)">
    <path d="M18.4242 2.01314V1.45455C18.4242 0.651636 17.7726 0 16.9697 0C7.61212 0 0 7.61212 0 16.9697C0 20.0301 0.762185 22.9644 2.20315 25.4565L2.20511 25.4545C2.47468 25.92 2.96145 26.1818 3.46375 26.1818C3.71005 26.1818 3.9583 26.1198 4.18715 25.9879L4.74376 25.6679C7.54618 29.5952 12.0979 32 16.9697 32C25.2567 32 32 25.2567 32 16.9697C32 9.17333 26.0325 2.74817 18.4242 2.01314ZM15.5152 2.98473V16.1261L4.08437 22.6909C3.32413 20.9435 2.90909 18.9983 2.90909 16.9678C2.90909 9.70671 8.44218 3.71588 15.5152 2.98473ZM16.9697 29.0909C13.1355 29.0909 9.53988 27.2504 7.26885 24.2192L17.695 18.2322C18.1469 17.9724 18.4242 17.4914 18.4242 16.9717V4.94549C24.4228 5.66694 29.0909 10.7811 29.0909 16.9717C29.0909 23.6529 23.6528 29.0909 16.9697 29.0909Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_990_22571">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  </div>
</template>
