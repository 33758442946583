<template>
  <div
    class="inline-flex min-h-[228px] shrink grow basis-0 flex-col items-start justify-start gap-4 self-stretch overflow-hidden rounded-xl bg-white/50 p-[17.50px] shadow shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]"
  >
    <div class="inline-flex items-center justify-start gap-2 self-stretch">
      <Skeleton shape="rectangle" width="42px" height="42px" class="rounded-md" />
      <div class="inline-flex flex-col items-start justify-center gap-1">
        <Skeleton width="150px" height="18px" class="rounded-full" />
      </div>
    </div>
    <div class="inline-flex flex-col items-start justify-start gap-1 self-stretch">
      <Skeleton width="100%" height="10px" class="rounded-full" />
      <Skeleton width="100%" height="10px" class="rounded-full" />
    </div>
    <Skeleton width="100%" height="33px" class="rounded-md" />
    <div class="inline-flex items-center justify-between self-stretch py-1">
      <div class="flex items-center justify-start gap-0.5 overflow-hidden px-0.5">
        <Skeleton width="80px" height="16px" class="rounded" />
      </div>
      <div class="flex items-center justify-end gap-2">
        <Skeleton width="60px" height="16px" class="rounded" />
        <Skeleton width="120px" height="16px" class="rounded" />
      </div>
    </div>
  </div>
</template>
