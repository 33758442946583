import axiosInstance from '@/api/axiosInstance';
import type { User } from '@/types/user';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;
const perPage = '8';

export const buildersApi = {
    async getBuilders(filters: Record<string, string>) {
        if (!filters.per_page) {
            filters.per_page = perPage;
        }
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/builders?${queryString}`);
        return response;
    },

    async getSingleBuilder(id: string): Promise<User> {
        const response = await api.get(`/builders/${id}`);
        return response.data.data;
    },

    async getSingleModel(id: string): Promise<User> {
        const response = await api.get(`/models/${id}`);
        return response.data.data;
    },

    async subscribeModel(modelId: string, userId): Promise<User> {
        const response = await api.post("/models/subscribe", { model_id: modelId, user_id: userId });
        return response.data.data;
    },

    async unSubscribeModel(modelId: string, userId): Promise<User> {
        const response = await api.post("/models/unsubscribe", { model_id: modelId, user_id: userId });
        return response.data.data;
    },
};
