<template>
  <div>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_990_22604)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M29.4414 3.96136L31.6935 4.71207C32.1847 4.87582 32.1847 5.57069 31.6935 5.73444L29.4414 6.48515C29.2805 6.53878 29.1542 6.66504 29.1006 6.82594L28.3499 9.07807C28.1861 9.56933 27.4912 9.56933 27.3275 9.07807L26.5768 6.82594C26.5231 6.66504 26.3969 6.53878 26.236 6.48515L23.9839 5.73444C23.4926 5.57069 23.4926 4.87582 23.9839 4.71207L26.236 3.96136C26.3969 3.90773 26.5231 3.78147 26.5768 3.62057L27.3275 1.36844C27.4912 0.877186 28.1861 0.877186 28.3499 1.36844L29.1006 3.62057C29.1542 3.78147 29.2805 3.90773 29.4414 3.96136ZM12.0213 3.51233C12.4853 2.12043 14.4541 2.12045 14.918 3.51233L17.6778 11.7918L25.9573 14.5516C27.3492 15.0156 27.3492 16.9844 25.9573 17.4484L17.6778 20.2082L14.918 28.4877C14.4541 29.8796 12.4853 29.8795 12.0213 28.4877L9.26147 20.2082L0.981991 17.4484C-0.409913 16.9844 -0.409889 15.0156 0.981991 14.5516L9.26147 11.7918L12.0213 3.51233ZM13.4697 7.68701L11.6328 13.1976C11.4808 13.6535 11.1231 14.0112 10.6672 14.1631L5.15668 16L10.6672 17.8369C11.1231 17.9888 11.4808 18.3465 11.6328 18.8024L13.4697 24.313L15.3065 18.8024C15.4585 18.3465 15.8162 17.9888 16.2721 17.8369L21.7826 16L16.2721 14.1631C15.8162 14.0112 15.4585 13.6535 15.3065 13.1976L13.4697 7.68701ZM31.6935 26.2656L29.4414 25.5148C29.2805 25.4612 29.1542 25.335 29.1006 25.1741L28.3499 22.9219C28.1861 22.4307 27.4912 22.4307 27.3275 22.9219L26.5768 25.1741C26.5231 25.335 26.3969 25.4612 26.236 25.5148L23.9839 26.2656C23.4926 26.4293 23.4926 27.1242 23.9839 27.2879L26.236 28.0386C26.3969 28.0923 26.5231 28.2185 26.5768 28.3794L27.3275 30.6316C27.4912 31.1228 28.1861 31.1228 28.3499 30.6316L29.1006 28.3794C29.1542 28.2185 29.2805 28.0923 29.4414 28.0386L31.6935 27.2879C32.1847 27.1242 32.1847 26.4293 31.6935 26.2656Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_990_22604">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
