<template>
  <GridLayout>
    <template #leftSidebar>
      <EventLeftSidebar />
    </template>

    <div v-if="!eventStore.isLoading && eventStore.currentEvent" class="p-2 md:p-4">
      <EventSubCategoriesMobile />

      <div class="flex w-full flex-col items-start justify-start">
        <!-- Event header (teams, logos, date and background video) -->
        <div
          class="sticky top-4 z-40 w-full overflow-hidden rounded-xl bg-black p-4 text-center text-white"
        >
          <!-- Background video -->
          <video
            autoplay
            muted
            loop
            playsinline
            class="absolute left-0 top-0 z-0 h-full w-full object-cover"
          >
            <source :src="`/videos/${event_type_slug}-vid-bg.mp4`" type="video/mp4" />
            <!-- <source src="/path/to/your-video.webp" type="video/webp" /> -->
            <!-- Fallback text -->
            Your browser does not support the video tag.
          </video>

          <!-- Content that appears over the video -->
          <div class="relative z-10">
            <!-- Event sub type -->
            <div class="w-full text-center text-xs md:text-base">{{ event_sub_type_name }}</div>

            <!-- Teams and logos -->
            <div class="flex w-full justify-center gap-5 md:gap-20">
              <!-- Home Team -->
              <div class="flex w-[120px] flex-col items-center text-center">
                <img :src="imageSrc" class="h-12 w-12 rounded-md" />
                <div class="text-xs font-semibold md:text-nowrap">
                  {{ home_team }}
                </div>
              </div>

              <!-- VS -->
              <div class="flex w-[60px] items-center justify-center text-4xl">vs</div>

              <!-- Away Team -->
              <div class="flex w-[120px] flex-col items-center text-center">
                <img :src="imageSrc2" class="h-12 w-12 rounded-md" />
                <div class="text-xs font-semibold md:text-nowrap">
                  {{ away_team }}
                </div>
              </div>
            </div>

            <!-- Event date and time -->
            <div class="flex items-center justify-center self-stretch py-1 text-[10px]">
              <!-- <div class="flex items-center justify-center gap-2 overflow-hidden"> -->
              <!-- <div class="flex items-center justify-start gap-2 overflow-hidden"> -->
              <div class="font-medium leading-tight">
                {{
                  formatDateTime(eventStore.currentEvent?.start_time!, undefined, true, "short")
                    .formattedDate
                }}
              </div>
              <!-- </div> -->
              <!-- <div class="flex items-center justify-start gap-2 overflow-hidden"> -->
              <div class="font-medium leading-tight">,&nbsp;</div>
              <div class="font-medium leading-tight">
                {{
                  formatDateTime(
                    eventStore.currentEvent?.start_time!,
                    undefined,
                    true,
                    "short",
                    false,
                  ).formattedTime
                }}
              </div>
              <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
        </div>

        <!-- Prediction cards -->
        <div
          class="w-full"
          v-if="!eventStore.isPredictionsLoading && displayPredictions.length > 0"
        >
          <div class="mt-5 flex w-full flex-col gap-4">
            <PredictionCard
              v-for="(prediction, index) in showAllPredictions
                ? displayPredictions
                : displayPredictions.slice(0, 1)"
              :key="prediction.id"
              :prediction="prediction"
              :index="index"
            />
          </div>

          <div
            v-if="!showAllPredictions && displayPredictions.length > 1"
            class="mt-4 flex w-full items-center justify-center"
          >
            <Button @click="showAllPredictions = true"> View AI More Predictions </Button>
          </div>
        </div>

        <div
          v-if="!eventStore.isPredictionsLoading && displayPredictions.length == 0"
          class="mt-8 flex w-full items-start justify-center overflow-hidden rounded-md border border-yellow-200 shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
        >
          <div
            class="flex shrink grow basis-0 items-center justify-center gap-[7px] bg-yellow-50/95 px-[10.50px] py-[7px] shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
          >
            <div class="relative overflow-hidden">
              <SvgIcon name="info" />
            </div>
            <div>
              <span class="h-0.75 font-['Inter'] text-sm font-medium text-yellow-600"
                >No predictions available for this event yet.<br />Please check back soon.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="eventStore.isLoading" class="p-2 md:p-4">
      <div class="inline-flex w-full flex-col items-start justify-start">
        <div class="mb-4 flex flex-col items-start justify-start gap-2 self-stretch">
          <div class="inline-flex items-center justify-start gap-2 self-stretch">
            <Skeleton width="42px" height="42px" class="rounded-md" />
            <Skeleton class="max-w-[280px] shrink grow basis-0 rounded-md" />
          </div>
          <div class="h-[48px]">
            <Skeleton height="2px" class="self-stretch rounded-md pb-1" />
            <Skeleton height="3px" width="40%" class="self-stretch rounded-md" />
          </div>
          <div
            class="inline-flex h-9 items-center justify-between self-stretch border-b border-slate-200 py-2"
          >
            <div class="flex items-center justify-start gap-4 overflow-hidden">
              <div class="flex items-center justify-start gap-2 overflow-hidden">
                <div class="relative h-4 w-4 overflow-hidden">
                  <Skeleton height="10px" width="13px" class="" />
                </div>
                <div class="text-sm font-medium leading-tight text-slate-500">
                  <Skeleton height="3px" width="120px" class="rounded-md" />
                </div>
              </div>
              <div class="flex items-center justify-start gap-2 overflow-hidden">
                <div class="relative h-4 w-4 overflow-hidden">
                  <Skeleton height="10px" width="13px" class="rounded-md" />
                </div>
                <div class="text-sm font-medium leading-tight text-slate-500">
                  <Skeleton height="3px" width="100px" class="rounded-md" />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-end gap-4">
              <div class="flex items-center justify-start gap-2 overflow-hidden">
                <div class="relative h-4 w-4 overflow-hidden">
                  <Skeleton height="10px" width="13px" class="rounded-md" />
                </div>
                <Skeleton height="3px" width="20px" class="hidden rounded-md md:block" />
              </div>
              <div class="flex items-center justify-start gap-2 overflow-hidden">
                <div class="relative h-4 w-4 overflow-hidden">
                  <Skeleton height="10px" width="13px" class="rounded-md" />
                </div>
                <div class="hidden md:block">
                  <Skeleton height="3px" width="50px" class="hidden rounded-md md:block" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full items-center justify-between gap-4">
          <Skeleton height="30px" width="90%" />
          <Skeleton height="30px" width="10%" />
        </div>
        <div class="w-full">
          <Skeleton height="8px" width="150px" class="mb-4 mt-4 self-stretch rounded-md" />
          <div class="flex w-full flex-col gap-4">
            <ModelCardSkeleton />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full px-2 md:px-4" v-if="eventStore.isPredictionsLoading">
      <Skeleton height="8px" width="150px" class="mb-4 mt-4 self-stretch rounded-md" />
      <div class="flex w-full flex-col gap-4">
        <ModelCardSkeleton v-for="(prediction, index) in Array.from({ length: 2 })" :key="index" />
      </div>
    </div>

    <template #rightSidebar>
      <EventRelatedPosts />
    </template>

    <SocialShareModal
      v-model:isVisible="showShareModal"
      :title="shareTitle"
      :description="shareDescription"
      :imageUrl="shareImageUrl"
      :url="shareUrl"
      type="event"
    />
  </GridLayout>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import { useErrorStore } from "@/stores/errorStore";
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Prediction } from "@/types/prediction";
import EventLeftSidebar from "@/ui/components/events/EventLeftSidebar.vue";
import EventRelatedPosts from "@/ui/components/events/EventRelatedPosts.vue";
import EventSubCategoriesMobile from "@/ui/components/events/EventSubCategoriesMobile.vue";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import PredictionCard from "@/ui/components/predictions/PredictionCard.vue";
import SocialShareModal from "@/ui/components/SocialShareModal.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import GridLayout from "@/ui/layouts/GridLayout.vue";
import { formatDateTime } from "@/utils/dateFormatter";
import { inject, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const updateMeta = inject("updateMeta") as (meta) => void;
const eventStore = useEventStore();
const authStore = useAuthStore();
const eventTypesStore = useEventTypesStore();
const errorStore = useErrorStore();
const route = useRoute();
const router = useRouter();
const searched = ref(false);
const showShareModal = ref(false);
const predictions = ref<Prediction[]>([]);
const displayPredictions = ref<Prediction[]>([]);

const showAllPredictions = ref(true);

const selectedFilter = ref("all");
const selectedSortFilter = ref("most_accurate");

const shareTitle = ref(null);
const shareDescription = ref(null);
const shareImageUrl = ref(null);
const shareUrl = ref(null);

const imageSrc = ref();
const imageSrc2 = ref();
const home_team = ref();
const away_team = ref();
const event_sub_type_name = ref();
const eventType = ref(null);
const event_type_slug = ref();

const fetchData = async () => {
  if (route.params.eventId) {
    eventStore.setIsLoading(true);
    await eventStore.fetchSingleEvent(route.params.eventId as string);
    fetchPredictions();

    if (eventStore.currentEvent) {
      home_team.value = eventStore.currentEvent.metadata.find((x) => x.key === "home_team")?.value;
      away_team.value = eventStore.currentEvent.metadata.find((x) => x.key === "away_team")?.value;
      event_sub_type_name.value = eventStore.currentEvent.event_sub_type_name;
      eventType.value = eventTypesStore.getChildTypeById(eventStore.currentEvent.event_sub_type_id);
      event_type_slug.value = eventType.value?.parent.slug;
      imageSrc.value = eventStore.currentEvent?.event_image;
      imageSrc2.value = eventStore.currentEvent?.event_image_2 ?? null;
      shareImageUrl.value = eventStore.currentEvent.event_image ?? null;
      shareTitle.value = eventStore.currentEvent.name;
      shareDescription.value = eventStore.currentEvent.description;
      shareUrl.value = window.location.href;
      updateMeta({
        title: `4CPredictions - ${eventStore.currentEvent.name}`,
      });
    } else {
      errorStore.setError("Event not found");
      router.push({ name: "not-found" });
    }
    eventStore.setIsLoading(false);
  }
};

const fetchPredictions = () => {
  eventStore.fetchPredictions(route.params.eventId as string).then((result) => {
    predictions.value = result;

    if (predictions.value.length > 0) {
      displayPredictions.value = predictions.value.sort(
        (a, b) => b.model.current_accuracy - a.model.current_accuracy,
      );
    }
  });
};

watch(
  () => route.params.eventId,
  () => {
    showAllPredictions.value = true;
    fetchData();
  },
  { immediate: true },
);

watch(
  () => authStore.user,
  () => {
    if (authStore.user && authStore.user.has_access) {
      fetchPredictions();
    }
  },
  { immediate: true },
);

watch(
  () => selectedFilter.value,
  () => {
    switch (selectedFilter.value.toLowerCase()) {
      case "free":
        displayPredictions.value = predictions.value.filter((item) => item.model.price === 0);
        break;
      case "paid":
        displayPredictions.value = predictions.value.filter((item) => item.model.price > 0);
        break;
      case "subscribed":
        displayPredictions.value = predictions.value;
        break;
      default:
        displayPredictions.value = predictions.value;
    }
    searched.value = true;
  },
  { immediate: true },
);

const filters = ref([
  { name: "All Ai Models", code: "all" },
  { name: "Premium Ai Models", code: "paid" },
  { name: "Free Ai Models", code: "free" },
]);

watch(
  () => selectedSortFilter.value,
  () => {
    switch (selectedSortFilter.value.toLowerCase()) {
      case "recently_updated":
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) =>
            new Date(b.model.last_trained).getTime() - new Date(a.model.last_trained).getTime(),
        );
        break;
      case "least_accurate":
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) => a.model.current_accuracy - b.model.current_accuracy,
        );
        break;
      case "most_accurate":
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) => b.model.current_accuracy - a.model.current_accuracy,
        );
        break;
      case "most_expensive":
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) => b.model.price - a.model.price,
        );
        break;
      case "least_expensive":
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) => a.model.price - b.model.price,
        );
        break;
      default:
        displayPredictions.value = displayPredictions.value.sort(
          (a, b) => b.model.current_accuracy - a.model.current_accuracy,
        );
    }
  },
  { immediate: true },
);

watch(
  () => authStore.user,
  () => {
    if (authStore.user && !filters.value.find((x) => x.code === "subscribed")) {
      filters.value.splice(1, 0, { name: "My Ai Models", code: "subscribed" });
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  eventStore.clearCurrentEvent();
});
</script>
