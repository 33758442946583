<script setup lang="ts">
import LockIcon from "@/assets/images/lock.png";
const lockIcon = LockIcon;
</script>

<template>
  <div class="flex items-center justify-start gap-2">
    <img class="relative top-[-1px] h-4 w-4" :src="lockIcon" />
    <div class="inline-flex flex-col items-start justify-center">
      <div
        class="h-[8px] w-[100px] justify-start rounded-md bg-surface-300 text-xs font-bold leading-tight"
      ></div>
    </div>
  </div>
</template>
