<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { useMeta } from "@/composables/useMeta";
import { provide } from "vue";

const { updateMeta } = useMeta();
provide("updateMeta", updateMeta);
</script>
