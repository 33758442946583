import { predictionsApi } from "@/api/predictionsApi";
import type { Prediction } from "@/types/prediction";
import { defineStore } from "pinia";
import { ref } from "vue";

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const now = Date.now();

export const usePredictionStore = defineStore("predictions_store", () => {
  const predictions = ref<Prediction[]>([]);
  const isLoading = ref(false);
  const hasMore = ref([]);
  const lastFetched = ref({});

  function setIsLoading(loading: boolean) {
    isLoading.value = loading;
  }

  const fetchPredictions = async (id: string, withReview: boolean = false) => {
    const key = `${id}_${withReview ? '_review' : 'no_review'}`;
    if (predictions.value[key] && now - (lastFetched.value[key] || 0) < CACHE_DURATION) {
      return { predictions: predictions.value[key], more: hasMore.value[key] || false };
    }

    try {
      isLoading.value = true;
      const response = await predictionsApi.getPredictions(id);
      predictions.value[key] = response.data;
      lastFetched.value[key] = now;
      return { predictions: predictions.value[key], more: hasMore.value[key] };
    } catch (error) {
      console.error("Error fetching predictions:", error);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    predictions,
    isLoading,
    hasMore,
    setIsLoading,
    fetchPredictions,
  };
});
