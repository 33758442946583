<template>
  <div
    class="scroll-container flex w-full items-start gap-4 overflow-x-auto bg-white py-2 md:hidden"
  >
    <div v-if="!eventTypesStore.loading" class="flex items-start gap-0.5">
      <div class="flex items-center" v-for="type in eventTypesStore.types" :key="type.id">
        <router-link
          :to="`/models/${type.slug}`"
          :class="{
            'pointer-events-none opacity-80': modelStore.isLoading,
            'bg-slate-200': selectedType == type.slug && !selectedChildType,
          }"
          class="flex items-center justify-start gap-[7px] self-stretch whitespace-nowrap rounded px-[10.50px] py-[7px] hover:bg-slate-200"
        >
          <div class="flex h-4 w-4 items-center justify-center overflow-hidden rounded">
            <TrophyIcon v-if="type.is_sport" class="h-4 w-4" />
            <GraphIcon v-if="type.is_crypto" class="h-4 w-4" />
            <div
              v-if="!type.is_sport && !type.is_crypto"
              class="h-4 w-4 bg-slate-300"
              :style="{
                backgroundImage: `url(${type.logo_url})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
            ></div>
          </div>
          <div class="shrink grow basis-0 text-sm font-medium leading-tight text-slate-800">
            All {{ type.name }}
          </div>
        </router-link>
        <div class="flex items-center">
          <router-link
            v-for="child in eventTypesStore.getTypeChildren(type.id)"
            :key="child.id"
            :to="`/models/${type.slug}/${child.slug}`"
            :class="{
              'pointer-events-none opacity-80': modelStore.isLoading,
              'bg-slate-200': selectedChildType == child.slug,
            }"
            class="flex items-center gap-[7px] whitespace-nowrap rounded px-[10.50px] py-[7px] hover:bg-slate-200"
          >
            <div class="flex h-4 w-4 items-center justify-center overflow-hidden rounded">
              <div
                class="h-4 w-4 bg-slate-300"
                :style="{
                  backgroundImage: `url(${child.logo_url})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }"
              ></div>
            </div>
            <div class="shrink text-sm font-medium leading-tight text-slate-800">
              {{ child.name }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <Skeleton
        v-for="(item, index) in Array.from({ length: 8 })"
        :key="index"
        height="33px w-full bg-slate-200"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEventTypesStore } from "@/stores/eventTypesStore";
import { useModelStore } from "@/stores/modelStore";
import Skeleton from "primevue/skeleton";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import GraphIcon from "../icons/GraphIcon.vue";
import TrophyIcon from "../icons/TrophyIcon.vue";

const modelStore = useModelStore();
const eventTypesStore = useEventTypesStore();

const route = useRoute();
const selectedType = ref((route.params.type as string) || "all");
const selectedChildType = ref((route.params.subType as string) || null);

watch(
  () => route.params.type,
  () => {
    if (route.params.type) {
      selectedType.value = route.params.type as string;
      selectedChildType.value = null;
    } else {
      selectedType.value = "all";
    }
  },
);

watch(
  () => route.params.subType,
  () => {
    if (route.params.subType) {
      selectedChildType.value = route.params.subType as string;
    } else {
      selectedChildType.value = null;
    }
  },
);
</script>
