<template>
  <div class="flex flex-col items-start justify-start gap-4 overflow-hidden bg-zinc-950 p-4 pb-12">
    <div class="flex flex-col items-start justify-between gap-4 self-stretch md:flex-row">
      <router-link to="/"><SiteLogo /></router-link>
      <div
        class="flex min-w-[690px] flex-col items-start justify-between gap-12 self-stretch md:flex-row md:justify-end"
      >
        <div
          class="inline-flex max-w-[200px] shrink grow flex-col items-start justify-start gap-4 self-stretch overflow-hidden"
        >
          <div class="text-base font-semibold leading-normal text-white">Predictions</div>
          <div class="flex flex-col items-center justify-center gap-1 self-stretch">
            <router-link
              v-for="(type, index) in eventTypesStore.types"
              :key="index"
              :to="{ name: 'events', params: { type: type.slug } }"
              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"
            >
              {{ type.name }}
            </router-link>
          </div>
        </div>
        <div
          class="inline-flex max-w-[200px] shrink grow flex-col items-start justify-start gap-4 self-stretch overflow-hidden"
        >
          <div class="text-base font-semibold leading-normal text-white">Resources</div>
          <div class="flex flex-col items-center justify-center gap-1 self-stretch">
            <!--            <a-->
            <!--              href="https://billing.stripe.com/p/login/fZeaETaIkg839d6cMM"-->
            <!--              target="_blank"-->
            <!--              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"-->
            <!--            >-->
            <!--              Manage Subscription-->
            <!--            </a>-->
            <!--            <a-->
            <!--              href="https://billing.stripe.com/p/login/fZeaETaIkg839d6cMM"-->
            <!--              target="_blank"-->
            <!--              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"-->
            <!--            >-->
            <!--              Cancel Subscription-->
            <!--            </a>-->
            <router-link
              :to="{ name: 'contact-us' }"
              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"
            >
              Contact Us
            </router-link>
            <router-link
              :to="{ name: 'submit-bug' }"
              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"
            >
              Submit a Bug
            </router-link>
            <router-link
              :to="{ name: 'profile-cancel-subscription' }"
              class="self-stretch text-sm leading-[21px] text-white hover:text-primary"
            >
              Cancel Subscription
            </router-link>
          </div>
        </div>
        <div
          class="inline-flex max-w-[210px] shrink grow flex-col items-start justify-start gap-4 self-stretch overflow-hidden"
        >
          <div class="text-base font-semibold leading-normal text-white">Join The Community</div>
          <div class="flex w-full items-start justify-between gap-6">
            <div class="flex items-center justify-center rounded-md py-[7px]">
              <a
                href="https://www.facebook.com/4cpredictions"
                target="_blank"
                class="relative h-5 w-5 overflow-hidden md:h-5 md:w-5"
              >
                <SvgIcon name="facebook" />
              </a>
            </div>
            <div class="flex items-center justify-center rounded-md py-[7px]">
              <a
                href="https://www.instagram.com/4cpredictions"
                target="_blank"
                class="relative h-5 w-5 overflow-hidden md:h-5 md:w-5"
              >
                <SvgIcon name="instagram" />
              </a>
            </div>
            <div class="flex items-center justify-center rounded-md py-[7px]">
              <a
                href="https://x.com/4cpredictions"
                target="_blank"
                class="relative h-5 w-5 overflow-hidden md:h-5 md:w-5"
              >
                <SvgIcon name="twitter" />
              </a>
            </div>
            <div class="flex items-center justify-center rounded-md py-[7px]">
              <a
                href="https://www.linkedin.com/company/4c-predictions"
                target="_blank"
                class="relative h-5 w-5 overflow-hidden md:h-5 md:w-5"
              >
                <SvgIcon name="linkedin" />
              </a>
            </div>
            <div class="flex items-center justify-center rounded-md py-[7px]">
              <a
                href="http://tiktok.com/@4cpredictions"
                target="_blank"
                class="relative h-5 w-5 overflow-hidden md:h-5 md:w-5"
              >
                <SvgIcon name="tiktok" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-start justify-start gap-4 self-stretch border-t border-zinc-700 md:flex-row"
    >
      <div
        class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-4 self-stretch overflow-hidden rounded-xl py-[17.50px]"
      >
        <div class="text-xs font-normal leading-[18.38px] text-white">
          4C Predictions - All Rights Reserved © 2025
        </div>
      </div>
      <div
        class="inline-flex shrink grow flex-col items-start justify-start gap-4 self-stretch overflow-hidden rounded-xl py-[17.50px]"
      >
        <div class="inline-flex items-center justify-start gap-4 self-stretch md:justify-end">
          <router-link
            :to="{ name: 'privacy-policy' }"
            class="text-xs font-normal leading-[18.38px] text-white hover:text-primary"
          >
            Privacy Policy
          </router-link>
          <router-link
            :to="{ name: 'cookie-policy' }"
            class="text-xs font-normal leading-[18.38px] text-white hover:text-primary"
          >
            Cookie Policy
          </router-link>
          <router-link
            :to="{ name: 'terms-of-use' }"
            class="text-xs font-normal leading-[18.38px] text-white hover:text-primary"
          >
            Terms & Conditions
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <ScrollToTop />
</template>

<script setup lang="ts">
import { useEventTypesStore } from "@/stores/eventTypesStore";
import ScrollToTop from "../components/ScrollToTop.vue";
import SiteLogo from "../components/SiteLogo.vue";
import SvgIcon from "../components/SvgIcon.vue";
const eventTypesStore = useEventTypesStore();
</script>
