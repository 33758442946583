<template>
  <div
    class="scroll-container mb-4 flex h-[34px] items-start justify-start overflow-hidden overflow-x-auto rounded-md md:hidden"
  >
    <router-link
      v-for="(type, index) in subTypes"
      :key="index"
      :to="`/events/${type.parent?.slug}/${type.slug}`"
      :class="`inline-flex items-center justify-start gap-[7px] self-stretch rounded ${currentEventType.value?.id == type.id ? 'bg-slate-200' : ''} px-[10.50px] py-[7px] hover:bg-slate-200`"
    >
      <div class="flex h-3.5 w-3.5 items-center justify-center overflow-hidden rounded">
        <img class="h-3.5 w-3.5" :src="type.logo_url" />
      </div>
      <div
        class="font-['Inter 24pt'] shrink grow basis-0 text-nowrap text-sm font-medium leading-tight text-slate-800"
      >
        {{ type.name }}
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import { onMounted, ref, watch } from "vue";

const eventStore = useEventStore();
const eventTypesStore = useEventTypesStore();
const subTypes = ref();
const currentEventType = ref(null);

const setTypes = async () => {
  if (eventStore.currentEvent) {
    currentEventType.value = eventTypesStore.getChildTypeById(
      eventStore.currentEvent?.event_sub_type_id,
    );
    subTypes.value = eventTypesStore.getTypeChildren(currentEventType.value?.parent?.id);
  }
};

watch(
  () => eventStore.currentEvent,
  () => {
    setTypes();
  },
  { immediate: true },
);

onMounted(() => {
  setTypes();
});
</script>
