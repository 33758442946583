<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Prediction } from "@/types/prediction";
import { formatCount } from "@/utils/numberFormater";
import Accordion from "primevue/accordion";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";
import AccordionPanel from "primevue/accordionpanel";
import { computed, onMounted, ref } from "vue";
import Last10PredictionsBadge from "../models/Last10PredictionsBadge.vue";
import ModelAccuracyBadge from "../models/ModelAccuracyBadge.vue";
import ModelActionButton from "../models/ModelActionButton.vue";
import SocialShareModal from "../SocialShareModal.vue";
import SvgIcon from "../SvgIcon.vue";
import MobilePredictionHead from "./MobilePredictionHead.vue";
import SportsPredictionResult from "./SportsPredictionResult.vue";

const props = defineProps<{
  prediction: Prediction;
  index?: number;
}>();

const authStore = useAuthStore();
const eventTypeStore = useEventTypesStore();
const moreInfoModalVisible = ref(false);
const modelAvatar = ref(null);
const eventType = ref(null);
const showShareModal = ref(false);

const shareTitle = ref(null);
const shareDescription = ref(null);
const shareImageUrl = ref(null);
const shareUrl = ref(null);

onMounted(() => {
  eventType.value = eventTypeStore.getChildTypeById(props.prediction.model?.event_sub_type_id);
  const metadataItem = props.prediction.model.metadata.find(
    (metadata) => metadata.key === "Avatar",
  );
  modelAvatar.value = metadataItem?.value;

  shareImageUrl.value = modelAvatar.value;
  shareTitle.value = props.prediction.model?.name;
  shareDescription.value = props.prediction.model?.description;
  shareUrl.value = window.location.href;
});

const fallbackHtml = `
<p>
  Predictive AI models leverage sophisticated machine learning architectures
  and statistical methodologies to analyze vast datasets, uncover latent
  patterns, and forecast future outcomes with a high degree of probabilistic precision.
  These models operate by ingesting historical data, identifying intricate
  relationships between variables, and constructing mathematical frameworks
  that optimize predictive accuracy over time.
</p>
<p>
  At the core of these systems are advanced computational techniques such as:
</p>
<ul>
  <li>
    <b>Supervised Learning</b> – Training on labeled datasets to establish
    correlations between past inputs and known outcomes.
  </li>
  <li>
    <b>Neural Networks & Deep Learning</b> – Multi-layered structures inspired
    by the human brain, capable of recognizing nonlinear dependencies and
    extracting high-dimensional features.
  </li>
  <li>
    <b>Bayesian Inference & Probability Distributions</b> – Modeling
    uncertainty and integrating prior knowledge to refine predictions
    and reasoning.
  </li>
  <li>
    <b>Feature Engineering & Dimensionality Reduction</b> – Identifying
    and refining critical variables to enhance predictive signal
    while reducing noise.
  </li>
</ul>
<p>
  All these AI models are dynamic, continuously recalibrating their
  internal parameters as new data streams in, allowing them to adapt
  to evolving trends and refine their accuracy over time. While no
  model can achieve perfect predictability, AI represents the cutting
  edge of data-driven forecasting, offering a valuable tool for
  prediction and decision-making in complex, uncertain environments.
</p>
`;

const renderedDescription = computed(() => {
  const metaDesc = props.prediction.model.meta_description;
  return metaDesc && metaDesc.trim() ? metaDesc : fallbackHtml;
});
</script>

<template>
  <div class="inline-flex flex-col items-center justify-center self-stretch">
    <div
      :class="{
        'rounded-tr-none border-primary-500 pt-8': index == 0,
        'border-slate-200': index != 0,
      }"
      class="relative flex flex-col items-center justify-center gap-2 self-stretch rounded-tl-lg rounded-tr-lg border-l border-r border-t bg-white py-4 md:py-2"
    >
      <div
        v-if="index! == 0"
        class="absolute right-0 top-0 inline-flex h-[24.50px] min-w-[24.50px] flex-col items-center justify-center rounded-bl-md bg-lime-400 px-[7px]"
      >
        <div class="justify-start text-xs font-bold text-slate-600">Top Performer</div>
      </div>
      <div
        :class="{ '!mt-5 md:mt-2': index! == 0 }"
        class="hidden items-center justify-between self-stretch px-4 md:inline-flex"
      >
        <div
          class="flex flex-1 flex-col items-start justify-start gap-2 md:flex-row md:items-center"
        >
          <div class="flex flex-1 items-center justify-start gap-2">
            <div class="flex items-center justify-start gap-2">
              <div
                class="h-[42px] w-[42px] rounded-md"
                :style="{
                  backgroundImage: `url(${modelAvatar ?? '/images/avatar.png'})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }"
              ></div>
              <div class="flex flex-1 flex-col items-start justify-center">
                <div class="flex flex-col items-start justify-start">
                  <div
                    class="justify-start text-[10px] font-bold uppercase leading-3 tracking-tight text-slate-500"
                  >
                    Ai Model
                  </div>
                </div>
                <div
                  class="justify-start text-sm font-bold leading-none text-slate-700 md:text-base"
                >
                  {{ prediction.model.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-1 items-center justify-start gap-2">
            <div
              class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
              :style="{
                backgroundImage: `url(${prediction.model.creator.avatar ?? '/images/avatar.png'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
            ></div>
            <div class="inline-flex flex-1 flex-col items-start justify-center">
              <div class="flex flex-col items-start justify-start">
                <div
                  class="justify-start text-[10px] font-bold uppercase leading-3 tracking-tight text-slate-500"
                >
                  Built by AI Genius
                </div>
                <div
                  class="justify-start text-sm font-semibold leading-none text-slate-700 md:text-base"
                >
                  {{ prediction.model.creator.first_name }} {{ prediction.model.creator.last_name }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-1 items-center justify-start gap-2">
            <ModelAccuracyBadge :model="prediction.model" />
          </div>
          <div class="flex items-center justify-end gap-2">
            <Last10PredictionsBadge
              :last10PredictionResults="prediction.model.last_ten_prediction"
            />
          </div>
        </div>
      </div>

      <MobilePredictionHead :prediction="prediction" />
      <div class="flex flex-col items-center justify-center self-stretch px-4">
        <div class="relative h-px self-stretch bg-slate-200"></div>
      </div>

      <Accordion
        value="0"
        class="w-full"
        expand-icon="pi pi-plus-circle"
        collapse-icon="pi pi-minus-circle"
      >
        <!-- MODEL OVERVIEW -->
        <AccordionPanel value="1" class="border-0">
          <AccordionHeader class="py-1"
            ><div
              class="justify-start py-0 text-xs font-bold uppercase leading-3 tracking-tight text-slate-500"
            >
              How This Model Works
            </div></AccordionHeader
          >
          <AccordionContent class="pb-0 pt-4">
            <div v-if="prediction.event.is_sport" class="border-b border-slate-200 pb-4">
              <p class="m-0 mb-3">
                This AI model predicts {{ eventType?.name ?? "all" }} events and provides
                predictions for:
                <!-- winner, loser, and over/under. -->
              </p>
              <div class="space-y-2">
                <div v-if="prediction.winner && prediction.winner.name" class="flex items-start">
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Winner</strong>
                  </span>
                </div>
                <div
                  v-if="prediction.winner && prediction.winner.probability"
                  class="flex items-start"
                >
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Winner Probability</strong>
                  </span>
                </div>
                <div
                  v-if="prediction.winner && prediction.winner.best_bet_odds"
                  class="flex items-start"
                >
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Winner Best Bet Odds</strong>
                  </span>
                </div>
                <div
                  v-if="prediction.winner && prediction.winner.over_under"
                  class="flex items-start"
                >
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Over/Under</strong>
                  </span>
                </div>
                <div
                  v-if="prediction.winner && prediction.winner.over_under_probability"
                  class="flex items-start"
                >
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Over/Under Probability</strong>
                  </span>
                </div>
                <div
                  v-if="prediction.winner && prediction.winner.over_under_best_bet_odds"
                  class="flex items-start"
                >
                  <span class="mr-2">✅</span>
                  <span>
                    <strong>Over/Under Best Bet Odds</strong>
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="m-0 mb-3">
                This AI model predicts price movements for {{ eventType?.name ?? "all events" }}. It
                forecasts whether the price will go up or down by a specific percentage on the date
                of the prediction.
              </p>
            </div>
            <div
              class="justify-start py-4 text-sm font-bold uppercase leading-3 tracking-tight text-slate-500"
            >
              Model Details (For the Brainiacs) 🤓🤖
            </div>
            <div v-html="renderedDescription"></div>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>

    <div class="inline-flex items-center justify-center gap-2 self-stretch bg-white">
      <div class="relative h-2 w-[5px] overflow-hidden">
        <div
          :class="{ 'border-primary-500': index! == 0, 'border-slate-200': index != 0 }"
          class="absolute left-[-3px] top-0 h-2 w-2 rounded-full border bg-slate-100"
        ></div>
      </div>
      <div class="h-0 flex-1 outline-1 outline-offset-[-0.50px] outline-slate-200">
        <div class="h-[1px] border-b border-dashed border-slate-200"></div>
      </div>
      <div class="relative h-2 w-[5px] overflow-hidden">
        <div
          :class="{ 'border-primary-500': index! == 0, 'border-slate-200': index != 0 }"
          class="absolute left-0 top-0 h-2 w-2 rounded-full border bg-slate-100"
        ></div>
      </div>
    </div>

    <div
      :class="{ 'border-primary-500': index! == 0, 'border-slate-200': index != 0 }"
      class="flex flex-col items-center justify-center gap-2 self-stretch rounded-bl-lg rounded-br-lg border-b border-l border-r bg-white px-4 pb-4 pt-2"
    >
      <div class="inline-flex items-center justify-start gap-4 self-stretch pt-2">
        <SportsPredictionResult
          v-if="
            (authStore.isLoaded && authStore.user && authStore.user.has_access) ||
            prediction.event?.is_free ||
            (authStore.user && prediction.model?.price == 0)
          "
          :prediction="prediction"
        />
        <div
          v-else-if="authStore.isLoaded"
          class="flex flex-1 items-center justify-start gap-2 self-stretch"
        >
          <ModelActionButton :model="prediction.model" />
        </div>
      </div>

      <div class="inline-flex h-8 items-center justify-between self-stretch py-2">
        <div class="flex flex-1 items-center justify-start gap-2">
          <div class="justify-start">
            <span class="text-xs font-medium leading-none text-slate-700">{{
              formatCount(prediction.model.subscribers)
            }}</span
            ><span class="text-xs font-medium leading-none text-slate-500"> Subscribers</span>
          </div>
          <div class="justify-start">
            <span class="text-xs font-medium leading-none text-slate-800">{{
              formatCount(prediction.model.views)
            }}</span
            ><span class="text-xs font-medium leading-none text-slate-500"> Views</span>
          </div>
        </div>
        <div
          @click="showShareModal = true"
          class="flex cursor-pointer items-center justify-center gap-2"
        >
          <i class="pi pi-share-alt w-2.5 text-[12px] font-bold text-slate-700"></i>
          <div class="justify-start text-xs font-medium leading-none text-slate-500">Share</div>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="moreInfoModalVisible"
    class="w-full max-w-[380px] md:max-w-[600px]"
    :modal="true"
    appends-to="self"
    :closable="false"
    :closeOnEscape="false"
    :dismissableMask="true"
  >
    <!-- Dialog Header -->
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div
          class="shrink grow basis-0 font-['Inter'] text-lg font-medium leading-loose text-slate-700"
        >
          🤖 What this Model Predicts
        </div>
        <Button
          @click="moreInfoModalVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>

    <!-- Dialog Body -->
    <div>
      <Accordion value="0" expand-icon="pi pi-chevron-down" collapse-icon="pi pi-chevron-up">
        <!-- MODEL OVERVIEW -->
        <AccordionPanel value="0">
          <AccordionHeader>Model Overview</AccordionHeader>
          <AccordionContent v-if="prediction.event.is_sport">
            <p class="m-0 mb-3">
              This AI model predicts {{ eventType?.name ?? "all" }} events and provides predictions
              for:
              <!-- winner, loser, and over/under. -->
            </p>
            <div class="space-y-2">
              <div class="flex items-start">
                <span class="mr-2">✅</span>
                <span>
                  <strong>Winner/Loser</strong>
                  <!-- – Predicts the chances of each team winning (e.g., Newcastle vs. Leicester City). -->
                </span>
              </div>

              <div class="flex items-start">
                <span class="mr-2">✅</span>
                <span>
                  <strong>Over/Under</strong>
                  <!-- – AI-calculated point spreads for better betting insights. -->
                </span>
              </div>
            </div>
          </AccordionContent>
          <AccordionContent v-else>
            <p class="m-0 mb-3">
              This AI model predicts price movements for {{ eventType?.name ?? "all events" }}. It
              forecasts whether the price will go up or down by a specific percentage on the date of
              the prediction.
            </p>
          </AccordionContent>
        </AccordionPanel>

        <!-- MODEL DETAILS -->
        <AccordionPanel value="1">
          <AccordionHeader>Model Details (For the Brainiacs) 🤓🤖</AccordionHeader>
          <AccordionContent>
            <div v-html="renderedDescription"></div>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
    </div>
  </Dialog>
  <SocialShareModal
    v-model:isVisible="showShareModal"
    :title="shareTitle"
    :description="shareDescription"
    :imageUrl="shareImageUrl"
    :url="shareUrl"
    type="event"
  />
</template>
