<template>
  <div>
    <!-- Your content here -->

    <!-- Scroll to Top Button -->
    <button
      v-show="isVisible"
      @click="scrollToTop"
      class="fixed bottom-[20px] right-[20px] z-20 rounded-md bg-zinc-950 border-2 border-primary hover:text-primary text-white p-[8px] w-[40px] h-[40px]"
    >
      <i class="pi pi-arrow-circle-up"></i>
    </button>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

const isVisible = ref(false);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const handleScroll = () => {
  isVisible.value = window.scrollY > 200;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
