<template>
  <div class="h-full">
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1574_11808)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.9 0.6C4.9 0.268629 4.63137 0 4.3 0C3.96863 0 3.7 0.268629 3.7 0.6V1.6H3.5C2.7268 1.6 2.1 2.2268 2.1 3V3.2H1.1C0.768629 3.2 0.5 3.46863 0.5 3.8C0.5 4.13137 0.768629 4.4 1.1 4.4H2.1V6.4H1.1C0.768629 6.4 0.5 6.66863 0.5 7C0.5 7.33137 0.768629 7.6 1.1 7.6H2.1V9.6H1.1C0.768629 9.6 0.5 9.86863 0.5 10.2C0.5 10.5314 0.768629 10.8 1.1 10.8H2.1V11C2.1 11.7732 2.7268 12.4 3.5 12.4H3.7V13.4C3.7 13.7314 3.96863 14 4.3 14C4.63137 14 4.9 13.7314 4.9 13.4V12.4H6.9V13.4C6.9 13.7314 7.16863 14 7.5 14C7.83137 14 8.1 13.7314 8.1 13.4V12.4H10.1V13.4C10.1 13.7314 10.3686 14 10.7 14C11.0314 14 11.3 13.7314 11.3 13.4V12.4H11.5C12.2732 12.4 12.9 11.7732 12.9 11V10.8H13.9C14.2314 10.8 14.5 10.5314 14.5 10.2C14.5 9.86863 14.2314 9.6 13.9 9.6H12.9V7.6H13.9C14.2314 7.6 14.5 7.33137 14.5 7C14.5 6.66863 14.2314 6.4 13.9 6.4H12.9V4.4H13.9C14.2314 4.4 14.5 4.13137 14.5 3.8C14.5 3.46863 14.2314 3.2 13.9 3.2H12.9V3C12.9 2.2268 12.2732 1.6 11.5 1.6H11.3V0.6C11.3 0.268629 11.0314 0 10.7 0C10.3686 0 10.1 0.268629 10.1 0.6V1.6H8.1V0.6C8.1 0.268629 7.83137 0 7.5 0C7.16863 0 6.9 0.268629 6.9 0.6V1.6H4.9V0.6ZM3.3 3C3.3 2.88954 3.38954 2.8 3.5 2.8H4.3H7.5H10.7H11.5C11.6105 2.8 11.7 2.88954 11.7 3V3.8V7V10.2V11C11.7 11.1105 11.6105 11.2 11.5 11.2H10.7H7.5H4.3H3.5C3.38954 11.2 3.3 11.1105 3.3 11V10.2V3.8V3ZM7.3389 9.20246L7.11883 8.58496H5.49734L5.27726 9.20247C5.16602 9.51461 4.8228 9.67746 4.51066 9.56622C4.19852 9.45497 4.03566 9.11175 4.14691 8.79961L5.50692 4.9836L5.50963 4.97597L5.51255 4.96843C5.63421 4.65416 5.92525 4.39902 6.30809 4.39902C6.69094 4.39902 6.98198 4.65416 7.10363 4.96843L7.10655 4.97598L7.10927 4.9836L8.46926 8.79962C8.5805 9.11176 8.41764 9.45498 8.1055 9.56622C7.79336 9.67746 7.45014 9.5146 7.3389 9.20246ZM6.30809 6.3101L6.69116 7.38496H5.92501L6.30809 6.3101ZM9.3 4.99902C9.3 4.66765 9.56863 4.39902 9.9 4.39902C10.2314 4.39902 10.5 4.66765 10.5 4.99902V9.00107C10.5 9.33245 10.2314 9.60107 9.9 9.60107C9.56863 9.60107 9.3 9.33245 9.3 9.00107V4.99902Z"
          fill="#64748B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1574_11808">
          <path
            d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H10.5C12.7091 0 14.5 1.79086 14.5 4V10C14.5 12.2091 12.7091 14 10.5 14H4.5C2.29086 14 0.5 12.2091 0.5 10V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
