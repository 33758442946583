<template>
  <div class="collect-js-form">
    <div v-if="!isCollectJsLoaded" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div v-if="!isCollectJsLoaded">
        <div class="p-4 bg-gray-100 text-gray-700 rounded-md text-center">
          Please wait while the payment system is Initializing ...
        </div>
      </div>
      <div>
        <div class="mb-5">
          <label for="ccnumber" class="block text-sm font-medium text-gray-700">Card Number *</label>
          <div class="relative mt-1">
            <div id="ccnumber" class="block w-full rounded-md border border-gray-300 px-3 py-2 pr-10 shadow-sm"></div>
            <div class="absolute inset-y-0 right-2 flex items-center space-x-1">
              <img v-for="icon in cardIcons" :key="icon.name" :src="icon.src" :alt="icon.alt" class="h-6 w-6" />
            </div>
          </div>
          <div v-if="ccnumber_error" class="text-sm text-red-600" role="alert">
            {{ ccnumber_error }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <label for="ccexp" class="block text-sm font-medium text-gray-700">Expiration Date *</label>
            <div id="ccexp" class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm"></div>
            <div v-if="ccexp_error" class="text-sm text-red-600" role="alert">
              {{ ccexp_error }}
            </div>
          </div>
          <div>
            <label for="cvv" class="block text-sm font-medium text-gray-700">CVV *</label>
            <div id="cvv" class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm"></div>
            <div v-if="cvv_error" class="text-sm text-red-600" role="alert">
              {{ cvv_error }}
            </div>
          </div>
        </div>
      </div>
      <Button type="submit" :disabled="isProcessing || !isCollectJsLoaded || !isReadyToSubmit"
        class="w-full text-sm font-semibold" :aria-busy="isProcessing">
        {{ isProcessing ? "Processing..." : "Get Predictions" }}
      </Button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { loadScript } from "@/utils/scriptLoader";
import Button from "primevue/button";
import { onMounted, ref } from "vue";

const cardIcons = [
  { name: "visa", src: "/images/credit-cards/visa.svg", alt: "Visa" },
  { name: "mastercard", src: "/images/credit-cards/mastercard.svg", alt: "MasterCard" },
  { name: "amex", src: "/images/credit-cards/amex.svg", alt: "American Express" },
  { name: "unionpay", src: "/images/credit-cards/unionpay.svg", alt: "Discover" },
];

const emit = defineEmits<{
  (e: "token-received", token: string): void;
  (e: "error", error: unknown): void;
}>();

const error = ref("");
const isProcessing = ref(false);
const isCollectJsLoaded = ref(false);
const ccnumber_error = ref(null);
const ccexp_error = ref(null);
const cvv_error = ref(null);
const isReadyToSubmit = ref(false);

interface CollectJS {
  configure: (config: {
    variant: string;
    fields: {
      ccnumber: { title: string; placeholder: string; selector: string };
      ccexp: { title: string; placeholder: string; selector: string };
      cvv: { title: string; placeholder: string; selector: string };
    };
    validationCallback: (field: string, status: boolean, message: string) => void;
    timeoutDuration: number;
    timeoutCallback: () => void;
    fieldsAvailableCallback: () => void;
    callback: (response: { token: string }) => void;
  }) => void;
  startPaymentRequest: () => void;
}

let collectJS: CollectJS | null = null;

const initializeCollectJs = () => {
  if (window.CollectJS) {
    console.log("Initializing CollectJS ...")
    collectJS = window.CollectJS as CollectJS;

    collectJS.configure({
      variant: "inline",
      fields: {
        ccnumber: {
          title: "Card number",
          placeholder: "•••• •••• •••• ••••",
          selector: "#ccnumber",
        },
        ccexp: { title: "Expiry date", placeholder: "MM / YY", selector: "#ccexp" },
        cvv: { title: "CVV", placeholder: "•••", selector: "#cvv" },
      },
      validationCallback: (field, status, message) => {
        console.log(field, status, message);
        if (field === "ccnumber") {
          ccnumber_error.value = status ? "" : message;
        } else if (field === "ccexp") {
          ccexp_error.value = status ? "" : message;
        } else if (field === "cvv") {
          cvv_error.value = status ? "" : message;
        }

        isReadyToSubmit.value = ccnumber_error.value === "" && ccexp_error.value === "" && cvv_error.value === "";
      },
      timeoutDuration: 5000,
      timeoutCallback: () => {
        console.log("The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity");
        error.value = "❌ Payment system didn't respond in the expected timeframe. Please try again.";
        isProcessing.value = false;
      },
      fieldsAvailableCallback: () => {
        isCollectJsLoaded.value = true;
        error.value = "";
      },
      callback: (response: { token?: string; error?: string }) => {
        isProcessing.value = true;

        if (response.token) {
          console.log("✅ Payment token received:", response.token);
          emit("token-received", response.token);
        } else if (response.error) {
          console.error("❌ CollectJS validation error:", response.error);
          error.value = response.error; // Show CollectJS error message
        } else {
          console.error("Unknown state in callback");
        }
      },
    });

    console.log("✅ CollectJS initialized successfully.");
  }
};

onMounted(async () => {
  try {
    const scriptPath = "https://secure.nmi.com/token/Collect.js";
    const existingScript = document.querySelector(`script[src='${scriptPath}']`);
    if (!existingScript) {
      console.log("Loading script ...");
      await loadScript(scriptPath, {
        "data-tokenization-key": import.meta.env.VITE_NMI_TOKENIZATION_KEY,
      });
      console.log("Script loaded successfully.");
    }
    initializeCollectJs();
  } catch (err) {
    console.error("Failed to load CollectJS:", err);
    error.value = "Failed to load payment system. Please try again later.";
  }
});

const handleSubmit = () => {
  error.value = ""; // Clear previous errors
  isProcessing.value = true;

  if (!isCollectJsLoaded.value || !collectJS) {
    error.value = "❌ Payment system is not ready. Please try again later.";
    isProcessing.value = false;
    return;
  }

  try {
    console.log("▶️ Starting CollectJS payment request...");
    collectJS.startPaymentRequest(); // 🔴 CollectJS handles validation
    console.log("▶️ Sent CollectJS payment request.");

  } catch (err) {
    isProcessing.value = false;
    error.value = `❌ Error processing payment: ${(err as Error).message}`;
    emit("error", err);
  }
};
</script>

<style scoped>
.collect-js-form input::placeholder {
  color: #a0aec0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
