import { useAuthStore } from "@/stores/authStore";
import BuilderDetailsView from "@/ui/views/builders/BuilderDetailsView.vue";
import BuilderListView from "@/ui/views/builders/BuilderListView.vue";
import ContactUsView from "@/ui/views/ContactUsView.vue";
import CookiePolicy from "@/ui/views/CookiePolicy.vue";
import MaintenanceView from "@/ui/views/error/MaintenanceView.vue";
import NotFoundView from "@/ui/views/error/NotFoundView.vue";
import EventDetailsView from "@/ui/views/events/EventDetailsView.vue";
import EventListView from "@/ui/views/events/EventListView.vue";
import FreePredictions from "@/ui/views/FreePredictions.vue";
import LandingPageV2View from "@/ui/views/landing-pages-v2/LandingPageV2View.vue";
import LandingPageView from "@/ui/views/landing-pages/LandingPageView.vue";
import MarchMadnessLanding from "@/ui/views/march-madness/MarchMadnessLanding.vue";
import MarchMadnessUpsell from "@/ui/views/march-madness/MarchMadnessUpsell.vue";
import ModelDetailsView from "@/ui/views/models/ModelDetailsView.vue";
import ModelListView from "@/ui/views/models/ModelListView.vue";
import PaymentView from "@/ui/views/payment/PaymentView.vue";
import PrivacyPageView from "@/ui/views/PrivacyPageView.vue";
import CancelSubscription from "@/ui/views/profile/CancelSubscription.vue";
import ProfilePageView from "@/ui/views/profile/ProfilePageView.vue";
import SubmitAbugView from "@/ui/views/SubmitAbugView.vue";
import TermsPageView from "@/ui/views/TermsPageView.vue";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../ui/views/HomeView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/march-madness/landing",
      name: "march-madness-landing",
      component: MarchMadnessLanding,
    },    {
      path: "/march-madness/get-predictions",
      name: "march-madness-upsell",
      component: MarchMadnessUpsell,
    },
    {
      path: "/email/verify/:id/:hash",
      name: "verify-email",
      component: HomeView,
      meta: { action: "verify" },
    },
    {
      path: "/reset-password/:hash",
      name: "reset-password",
      component: HomeView,
      meta: { action: "reset" },
    },
    {
      path: "/landing/:slug?",
      name: "landing",
      component: LandingPageView,
    },

    {
      path: "/landing-v2/:slug?",
      name: "landing-v2",
      component: LandingPageV2View,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPageView,
    },
    {
      path: "/cookie-policy",
      name: "cookie-policy",
      component: CookiePolicy,
    },
    {
      path: "/free-predictions",
      name: "free-predictions",
      component: FreePredictions,
    },
    {
      path: "/profile",
      children: [
        {
          path: "",
          name: "profile",
          component: ProfilePageView,
        },
        {
          path: "cancel-subscription",
          name: "profile-cancel-subscription",
          component: CancelSubscription,
        },
      ],
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      component: TermsPageView,
    },
    {
      path: "/submit-a-bug",
      name: "submit-bug",
      component: SubmitAbugView,
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactUsView,
    },
    {
      path: "/events/:type?/:subType?",
      name: "events",
      component: EventListView,
      meta: { type: "events" },
    },
    {
      path: "/events/:eventId/details",
      name: "single-event",
      component: EventDetailsView,
      meta: { type: "events" },
    },
    {
      path: "/models/:type?/:subType?",
      name: "models",
      component: ModelListView,
      meta: { type: "models" },
    },
    {
      path: "/models/:modelId/details",
      name: "single-model",
      component: ModelDetailsView,
      meta: { type: "models" },
    },
    {
      path: "/creators",
      name: "creators",
      component: BuilderListView,
    },
    {
      path: "/creators/:builderId/details",
      name: "creator.details",
      component: BuilderDetailsView,
      meta: { type: "creators" },
    },
    {
      path: "/payment/:orderId",
      name: "payment",
      component: PaymentView,
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: MaintenanceView,
    },
    {
      path: "/not-found",
      name: "not-found",
      component: NotFoundView,
    },
    // {
    //   path: "/march-madness/",
    //   children: [
    //     {
    //         path: "",
    //         redirect: "/march-madness/landing",
    //     },
    //     {
    //       path: "landing",
    //       name: "march-madness-landing",
    //       component: MarchMadnessLanding,
    //     },
    //     {
    //       path: "get-predictions",
    //       name: "march-madness-upsell",
    //       component: MarchMadnessUpsell,
    //     },
    //   ],
    // },
    {
      path: "/:catchAll(.*)",
      redirect: "/",
    },
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
});

router.beforeEach((to) => {
  const store = useAuthStore();
  const maintenanceOn = (import.meta.env.VITE_MAINTENANCE_MODE || "off") == "on";

  return new Promise((resolve) => {
    const evaluate = () => {

      if (maintenanceOn && to.name.toString().toLocaleLowerCase() != "maintenance") {
        resolve({ name: "maintenance" });
        return;
      }

      store.fetchUser();
      resolve();
    };

    return evaluate();
  });
});

export default router;
