<template>
  <div
    class="hidden min-w-44 flex-col items-start justify-start gap-4 overflow-hidden p-2 pr-0 md:p-4 lg:inline-flex"
  >
    <div class="w-full pb-3 text-base font-semibold leading-7 text-slate-700">
      <span v-if="!eventStore.isLoading && eventStore.currentEvent">{{
        eventType?.parent!.name
      }}</span>
      Categories
    </div>
    <div class="flex flex-col items-start justify-start self-stretch rounded-md">
      <div
        v-if="!eventStore.isLoading"
        class="flex flex-col items-start justify-start gap-0.5 self-stretch"
      >
        <router-link
          v-for="(type, index) in eventTypes"
          :key="index"
          :to="`/events/${eventType?.parent!.slug}/${type.slug}`"
          :class="`inline-flex items-center justify-start gap-[7px] self-stretch rounded px-[10.50px] py-[7px] hover:bg-slate-200`"
        >
          <div class="flex h-4 w-4 items-center justify-center overflow-hidden rounded">
            <div
              class="h-4 w-4 bg-slate-300"
              :style="{
                backgroundImage: `url(${type.logo_url})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }"
            ></div>
          </div>
          <div
            class="font-['Inter 24pt'] shrink grow basis-0 text-sm font-medium leading-tight text-slate-800"
          >
            {{ type.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import { ref, watch } from "vue";

const eventTypesStore = useEventTypesStore();
const eventStore = useEventStore();
const eventTypes = ref([]);
const eventType = ref(null);

watch(
  () => eventStore.currentEvent,
  () => {
    if (eventStore.currentEvent) {
      eventType.value = eventTypesStore.getChildTypeById(eventStore.currentEvent.event_sub_type_id);
    }
    if (eventTypesStore.types.length > 0) {
      eventTypes.value = eventTypesStore.getTypeChildren(eventType.value?.parent?.id);
    }
  },
  { immediate: true },
);
</script>
