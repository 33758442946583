/* eslint-disable @typescript-eslint/no-explicit-any */
import { onMounted } from 'vue';

export function useDataLayer() {
    // Ensure dataLayer exists
    const ensureDataLayer = (): void => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
        }
    };

    const pushToDataLayer = (data: any): void => {
        ensureDataLayer();
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push(data);
        } else {
            console.warn('dataLayer is not available');
        }
    };

    onMounted(() => {
        ensureDataLayer();
    });

    return {
        pushToDataLayer
    };
}
