<template>
  <PlainLayout>
    <div class="p-2 pb-8 md:p-4">
      <div class="mb-[10px] inline-flex w-full items-center justify-between gap-4">
        <div class="flex-1 truncate text-base font-semibold leading-7 text-slate-700">
          Model Builders
        </div>
      </div>

      <div v-if="!builderStore.isLoading" class="grid grid-cols-1 gap-5 md:grid-cols-4">
        <BuilderCard v-for="(builder, index) in builders" :builder="builder" :key="index" />
      </div>

      <div v-if="builderStore.isLoading" class="flex flex-col gap-3">
        <ModelCardSkeleton v-for="(item, index) in Array.from({ length: 8 })" :key="index" />
      </div>

      <div
        v-if="!builderStore.isLoading && builders.length == 0"
        class="items-startflex h-[33px] justify-center overflow-hidden rounded-md border border-yellow-200 shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
      >
        <div
          class="flex h-[31px] shrink grow basis-0 items-center justify-center gap-[7px] bg-yellow-50/95 px-[10.50px] py-[7px] shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
        >
          <div class="relative overflow-hidden">
            <SvgIcon name="info" />
          </div>
          <div>
            <span class="font-['Inter'] text-sm font-medium text-yellow-600"
              >No model builders were found
              <span v-if="type"
                >under
                <strong
                  >{{ type }} <span v-if="subType"> -> {{ subType }}</span></strong
                ></span
              ></span
            >
          </div>
        </div>
      </div>

      <div v-if="hasMore" class="my-4 flex items-center justify-center self-stretch">
        <Button
          v-on:click="fetchItems()"
          :disabled="builderStore.isLoading"
          class="flex items-center justify-start gap-[7px] rounded-md border border-lime-400 bg-lime-400 px-[10.50px] py-[7px] text-sm"
        >
          <i
            v-if="builderStore.isLoading"
            class="pi pi-spin pi-spinner"
            style="font-size: 1rem"
          ></i>
          <span>Show More</span>
        </Button>
      </div>
    </div>

    <!-- <template #rightSidebar>
      <EventUpcoming />
    </template> -->
  </PlainLayout>
</template>

<script setup lang="ts">
import { useBuilderStore } from "@/stores/builderStore";
import type { User } from "@/types/user";
import BuilderCard from "@/ui/components/builders/BuilderCard.vue";
// import EventUpcoming from "@/ui/components/events/EventUpcoming.vue";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
// import GridLayout from "@/ui/layouts/GridLayout.vue";
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import { showGlobalToast } from "@/utils/toast";
import { inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const builderStore = useBuilderStore();
const searchQuery = ref(route.query.q ? (route.query.q as string) : "");
const builders = ref<User[]>([]);
const type = ref("");
const subType = ref("");
const page = ref(1);
const hasMore = ref(false);
const pageSize = 16;

const updateMeta = inject("updateMeta") as (meta) => void;

const fetchItems = async () => {
  try {
    const result = await builderStore.fetchBuilders({
      q: searchQuery.value,
      type: type.value,
      subType: subType.value,
      per_page: pageSize.toString(),
      page: page.value.toString(),
    });
    console.log(result)
    if (page.value === 1) {
      builders.value = result.builders;
    } else {
      builders.value = [...builders.value, ...result.builders];
    }

    hasMore.value = result.more;
    page.value++;
  } catch (error) {
    console.error("Error fetching items:", error);
    showGlobalToast("error", "", "Error fetching builders");
  }
};

updateMeta({
  title: "4CPredictions - Creators",
});

onMounted(() => fetchItems());
</script>
