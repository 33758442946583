<template>
  <GridLayout>
    <template #leftSidebar>
      <ModelLeftSidebar />
    </template>

    <div v-if="!isloading && model" class="p-2 pb-8 md:p-4">
      <div class="rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]">
        <div class="pb-4 text-xl font-bold">
          <Button
            @click="router.push({ name: 'models' })"
            class="!h-[33px] !gap-1 !rounded-[6px] border-[#020617] bg-transparent !px-[10.5px] !py-0"
          >
            <ArrowBackIcon class="h-[14px] w-[14px]" />
            <span class="hidden !text-sm font-medium text-slate-950 md:block">Back</span>
          </Button>
          - Ai Model: {{ model.name }}
        </div>
        <div
          class="mb-4 flex flex-col items-start justify-between gap-2 border-b-2 border-slate-100 pb-4 md:flex-row md:items-center"
        >
          <div class="flex flex-1 gap-2">
            <img
              :src="imageSrc ?? '/images/4C_Logo.png'"
              @error="onImageError"
              class="h-10 w-10 rounded-md"
            />
            <div class="flex flex-col items-start justify-start">
              <div
                class="text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400 md:text-xs"
              >
                THIS AI MODEL PREDICTS:
              </div>
              <div class="inline-flex items-center justify-start gap-2 overflow-hidden">
                <div class="flex items-start justify-start text-sm font-extrabold md:text-base">
                  {{ modelType?.name ?? "All events" }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex w-full items-center gap-3 md:w-auto">
            <Button
              variant="text"
              @click="showShareModal = true"
              class="flex items-center gap-2 text-nowrap hover:bg-transparent"
              icon="pi pi-share-alt"
              label="Share"
            />
            <ModelActionButton :model="model" btnClass="px-7" />
          </div>
        </div>

        <div
          class="grid grid-cols-2 gap-4 border-b-2 border-slate-100 pb-4 md:grid-cols-3 lg:grid-cols-4"
        >
          <div
            :class="ModelHelper.getAccuracyOutlineColor(model.current_accuracy)"
            class="flex flex-col items-center justify-center gap-2 rounded-lg border p-4 md:p-6"
          >
            <i class="pi pi-star-fill text-lg"></i>
            <div class="flex items-center gap-2">
              <span class="mb-2 h-6 text-lg font-bold md:text-2xl"
                >{{ model.current_accuracy }}%</span
              >
              <Button
                @click="infoModalVisible = true"
                variant="text"
                class="h-6 w-6"
                severity="warn"
                rounded
                aria-label="User"
                ><i class="pi pi-info-circle font-bold text-gray-500"></i
              ></Button>
            </div>
            <div class="text-center text-[12px] font-semibold md:text-sm">Current Accuracy</div>
          </div>
          <div
            class="flex flex-col items-center justify-center gap-2 rounded-lg border border-slate-400 p-4 text-slate-700 md:p-6"
          >
            <i class="pi pi-sparkles text-lg"></i>
            <div class="mb-2 inline-flex h-6 items-center justify-start">
              <div
                v-for="(prediction, index) in ModelHelper.getCleanLastPredictions(
                  model.last_ten_prediction,
                )"
                :key="index"
                :class="
                  prediction == true
                    ? 'bg-green-500'
                    : prediction == null
                      ? 'bg-slate-300'
                      : 'bg-rose-500'
                "
                class="h-5 w-3 rounded-full border-2 border-white"
              ></div>
            </div>
            <div class="text-center text-[12px] font-semibold md:text-sm">Last 10 predictions</div>
          </div>
          <div
            class="flex flex-col items-center justify-center gap-2 rounded-lg border border-sky-500 p-4 text-sky-500 md:p-6"
          >
            <i class="pi pi-calendar-clock text-lg"></i>
            <span class="mb-2 h-6 text-lg font-bold md:text-2xl">{{ model.events_predicted }}</span>
            <div class="text-center text-[12px] font-semibold text-slate-700 md:text-sm">
              Total Events Predicted
            </div>
          </div>
          <div
            class="flex flex-col items-center justify-center gap-2 rounded-lg border border-[#9333EA] p-4 text-[#9333EA] md:p-6"
          >
            <i class="pi pi-users text-lg"></i>
            <span class="mb-2 h-6 text-lg font-bold md:text-2xl">{{ model.subscribers }}</span>
            <div class="text-center text-[12px] font-semibold text-slate-700 md:text-sm">
              Subscribers
            </div>
          </div>
        </div>

        <div>
          <div class="flex flex-1 items-center justify-between gap-2 pt-4">
            <div class="flex items-center gap-2">
              <div
                class="h-12 w-12 rounded-[14px]"
                :style="{
                  backgroundImage: `url(${model.creator.avatar ?? '/images/avatar.png'})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                }"
              />
              <div
                class="inline-flex flex-col items-start justify-center font-semibold text-slate-700"
              >
                <div
                  class="text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400"
                >
                  Built by AI Genius
                </div>
                <div class="text-xs font-medium leading-none text-slate-700">
                  {{ model.creator.first_name }} {{ model.creator.last_name }}
                </div>
              </div>
            </div>
            <a
              :href="'https://www.linkedin.com/in/' + model.creator.linkedin + '/'"
              target="_blank"
              class="flex h-[32px] w-[32px] items-center justify-center rounded-[5px] bg-blue-600"
            >
              <SvgIcon name="linkedin" class="h-[16px] w-[16px]" />
            </a>
          </div>
          <div class="mt-3 flex flex-col items-start justify-start">
            <p>{{ model.creator.bio }}</p>
            <div
              v-if="showMoreInfo"
              class="mt-4 text-[14px]"
              v-html="model.metadata.filter((m) => m.key == 'Model_Description')[0].value"
            ></div>
            <Button
              v-if="model.metadata.filter((m) => m.key == 'Model_Description').length > 0"
              @click="showMoreInfo = !showMoreInfo"
              text
              class="mt-2 !px-0 text-sm !font-normal !text-green-500 hover:bg-transparent"
            >
              <span>{{ showMoreInfo ? "Show Less" : "Show More" }}</span>
              <i :class="`pi pi-${showMoreInfo ? 'minus-circle' : 'plus-circle'}`"></i>
            </Button>
          </div>
        </div>
      </div>

      <!-- Previous Predictions -->
      <Tabs :value="activeTab" class="mt-4 bg-transparent">
        <TabList class="!bg-transparent">
          <div class="flex flex-col items-center gap-2 md:flex-row md:gap-4">
            <span class="text-base font-bold text-slate-700">Predictions</span>
            <div class="rounded-md bg-slate-200 p-[4px]">
              <Tab value="past" :disabled="pastPredictions.length == 0">Past</Tab>
              <Tab value="future">Future</Tab>
            </div>
          </div>
        </TabList>
        <TabPanels class="mt-4 bg-transparent p-0">
          <TabPanel value="past">
            <div v-if="pastPredictions.length > 0" class="flex flex-col gap-4">
              <PreviousPredictionCard
                v-for="prediction in pastPredictions"
                :key="prediction.id"
                :prediction="prediction"
                :type="modelType?.parent"
                :model="model"
              />
            </div>
          </TabPanel>
          <TabPanel value="future">
            <div v-if="futurePredictions.length > 0" class="flex flex-col gap-4">
              <PredictionCardSlim
                v-for="prediction in futurePredictions"
                :key="prediction.id"
                :prediction="prediction"
                :type="modelType?.parent"
                :model="model"
              />
            </div>
            <div
              v-else
              class="flex flex-col justify-center gap-4 p-4 md:items-center md:text-center"
            >
              <div><strong>No predictions available right now!</strong> ⏳</div>
              <!-- <br /> -->
              <div>
                Looks like this model isn’t making any new predictions—maybe the season’s over, or
                maybe we’ve hit a glitch in the matrix. 🤯 But don’t worry! There are plenty of
                other AI models making predictions on
                <strong>sports, crypto, politics, and more.</strong>
              </div>
              <!-- <br /> -->
              <div>✨ <strong>Check back soon</strong> — new predictions could drop anytime!</div>
              <div>🔍 <strong>Or explore other models and find your next big insight!</strong></div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>

    <div v-if="isloading" class="p-2 pb-8 md:p-4">
      <div class="rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]">
        <div
          class="mb-4 flex flex-col items-start justify-between gap-2 border-b-2 border-slate-100 pb-4 md:flex-row md:items-center"
        >
          <div class="flex gap-2">
            <Skeleton height="40px" width="40px" class="h-10 w-10 rounded-md" />
            <div class="flex flex-col items-start justify-start gap-2">
              <Skeleton height="10px" width="80px" />
              <div class="inline-flex items-center justify-start gap-2 overflow-hidden pt-2">
                <Skeleton height="8px" width="30px" />
              </div>
            </div>
          </div>
          <div class="flex w-full items-center gap-3 md:w-auto">
            <Skeleton height="10px" width="22px" />
            <Skeleton height="30px" width="80px" class="hidden md:block" />
            <Skeleton height="30px" width="100%" class="block md:hidden" />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
          <Skeleton
            v-for="(i, index) in Array.from({ length: 4 })"
            :key="index"
            height="136px"
            width="100%"
            class="flex flex-col items-center justify-center gap-2 rounded-lg p-4 md:p-6"
          />
        </div>
      </div>
    </div>

    <div v-if="predictionsStore.isLoading" class="p-2 pb-8 md:p-4">
      <!-- Previous Predictions -->
      <div class="flex min-h-[44px] flex-col items-start justify-center">
        <Skeleton height="10px" width="120px" class="my-4" />
      </div>
      <div class="flex flex-col gap-4">
        <PreviousPredictionCardSkeleton
          v-for="(i, index) in Array.from({ length: 2 })"
          :key="index"
        />
      </div>
    </div>

    <template #rightSidebar>
      <EventUpcoming />
    </template>

    <Dialog
      v-model:visible="infoModalVisible"
      :style="{ maxWidth: '380px', width: '100%' }"
      :modal="true"
      :closable="false"
      :closeOnEscape="true"
      :dismissableMask="true"
    >
      <template #header>
        <div class="inline-flex w-full items-start justify-between gap-4">
          <div
            class="shrink grow basis-0 font-['Inter'] text-lg font-bold leading-loose text-slate-700"
          >
            Accuracy
          </div>
          <Button
            @click="infoModalVisible = false"
            class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
          >
            <div class="relative h-3.5 w-3.5 overflow-hidden">
              <SvgIcon name="close" />
            </div>
          </Button>
        </div>
      </template>
      <div class="py-2">
        Accuracy shows how well this model performs in real life. It starts at 0% and updates with
        every prediction—going up when it's right and down when it's wrong.
      </div>
    </Dialog>

    <SocialShareModal
      v-model:isVisible="showShareModal"
      :title="shareTitle"
      :description="shareDescription"
      :imageUrl="shareImageUrl"
      :url="shareUrl"
      type="event"
    />
  </GridLayout>
</template>

<script setup lang="ts">
import { modelsApi } from "@/api/modelsApi";
import { useAuthStore } from "@/stores/authStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import { usePredictionStore } from "@/stores/predictionStore";
import type { Model } from "@/types/model";
import type { Prediction } from "@/types/prediction";
import EventUpcoming from "@/ui/components/events/EventUpcoming.vue";
import ArrowBackIcon from "@/ui/components/icons/ArrowBackIcon.vue";
import ModelActionButton from "@/ui/components/models/ModelActionButton.vue";
import ModelLeftSidebar from "@/ui/components/models/ModelLeftSidebar.vue";
import PredictionCardSlim from "@/ui/components/predictions/PredictionCardSlim.vue";
import PreviousPredictionCard from "@/ui/components/predictions/PreviousPredictionCard.vue";
import PreviousPredictionCardSkeleton from "@/ui/components/predictions/PreviousPredictionCardSkeleton.vue";
import SocialShareModal from "@/ui/components/SocialShareModal.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import GridLayout from "@/ui/layouts/GridLayout.vue";
import { ModelHelper } from "@/utils/modelHelper";
import Tab from "primevue/tab";
import TabList from "primevue/tablist";
import TabPanel from "primevue/tabpanel";
import TabPanels from "primevue/tabpanels";
import Tabs from "primevue/tabs";
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const eventTypesStore = useEventTypesStore();
const predictionsStore = usePredictionStore();
const model = ref<Model>(null);
const isloading = ref(true);
const infoModalVisible = ref(false);
const showShareModal = ref(false);
const showMoreInfo = ref(false);
const activeTab = ref("future");
const modelType = ref(null);
const pastPredictions = ref<Prediction[]>([]);
const futurePredictions = ref<Prediction[]>([]);

const updateMeta = inject("updateMeta") as (meta) => void;

const shareTitle = ref(null);
const shareDescription = ref(null);
const shareImageUrl = ref(null);
const shareUrl = ref(null);

const imageSrc = ref();
const placeholderSrc = "/images/placeholder.svg";

const onImageError = () => {
  imageSrc.value = placeholderSrc;
};

const fetchData = async () => {
  if (route.params.modelId) {
    model.value = await modelsApi.getSingleModel(route.params.modelId as string);
    if (model.value) {
      fetchPredictions();
      modelType.value = eventTypesStore.getChildTypeById(model.value.event_sub_type_id);
      imageSrc.value = modelType.value?.logo_url;
      shareImageUrl.value = modelType.value?.logo_url ?? null;
      shareTitle.value = model.value.name;
      shareDescription.value = model.value.description;
      shareUrl.value = window.location.href;

      updateMeta({
        title: `4CPredictions - ${model.value.name}`,
      });

      activeTab.value = "future";
    }
    isloading.value = false;
  }
};

const fetchPredictions = (review: boolean = false) => {
  if (model.value && model.value.id) {
    predictionsStore.fetchPredictions(model.value.id, review).then((result) => {
      if (result.predictions) {
        pastPredictions.value = result.predictions.past;
        futurePredictions.value = result.predictions.future;
      }
    });
  }
};

watch(
  () => authStore.user,
  (user) => {
    if (user && user.has_access) {
      fetchPredictions(true);
    }
  },
  { immediate: true },
);

onMounted(() => {
  fetchData();
});
</script>
