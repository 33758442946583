import { buildersApi } from "@/api/buildersApi";
import type { User } from "@/types/user";
import StringHelper from "@/utils/stringHelper";
import { defineStore } from "pinia";
import { ref } from "vue";

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const now = Date.now();

export const useBuilderStore = defineStore("builders", () => {
  const builders = ref<User[]>([]);
  const relatedModels = ref<User[]>([]);
  const isLoading = ref(false);
  const hasMore = ref([]);
  const lastFetched = ref({});

  function setIsLoading(loading: boolean) {
    isLoading.value = loading;
  }

  const fetchBuilders = async (filters: Record<string, string>) => {
    const key = StringHelper.keyFromRecord(filters);

    if (builders.value[key] && now - (lastFetched.value[key] || 0) < CACHE_DURATION) {
      return { builders: builders.value[key], more: hasMore.value[key] || false };
    }

    try {
      isLoading.value = true;
      const response = await buildersApi.getBuilders(filters);
      builders.value[key] = response.data.data;
      if (response.data.pagination.current_page < response.data.pagination.last_page) {
        hasMore.value[key] = true;
      } else {
        hasMore.value[key] = false;
      }
      lastFetched.value[key] = now;
      return { builders: builders.value[key], more: hasMore.value[key] };
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    builders,
    relatedModels,
    isLoading,
    hasMore,
    setIsLoading,
    fetchBuilders,
  };
});
