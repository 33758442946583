import axiosInstance from '@/api/axiosInstance';
import type { Event } from '@/types/event';
import type { Prediction } from '@/types/prediction';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;
const perPage = '20';

export const eventsApi = {
    async getParentTypes(filters: Record<string, string>) {
        filters.per_page = perPage;
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/events/get-all-event-types?${queryString}`);
        return response;
    },
    async getSubTypes(filters: Record<string, string>) {
        filters.per_page = perPage;
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/events/get-all-event-sub-types?${queryString}`);
        return response;
    },

    async getEvents(filters: Record<string, string>) {
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/events?${queryString}`);
        return response;
    },

    async getPredictions(eventId: string, filters: Record<string, string>): Promise<Prediction[]> {
        if (!filters.per_page) {
            filters.per_page = perPage;
        }
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/predictions/get-event-predictions/${eventId}?${queryString}`);
        return response.data.data;
    },

    async getSingleEvent(id: string): Promise<Event> {
        const response = await api.get(`/events/${id}`);
        return response.data.data;
    },
};
