<template>
  <div>
    <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7992 2.986C16.0669 3.25379 16.0669 3.68796 15.7992 3.95574L5.74202 14.0129C5.47423 14.2807 5.04006 14.2807 4.77227 14.0129L0.200841 9.44146C-0.066947 9.17367 -0.066947 8.7395 0.200841 8.47171C0.468629 8.20392 0.902799 8.20392 1.17059 8.47171L5.25714 12.5583L14.8294 2.986C15.0972 2.71821 15.5314 2.71821 15.7992 2.986Z"
        fill="#020617"
      />
    </svg>
  </div>
</template>
