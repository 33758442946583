<template>
  <div class="flex w-full flex-col items-start gap-[34px] bg-white md:pb-[55.83px]">
    <router-link to="/" class="flex w-full items-center justify-center px-6 py-5 md:px-80">
      <img class="h-10" src="/images/logo-inverse.svg" />
    </router-link>
    <div
      class="mx-auto inline-flex w-full flex-col items-center justify-center gap-[34px] px-6 md:max-w-[800px]"
    >
      <div class="self-stretch text-4xl font-bold leading-[43.20px] text-black">
        Why Are Former NFL Players Turning to AI for Predictions? The Truth Will Shock You!
      </div>
      <div class="flex-col items-start justify-start gap-5">
        <div class="w-full self-stretch text-lg font-light leading-loose text-black">
          Elite AI models are uncovering patterns no one else sees. Ex-players and analysts are
          ditching their gut instincts for AI-driven insights—and the results are shocking! "The
          moment I saw these AI-driven patterns, I knew the game had changed forever." — Mike
          Thompson, Former NFL Analyst
        </div>
        <div class="inline-flex w-full items-center justify-center overflow-hidden rounded-2xl">
          <div class="w-full border-t border-[#ebebeb]">
            <img class="w-full" src="/images/landing/nfl/1.svg" />
          </div>
        </div>
        <div class="mt-2 flex h-[55px] flex-col items-start justify-start gap-3 self-stretch">
          <div class="inline-flex items-center justify-center gap-2.5 self-stretch">
            <img
              class="relative h-[55px] w-[55px] rounded-[100px]"
              src="/images/landing/nfl/avatars/1.svg"
            />
            <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center">
              <div class="self-stretch text-sm font-semibold leading-[18.72px] text-[#334154]">
                By James Barrett
              </div>
              <div class="self-stretch text-xs font-normal leading-[18.72px] text-[#545454]">
                Sports Technology Correspondent
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Most NFL fans don't know this...
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        While they're listening to talking heads and following "expert" picks, an elite group has
        discovered something extraordinary...
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        A hidden marketplace where the world's most brilliant AI engineers compete to predict games
        with mind-bending accuracy.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        You know those frustrating moments every NFL Sunday...
      </div>
      <div class="self-stretch text-lg font-normal leading-loose text-black">
        "Why didn't I see that upset coming?"<br />"How did everyone miss this player breakout?"<br />"What
        patterns am I not seeing?"
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        It's maddening, watching games unfold in ways the experts never predicted...
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        But what if you could tap into something more powerful than expert opinions?
      </div>
      <div class="self-stretch text-lg font-semibold text-black">
        THE AI ENGINEERING BREAKTHROUGH THAT CHANGES EVERYTHING
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Imagine having access to Dr. Archie Arakal's model—the one that correctly predicted 77% of
        outcomes across 248 games. Not through guesswork or "insider info." Through pure, verifiable
        artificial intelligence.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Or Dr. Sarah Chen's quarterback performance system, which spotted Justin Herbert's breakout
        three weeks before it happened.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        This isn't just another prediction service. This is 4C Predictions - a revolutionary
        platform where the world's top AI engineers compete to prove their models' worth.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        "For years, I analyzed NFL games the traditional way. But when my colleague showed me the
        verified track records of these independent AI models, everything changed.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        It started with just following one engineer's predictions for my favorite team. But when
        their model correctly called five consecutive upset victories - including that shocking
        Bills-Vikings game that left every analyst speechless - I knew this was different.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        The breaking point? Watching Dr. Arakal's model predict not just winners, but exact player
        performance patterns that seemed impossible to forecast.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Then my friend, a PhD in Computer Science, started tracking the models. Now he says it's the
        most transparent AI competition he's ever seen."
      </div>
      <div class="flex w-full flex-col items-center justify-start gap-6 self-stretch">
        <img class="relative w-full rounded-2xl" src="/images/landing/nfl/2.svg" />
      </div>
      <div class="self-stretch text-lg font-semibold text-black">
        MEET THE ENGINEERING MINDS BEHIND THE MAGIC
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black"
          >Dr. Archie Arakal - Former MIT researcher<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >• 77% accuracy on game outcomes<br />• 248 verified predictions<br />• Specialized in
          defensive pattern recognition</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black"
          >Dr. Sarah Chen - Google AI veteran<br /></span
        ><span class="text-lg font-normal leading-loose text-black"
          >• 82% accuracy on QB performance<br />• Pioneer in player evolution modeling<br />•
          Created breakthrough momentum analysis system</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black"
          >Marcus Zhang - Stanford AI Lab<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >• 74% accuracy on game flow predictions<br />• Developed unique team chemistry
          algorithms<br />• Specializes in real-time adjustment patterns</span
        >
      </div>
      <div class="self-stretch text-lg font-normal leading-loose text-black">
        These aren't 4C's models. These are independent systems created by brilliant engineers,
        competing openly on pure performance.
      </div>
      <div class="self-stretch text-lg font-semibold text-black">THE TRANSPARENT REVOLUTION</div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Unlike traditional "black box" prediction services, on 4C Predictions:<br />• Every model's
        complete history is public<br />• All predictions are timestamped and verified<br />•
        Engineers can't hide or delete poor performance<br />• Results are updated in real-time
      </div>
      <div
        class="flex w-full flex-col items-center justify-center gap-2.5 rounded-2xl bg-gradient-to-r from-[#010516] to-[#334154] px-4 py-6 shadow-[0px_6px_10px_0px_rgba(0,0,0,0.10)] md:min-w-[909px]"
      >
        <div class="flex flex-col items-center justify-start gap-6 self-stretch">
          <div class="flex flex-col items-center justify-start gap-3 self-stretch">
            <img
              @click="showVideo"
              src="/images/video_poster.jpg"
              class="w-full cursor-pointer rounded-xl md:max-w-[480px]"
            />
            <div class="inline-flex items-center justify-center gap-1.5 self-stretch">
              <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
              <div class="text-center text-lg font-semibold uppercase leading-loose text-[#bdff1c]">
                instant ACCESS
              </div>
              <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
            </div>
            <div
              class="self-stretch text-center text-[26px] font-bold uppercase leading-[34px] text-[#bae8f1]"
            >
              UNLOCK ELITE AI PREDICTIONS
            </div>
            <div class="text-center text-lg font-normal leading-loose text-white">
              ✓ Access Top Engineers' Models<br />✓ Full Performance History<br />✓ 30-Day
              Money-Back Guarantee
            </div>
          </div>
          <div class="flex flex-col items-center justify-start gap-2">
            <div class="flex flex-col items-start justify-start gap-2.5">
              <router-link
                to="/"
                class="inline-flex items-center justify-start gap-2.5 rounded-lg bg-[#bdff1c] px-10 py-2"
              >
                <div class="text-center text-lg font-semibold leading-loose text-black">
                  Get Instant Access
                </div>
                <i class="pi pi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-start gap-8 overflow-hidden bg-gradient-to-r from-[#bef267] to-[#bae6f8] px-4 pb-12 pt-8 md:w-screen md:px-[72px]"
    >
      <div class="flex h-14 flex-col items-center justify-start gap-6 self-stretch">
        <div class="inline-flex items-center justify-center gap-2.5 self-stretch pt-6">
          <div
            class="shrink grow basis-0 text-center text-4xl font-bold leading-loose tracking-wide text-black"
          >
            Real Fans, Real Transformations:
          </div>
        </div>
      </div>
      <div class="mt-4 flex flex-col items-start justify-start gap-6 self-stretch md:flex-row">
        <div
          class="inline-flex shrink grow basis-0 flex-col items-center justify-start gap-7 self-stretch rounded-2xl bg-white px-8 py-5 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)]"
        >
          <div class="inline-flex items-start justify-start gap-7 self-stretch">
            <img
              class="relative h-[72px] w-[69.81px] rounded-[63px]"
              src="/images/landing/nfl/avatars/2.svg"
            />
            <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
              <div class="flex h-6 flex-col items-start justify-start gap-1 self-stretch">
                <div class="text-lg font-semibold leading-normal text-black">Sarah K.</div>
              </div>
              <div class="flex h-6 flex-col items-start justify-start gap-1 self-stretch">
                <div class="text-lg font-normal leading-normal text-black">Dallas</div>
              </div>
              <div class="inline-flex h-5 w-[88px] items-start justify-center gap-[7px] py-1">
                <svg
                  width="89"
                  height="21"
                  viewBox="0 0 89 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5244 15.7525C3.2349 15.901 2.9064 15.6408 2.9649 15.3085L3.5874 11.761L0.945146 9.24405C0.698396 9.00855 0.826646 8.57805 1.1574 8.53155L4.8309 8.00955L6.4689 4.7643C6.61665 4.4718 7.0164 4.4718 7.16415 4.7643L8.80215 8.00955L12.4756 8.53155C12.8064 8.57805 12.9346 9.00855 12.6871 9.24405L10.0456 11.761L10.6681 15.3085C10.7266 15.6408 10.3981 15.901 10.1086 15.7525L6.8154 14.0605L3.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M22.5244 15.7525C22.2349 15.901 21.9064 15.6408 21.9649 15.3085L22.5874 11.761L19.9451 9.24405C19.6984 9.00855 19.8266 8.57805 20.1574 8.53155L23.8309 8.00955L25.4689 4.7643C25.6166 4.4718 26.0164 4.4718 26.1641 4.7643L27.8021 8.00955L31.4756 8.53155C31.8064 8.57805 31.9346 9.00855 31.6871 9.24405L29.0456 11.761L29.6681 15.3085C29.7266 15.6408 29.3981 15.901 29.1086 15.7525L25.8154 14.0605L22.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M41.5244 15.7525C41.2349 15.901 40.9064 15.6408 40.9649 15.3085L41.5874 11.761L38.9451 9.24405C38.6984 9.00855 38.8266 8.57805 39.1574 8.53155L42.8309 8.00955L44.4689 4.7643C44.6166 4.4718 45.0164 4.4718 45.1641 4.7643L46.8021 8.00955L50.4756 8.53155C50.8064 8.57805 50.9346 9.00855 50.6871 9.24405L48.0456 11.761L48.6681 15.3085C48.7266 15.6408 48.3981 15.901 48.1086 15.7525L44.8154 14.0605L41.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M60.5244 15.7525C60.2349 15.901 59.9064 15.6408 59.9649 15.3085L60.5874 11.761L57.9451 9.24405C57.6984 9.00855 57.8266 8.57805 58.1574 8.53155L61.8309 8.00955L63.4689 4.7643C63.6166 4.4718 64.0164 4.4718 64.1641 4.7643L65.8021 8.00955L69.4756 8.53155C69.8064 8.57805 69.9346 9.00855 69.6871 9.24405L67.0456 11.761L67.6681 15.3085C67.7266 15.6408 67.3981 15.901 67.1086 15.7525L63.8154 14.0605L60.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M79.5244 15.7525C79.2349 15.901 78.9064 15.6408 78.9649 15.3085L79.5874 11.761L76.9451 9.24405C76.6984 9.00855 76.8266 8.57805 77.1574 8.53155L80.8309 8.00955L82.4689 4.7643C82.6166 4.4718 83.0164 4.4718 83.1641 4.7643L84.8021 8.00955L88.4756 8.53155C88.8064 8.57805 88.9346 9.00855 88.6871 9.24405L86.0456 11.761L86.6681 15.3085C86.7266 15.6408 86.3981 15.901 86.1086 15.7525L82.8154 14.0605L79.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            "Amazing to see different engineers' models compete. Following Dr. Arakal's system
            changed my whole season."
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            Following: 3 AI Models
          </div>
        </div>
        <div
          class="inline-flex shrink grow basis-0 flex-col items-center justify-start gap-7 self-stretch rounded-2xl bg-white px-8 py-5 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)]"
        >
          <div class="inline-flex items-start justify-start gap-7 self-stretch">
            <img
              class="relative h-[72px] w-[69.81px] rounded-[63px]"
              src="/images/landing/nfl/avatars/3.svg"
            />
            <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
              <div class="flex h-[52px] flex-col items-start justify-start gap-1 self-stretch">
                <div class="text-lg font-semibold leading-normal text-black">James M.</div>
                <div class="text-lg font-normal leading-normal text-black">Chicago</div>
              </div>
              <div class="inline-flex h-5 w-[88px] items-start justify-center gap-[7px] py-1">
                <svg
                  width="89"
                  height="21"
                  viewBox="0 0 89 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5244 15.7525C3.2349 15.901 2.9064 15.6408 2.9649 15.3085L3.5874 11.761L0.945146 9.24405C0.698396 9.00855 0.826646 8.57805 1.1574 8.53155L4.8309 8.00955L6.4689 4.7643C6.61665 4.4718 7.0164 4.4718 7.16415 4.7643L8.80215 8.00955L12.4756 8.53155C12.8064 8.57805 12.9346 9.00855 12.6871 9.24405L10.0456 11.761L10.6681 15.3085C10.7266 15.6408 10.3981 15.901 10.1086 15.7525L6.8154 14.0605L3.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M22.5244 15.7525C22.2349 15.901 21.9064 15.6408 21.9649 15.3085L22.5874 11.761L19.9451 9.24405C19.6984 9.00855 19.8266 8.57805 20.1574 8.53155L23.8309 8.00955L25.4689 4.7643C25.6166 4.4718 26.0164 4.4718 26.1641 4.7643L27.8021 8.00955L31.4756 8.53155C31.8064 8.57805 31.9346 9.00855 31.6871 9.24405L29.0456 11.761L29.6681 15.3085C29.7266 15.6408 29.3981 15.901 29.1086 15.7525L25.8154 14.0605L22.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M41.5244 15.7525C41.2349 15.901 40.9064 15.6408 40.9649 15.3085L41.5874 11.761L38.9451 9.24405C38.6984 9.00855 38.8266 8.57805 39.1574 8.53155L42.8309 8.00955L44.4689 4.7643C44.6166 4.4718 45.0164 4.4718 45.1641 4.7643L46.8021 8.00955L50.4756 8.53155C50.8064 8.57805 50.9346 9.00855 50.6871 9.24405L48.0456 11.761L48.6681 15.3085C48.7266 15.6408 48.3981 15.901 48.1086 15.7525L44.8154 14.0605L41.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M60.5244 15.7525C60.2349 15.901 59.9064 15.6408 59.9649 15.3085L60.5874 11.761L57.9451 9.24405C57.6984 9.00855 57.8266 8.57805 58.1574 8.53155L61.8309 8.00955L63.4689 4.7643C63.6166 4.4718 64.0164 4.4718 64.1641 4.7643L65.8021 8.00955L69.4756 8.53155C69.8064 8.57805 69.9346 9.00855 69.6871 9.24405L67.0456 11.761L67.6681 15.3085C67.7266 15.6408 67.3981 15.901 67.1086 15.7525L63.8154 14.0605L60.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M79.5244 15.7525C79.2349 15.901 78.9064 15.6408 78.9649 15.3085L79.5874 11.761L76.9451 9.24405C76.6984 9.00855 76.8266 8.57805 77.1574 8.53155L80.8309 8.00955L82.4689 4.7643C82.6166 4.4718 83.0164 4.4718 83.1641 4.7643L84.8021 8.00955L88.4756 8.53155C88.8064 8.57805 88.9346 9.00855 88.6871 9.24405L86.0456 11.761L86.6681 15.3085C86.7266 15.6408 86.3981 15.901 86.1086 15.7525L82.8154 14.0605L79.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            "Each engineer brings unique insights. I combine predictions from my favorite three
            models."
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            Following: 4 AI Models
          </div>
        </div>
        <div
          class="inline-flex shrink grow basis-0 flex-col items-center justify-start gap-7 self-stretch rounded-2xl bg-white px-8 py-5 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)]"
        >
          <div class="inline-flex items-start justify-start gap-7 self-stretch">
            <img
              class="relative h-[72px] w-[69.81px] rounded-[63px]"
              src="/images/landing/nfl/avatars/4.svg"
            />
            <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
              <div class="flex h-[52px] flex-col items-start justify-start gap-1 self-stretch">
                <div class="text-lg font-semibold leading-normal text-black">Rick T.</div>
                <div class="text-lg font-normal leading-normal text-black">London</div>
              </div>
              <div class="inline-flex h-5 w-[88px] items-start justify-center gap-[7px] py-1">
                <svg
                  width="89"
                  height="21"
                  viewBox="0 0 89 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5244 15.7525C3.2349 15.901 2.9064 15.6408 2.9649 15.3085L3.5874 11.761L0.945146 9.24405C0.698396 9.00855 0.826646 8.57805 1.1574 8.53155L4.8309 8.00955L6.4689 4.7643C6.61665 4.4718 7.0164 4.4718 7.16415 4.7643L8.80215 8.00955L12.4756 8.53155C12.8064 8.57805 12.9346 9.00855 12.6871 9.24405L10.0456 11.761L10.6681 15.3085C10.7266 15.6408 10.3981 15.901 10.1086 15.7525L6.8154 14.0605L3.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M22.5244 15.7525C22.2349 15.901 21.9064 15.6408 21.9649 15.3085L22.5874 11.761L19.9451 9.24405C19.6984 9.00855 19.8266 8.57805 20.1574 8.53155L23.8309 8.00955L25.4689 4.7643C25.6166 4.4718 26.0164 4.4718 26.1641 4.7643L27.8021 8.00955L31.4756 8.53155C31.8064 8.57805 31.9346 9.00855 31.6871 9.24405L29.0456 11.761L29.6681 15.3085C29.7266 15.6408 29.3981 15.901 29.1086 15.7525L25.8154 14.0605L22.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M41.5244 15.7525C41.2349 15.901 40.9064 15.6408 40.9649 15.3085L41.5874 11.761L38.9451 9.24405C38.6984 9.00855 38.8266 8.57805 39.1574 8.53155L42.8309 8.00955L44.4689 4.7643C44.6166 4.4718 45.0164 4.4718 45.1641 4.7643L46.8021 8.00955L50.4756 8.53155C50.8064 8.57805 50.9346 9.00855 50.6871 9.24405L48.0456 11.761L48.6681 15.3085C48.7266 15.6408 48.3981 15.901 48.1086 15.7525L44.8154 14.0605L41.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M60.5244 15.7525C60.2349 15.901 59.9064 15.6408 59.9649 15.3085L60.5874 11.761L57.9451 9.24405C57.6984 9.00855 57.8266 8.57805 58.1574 8.53155L61.8309 8.00955L63.4689 4.7643C63.6166 4.4718 64.0164 4.4718 64.1641 4.7643L65.8021 8.00955L69.4756 8.53155C69.8064 8.57805 69.9346 9.00855 69.6871 9.24405L67.0456 11.761L67.6681 15.3085C67.7266 15.6408 67.3981 15.901 67.1086 15.7525L63.8154 14.0605L60.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                  <path
                    d="M79.5244 15.7525C79.2349 15.901 78.9064 15.6408 78.9649 15.3085L79.5874 11.761L76.9451 9.24405C76.6984 9.00855 76.8266 8.57805 77.1574 8.53155L80.8309 8.00955L82.4689 4.7643C82.6166 4.4718 83.0164 4.4718 83.1641 4.7643L84.8021 8.00955L88.4756 8.53155C88.8064 8.57805 88.9346 9.00855 88.6871 9.24405L86.0456 11.761L86.6681 15.3085C86.7266 15.6408 86.3981 15.901 86.1086 15.7525L82.8154 14.0605L79.5244 15.7525Z"
                    fill="#FFCB5A"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            "The engineering talent on this platform is incredible. These aren't black boxes - you
            can see exactly how each model performs."
          </div>
          <div class="self-stretch text-lg font-extralight leading-[34px] text-[#25282a]">
            Following: 2 AI Models
          </div>
        </div>
      </div>
    </div>
    <div
      class="mx-auto inline-flex w-full flex-col items-center justify-center gap-[34px] px-6 md:max-w-[800px]"
    >
      <div class="self-stretch text-lg font-semibold text-black">
        THE SCIENCE BEHIND THE ACCURACY
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        When developing their models, these engineers incorporate:<br />• Advanced pattern
        recognition algorithms<br />• Real-time performance data analysis<br />• Weather impact
        calculations<br />• Team chemistry metrics<br />• Historical trend analysis<br />• Player
        evolution tracking
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Each model is unique, reflecting its creator's innovative approach to NFL prediction.
      </div>
      <div class="self-stretch text-lg font-semibold text-black">HOW IT WORKS</div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black"
          >1. Browse the Marketplace<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >Review each engineer's complete performance history and prediction specialties</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black">2. Choose Your Models<br /></span
        ><span class="text-lg font-normal leading-loose text-black"
          >Follow the engineers whose systems align with your interests</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black"
          >3. Get Real-Time Predictions<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >Receive alerts and insights directly from your chosen models</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-medium leading-loose text-black">4. Track Performance<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >Watch as your selected models compete against others in real-time</span
        >
      </div>
      <div class="inline-flex w-full items-start justify-center md:w-[1440px] md:px-[164px]">
        <div
          class="flex w-full items-center justify-start self-stretch rounded-2xl shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)] md:w-[909px]"
        >
          <div
            :style="{
              backgroundImage: `url(/images/landing/nfl/container.svg)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }"
            class="hidden h-full flex-1 grow basis-0 self-stretch rounded-l-2xl bg-[#090A1F] md:flex"
          ></div>
          <div
            class="inline-flex w-full grow basis-0 flex-col items-center justify-center gap-8 rounded-l-2xl rounded-r-2xl bg-gradient-to-r from-[#090A1F] to-[#334154] p-12 md:rounded-l-none"
          >
            <div class="flex w-full flex-col items-center justify-center gap-6 self-stretch">
              <div class="flex flex-col items-center justify-start gap-2.5 self-stretch">
                <div
                  class="inline-block self-stretch bg-gradient-to-r from-[#BAE8F1] to-[#BEF174] bg-clip-text text-center text-[20px] font-bold uppercase leading-[34px] text-[#bae8f1] text-transparent md:text-[26px]"
                >
                  JOIN THE AI PREDICTION REVOLUTION
                </div>
                <div class="text-center text-base font-normal leading-loose text-white">
                  Limited Time: 50% Off Your First Month
                </div>
              </div>
              <div class="flex flex-col items-center justify-start gap-2">
                <div class="flex flex-col items-start justify-start gap-2.5">
                  <router-link
                    to="/"
                    class="inline-flex items-center justify-start gap-2.5 rounded-lg bg-[#bdff1c] px-10 py-2"
                  >
                    <div class="text-center text-lg font-semibold leading-loose text-black">
                      Start Now
                    </div>
                    <i class="pi pi-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch text-lg font-semibold text-black">
        WARNING: SPOTS ARE FILLING FAST
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        As word spreads about these engineers' track records, access to 4C Predictions is becoming
        increasingly limited.
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Top engineers are seeing unprecedented demand for their models, with some already
        implementing waiting lists.
      </div>
      <div
        class="inline-flex w-full items-center justify-center gap-2.5 border border-[#1eb4e1] bg-slate-100 p-6"
      >
        <div class="w-full text-lg font-semibold leading-loose text-black md:max-w-[580px]">
          UPDATE: After recent media coverage of Dr. Arakal's 77% accuracy rate, new user
          registrations have surged. Only 247 spots remain for this NFL season.
        </div>
      </div>
      <div class="self-stretch text-lg font-semibold text-black">
        THE "UNFAIR" ADVANTAGE THAT'S PERFECTLY LEGAL
      </div>
      <div class="self-stretch">
        <span class="text-lg font-normal leading-loose text-black">While others rely on:<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >• Outdated statistics<br />• Media narratives<br />• "Expert" opinions<br />• Gut
          feelings</span
        >
      </div>
      <div class="self-stretch">
        <span class="text-lg font-normal leading-loose text-black"
          >You could be accessing:<br /></span
        ><span class="text-lg font-light leading-loose text-black"
          >• Verified AI predictions<br />• Engineer-level insights<br />• Pattern recognition
          impossible for humans<br />• Real-time performance updates</span
        >
      </div>
      <div
        class="flex w-full flex-col items-center justify-center gap-2.5 rounded-2xl bg-gradient-to-r from-[#010516] to-[#334154] px-4 py-6 shadow-[0px_6px_10px_0px_rgba(0,0,0,0.10)] md:min-w-[909px]"
      >
        <div class="flex flex-col items-center justify-start gap-6 self-stretch">
          <div class="flex flex-col items-center justify-start gap-3 self-stretch">
            <img
              @click="showVideo"
              src="/images/video_poster.jpg"
              class="w-full cursor-pointer rounded-xl md:max-w-[480px]"
            />
            <div class="inline-flex items-center justify-center gap-1.5 self-stretch">
              <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
              <div class="text-center text-lg font-semibold uppercase leading-loose text-[#bdff1c]">
                instant ACCESS
              </div>
              <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
            </div>
            <div
              class="inline-block self-stretch bg-gradient-to-r from-[#BAE8F1] to-[#BEF174] bg-clip-text text-center text-[26px] font-bold uppercase leading-[34px] text-[#bae8f1] text-transparent"
            >
              CLAIM YOUR ACCESS NOW
            </div>
            <div class="text-center text-lg font-normal leading-loose text-white">
              ✓ Browse All Engineer Models<br />✓ View Complete Track Records<br />✓ 30-Day
              Money-Back Guarantee<br />✓ Real-Time Predictions
            </div>
          </div>
          <div class="flex flex-col items-center justify-start gap-2">
            <div class="flex flex-col items-start justify-start gap-2.5">
              <router-link
                to="/"
                class="inline-flex items-center justify-start gap-2.5 rounded-lg bg-[#bdff1c] px-10 py-2"
              >
                <div class="text-center text-lg font-semibold leading-loose text-black">
                  Get Started Today
                </div>
                <i class="pi pi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="self-stretch text-lg font-light leading-loose text-black">
        Because in the NFL, everyone has opinions...<br />But only a select few have access to
        proven AI engineering brilliance.
      </div>
      <div class="self-stretch text-lg font-normal leading-loose text-black">
        P.S. Remember: While others guess, you could know. But only if you act now.
      </div>
      <div class="mb-10 inline-flex items-center justify-center gap-2.5 self-stretch pt-6">
        <div
          class="shrink grow basis-0 text-base font-light leading-[14px] text-[#696969] md:text-xs"
        >
          © {{ new Date().getFullYear() }} 4C Predictions |
          <router-link to="/terms-of-use">Terms</router-link> |
          <router-link to="/privacy-policy">Privacy</router-link>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    :visible="videoVisible"
    class="group relative overflow-hidden border-0 !p-0"
    pt:root:class="!border-0 !bg-zinc-950"
    pt:mask:class="bg-black/20"
  >
    <template #container>
      <iframe
        width="560"
        height="315"
        :src="embedUrl()"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
      <Button
        @click="videoVisible = false"
        class="absolute right-1 top-1 z-20 hidden h-10 w-10 border-none bg-black/40 group-hover:block"
        ><i class="pi pi-times text-white"></i
      ></Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";

const videoRef = ref(null);
const videoVisible = ref(false);
const embedUrl = () => {
  const baseUrl = "https://www.youtube.com/embed/2I5ft8Z61pw";
  const params = new URLSearchParams({
    autoplay: "1",
    rel: "0",
    modestbranding: "0",
    controls: "1",
    showinfo: "0",
    fs: "1",
  });

  params.append("playlist", "2I5ft8Z61pw");
  params.append("loop", "1");
  params.append("iv_load_policy", "3");

  return `${baseUrl}?${params.toString()}`;
};

const showVideo = () => {
  videoVisible.value = true;
  if (videoRef.value) {
    videoRef.value.play();
  }
};
</script>
