<template>
  <div class="hidden w-full flex-col items-start justify-start gap-4 p-4 pl-0 pr-4 lg:flex">
    <div class="inline-flex items-center justify-between gap-2 self-stretch">
      <div class="flex-1 truncate text-base font-semibold leading-7 text-slate-700">
        More {{ eventType?.name }} Predictions
      </div>
      <div class="flex items-center justify-start gap-2 overflow-hidden">
        <router-link
          :to="`/events/${eventType?.parent?.slug}/${eventType?.slug}`"
          class="cursor-pointer text-nowrap text-sm font-medium leading-tight text-slate-500 hover:text-slate-800 hover:underline"
        >
          View All
        </router-link>
      </div>
    </div>
    <div
      class="flex basis-0 flex-col items-start gap-3 self-stretch"
      v-if="eventStore.currentEvent && events"
    >
      <EventCard
        v-for="(event, index) in events.filter((ev) => ev.id != eventStore.currentEvent?.id)"
        :key="index"
        :event="event"
      />
    </div>
    <div
      v-if="eventStore.relatedEventsLoading"
      class="flex basis-0 flex-col items-start gap-3 self-stretch"
    >
      <EventCardSkeleton v-for="(item, index) in Array.from({ length: 3 })" :key="index" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Event } from "@/types/event";
import EventCardSkeleton from "@/ui/components/events/EventCardSkeleton.vue";
import { ref, watch } from "vue";
import EventCard from "./EventCard.vue";

const eventStore = useEventStore();
const eventTypesStore = useEventTypesStore();
const eventType = ref(null);
const events = ref<Event[]>([]);

const init = async () => {
  events.value = await eventStore.fetchRelatedEvents();
};

watch(
  () => eventStore.currentEvent,
  () => {
    if (eventStore.currentEvent) {
      init();
      eventType.value = eventTypesStore.getChildTypeById(
        eventStore.currentEvent?.event_sub_type_id,
      );
    }
  },
  { immediate: true },
);
</script>
