<template>
  <div
    class="flex-col items-start justify-start self-stretch rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)] md:flex"
  >
    <div class="flex w-full flex-col justify-between gap-2 pb-2 md:flex-row md:items-center">
      <div>
        <router-link
          :to="{ name: 'single-event', params: { eventId: prediction.event.id } }"
          class="font-semibold"
        >
          {{ prediction.event.name }}
        </router-link>
        <div class="flex flex-row">
          <div class="pr-3 text-[9px] font-medium uppercase leading-3 text-slate-400 md:text-xs">
            {{ formatShortDateTime(prediction.event.start_time).formattedDate }}
          </div>

          <div class="text-[9px] font-medium uppercase leading-3 text-slate-400 md:text-xs">
            {{ formatShortDateTime(prediction.event.start_time).formattedTime }}
          </div>
        </div>
      </div>
      <div class="w-full md:w-auto">
        <ModelActionButton v-if="!(authStore.user && authStore.user.has_access)" :model="model" />
      </div>
    </div>

    <SportsPredictionResult v-if="prediction.event.is_sport" :prediction="prediction" />

    <div class="flex w-full items-center justify-between gap-2 pt-3">
      <div class="flex items-center justify-start gap-2 overflow-hidden px-0.5">
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.subscribers)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >subscribers</span
          >
        </div>
        <div class="flex items-center justify-start gap-1">
          <span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-700">{{
            formatCount(model.views)
          }}</span
          ><span class="font-['Inter 24pt'] text-xs font-medium leading-none text-slate-500"
            >Views</span
          >
        </div>
      </div>
      <div class="flex items-center gap-4">
        <div class="text-xs font-medium leading-none text-slate-500">
          Last Updated: {{ prediction.updated_at }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import type { EventType } from "@/stores/eventTypesStore";
import type { Model } from "@/types/model";
import type { Prediction } from "@/types/prediction";
import { formatShortDateTime } from "@/utils/dateFormatter";
import { formatCount } from "@/utils/numberFormater.ts";
import ModelActionButton from "../models/ModelActionButton.vue";
import SportsPredictionResult from "./SportsPredictionResult.vue";

defineProps<{
  prediction: Prediction;
  type?: EventType;
  model: Model;
}>();

const authStore = useAuthStore();
</script>
