<template>
  <div
    class="inline-flex min-h-[228px] w-full flex-col items-start justify-start gap-4 self-stretch overflow-hidden rounded-xl bg-white p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]"
  >
    <router-link
      :to="{ name: 'single-event', params: { eventId: event.id } }"
      class="flex items-center gap-2 md:min-h-[47px]"
    >
      <img :src="imageSrc" @error="onImageError" class="h-10 w-10 rounded-md" />
      <div class="flex-1 text-base font-bold leading-normal text-slate-700">
        {{ event.name }}
      </div>
      <!-- <img :src="imageSrc2" @error="onImageError" class="h-10 w-10 rounded-md" /> -->
    </router-link>

    <div class="flex items-center gap-4 text-[14px]">
      <div class="flex items-center gap-1">
        <i class="pi pi-calendar text-slate-700"></i>
        <span class="text-slate-600">{{ formatDateTime(event.start_time!).formattedDate }}</span>
      </div>
      <div class="flex items-center gap-1">
        <i class="pi pi-clock text-slate-700"></i>
        <span class="text-slate-600">{{ formatDateTime(event.start_time!).formattedTime }}</span>
      </div>
    </div>

    <!-- <div class="inline-flex items-start justify-start gap-[7px] self-stretch">
      <div
        class="truncate-multiline h-[35px] shrink grow basis-0 text-sm font-normal leading-tight text-slate-700"
      >
        {{ event.description }}
      </div>
    </div> -->

    <Button
      v-on:click="router.push({ name: 'single-event', params: { eventId: event.id } })"
      label="View Predictions"
      class="h-9 w-full text-sm font-medium"
    ></Button>

    <div class="inline-flex items-center justify-between self-stretch">
      <div class="flex items-center justify-start gap-0.5 overflow-hidden px-0.5">
        <div>
          <span class="text-xs font-medium leading-none text-slate-700"
            >{{ formatCount(event.views) }} </span
          ><span class="text-xs font-medium leading-none text-slate-500"> views</span>
        </div>
      </div>
      <div class="flex items-center justify-end gap-2">
        <Button
          variant="text"
          @click="showShareModal = true"
          class="flex items-center justify-start gap-1 overflow-hidden !py-0 px-0 hover:bg-transparent"
        >
          <div class="relative top-[-0.5px] h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="share" />
          </div>
          <div class="text-xs font-medium leading-none text-slate-500">Share</div>
        </Button>
      </div>
    </div>
  </div>

  <SocialShareModal
    v-model:isVisible="showShareModal"
    :title="shareTitle"
    :description="shareDescription"
    :imageUrl="shareImageUrl"
    :url="shareUrl"
    type="event"
  />
</template>

<script lang="ts" setup>
import type { Event } from "@/types/event";
import SocialShareModal from "@/ui/components/SocialShareModal.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import { formatDateTime } from "@/utils/dateFormatter";
import { formatCount } from "@/utils/numberFormater.ts";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const shareTitle = ref(null);
const shareDescription = ref(null);
const shareImageUrl = ref(null);
const shareUrl = ref(null);
const showShareModal = ref(false);

const props = defineProps<{
  event: Event;
}>();

const imageSrc = ref(null);
const imageSrc2 = ref(null);
const placeholderSrc = "/images/placeholder.svg";

const onImageError = () => {
  imageSrc.value = placeholderSrc;
};

watch(
  () => props.event,
  () => {
    imageSrc.value = props.event.event_image;
    imageSrc2.value = props.event.event_image_2;

    shareImageUrl.value = props.event.event_image ?? null;
    shareTitle.value = props.event.name;
    shareDescription.value = props.event.description;
    shareUrl.value =
      window.location.origin +
      router.resolve({
        name: "single-event",
        params: { eventId: props.event.id },
      }).fullPath;
  },
  { immediate: true },
);
</script>
