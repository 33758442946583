import axiosInstance from '@/api/axiosInstance';
import type { Model } from '@/types/model';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;

export const modelsApi = {
    async getModels(filters: Record<string, string | null>) {
      console.log(filters);
        const queryString = new URLSearchParams(filters).toString();
        const response = await api.get(`/models?${queryString}`);
        return response;
    },

    async getSingleModel(id: string): Promise<Model> {
        const response = await api.get(`/models/${id}`);
        return response.data.data;
    },

    async subscribeModel(modelId: string, userId): Promise<Model> {
        const response = await api.post("/models/subscribe", { model_id: modelId, user_id: userId });
        return response.data.data;
    },

    async unSubscribeModel(modelId: string, userId): Promise<Model> {
        const response = await api.post("/models/unsubscribe", { model_id: modelId, user_id: userId });
        return response.data.data;
    },

    async getUserSubscriptions() {
      const response = await api.get("/user-subscriptions");
      return response.data.data;
    }
};
