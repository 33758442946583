<template>
  <div class="flex flex-col items-start justify-start self-stretch overflow-hidden">
    <!-- Logo Row -->
    <div class="flex text-xl md:text-2xl font-bold leading-tight">
      <!-- Reduce letter spacing on 4C -->
      <div class="text-primary tracking-[-4px]">4C</div>
      <div class="text-white ml-2">Predictions</div>
    </div>

    <!-- Tagline Row -->
    <div class="text-[9px] md:text-[12px] font-bold leading-tight text-white">
      <span>The Worlds </span>
      <span class="text-primary">#1 Prediction Marketplace</span>
    </div>
  </div>
</template>
