<template>
  <Dialog
    v-model:visible="isVisible"
    :modal="true"
    header=" "
    :style="{ width: '23vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '90vw' }"
  >
    <div class="text-slate-700">
      <h1 class="text-lg font-semibold">Share this {{ type }}</h1>
      <p class="text-sm">If you like this {{ type }} share it with your friends.</p>
      <div class="mt-5 flex justify-between space-x-2">
        <div
          v-for="platform in sharePlatforms"
          :key="platform.name"
          class="flex flex-1 flex-col items-center"
        >
          <Button
            :icon="platform.icon"
            :class="platform.class"
            class="h-8 w-full rounded-md text-lg"
            @click="share(platform.name)"
          />
        </div>
      </div>
      <div
        class="my-5 flex h-10 items-center justify-center gap-2 rounded border-2 border-slate-300 px-3 pl-4"
      >
        <div class="flex-1 overflow-hidden text-nowrap text-sm font-normal text-[#8d9ca4]">
          {{ url }}
        </div>
        <div
          class="ml-1 h-4 w-4 cursor-pointer stroke-slate-700 hover:stroke-sky-500"
          @click="copyToClipboard(url)"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3996 6.60001H8.19961C7.31595 6.60001 6.59961 7.31635 6.59961 8.20001V15.4C6.59961 16.2837 7.31595 17 8.19961 17H15.3996C16.2833 17 16.9996 16.2837 16.9996 15.4V8.20001C16.9996 7.31635 16.2833 6.60001 15.3996 6.60001Z"
              stroke="current-color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.4 11.4H2.6C2.17565 11.4 1.76869 11.2314 1.46863 10.9314C1.16857 10.6313 1 10.2243 1 9.8V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1H9.8C10.2243 1 10.6313 1.16857 10.9314 1.46863C11.2314 1.76869 11.4 2.17565 11.4 2.6V3.4"
              stroke="current-color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { computed } from "vue";

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  url: {
    type: String,
    required: false,
  },
  type: String,
});

const emit = defineEmits(["update:isVisible"]);

const toast = useToast();

const isVisible = computed({
  get: () => props.isVisible,
  set: (value) => emit("update:isVisible", value),
});

const sharePlatforms = [
  {
    name: "Facebook",
    icon: "pi pi-facebook",
    class: "p-button-info bg-[#316FF6] border-transparent !text-white",
  },
  {
    name: "Twitter",
    icon: "pi pi-twitter",
    class: "p-button-info bg-[#1DA1F2] !text-white border-[#1DA1F2]",
  },
  {
    name: "Linkedin",
    icon: "pi pi-linkedin",
    class: "p-button-inf0 bg-[#0072b1] border-[#0072b1] !text-white",
  },
  {
    name: "WhatsApp",
    icon: "pi pi-whatsapp",
    class: "p-button-success bg-[#25D366] border-[#25D366] !text-white",
  },
];

async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
    toast.add({
      severity: "success",
      detail: "Copied to clipboard",
      life: 3000,
    });
  } catch (error) {
    console.error("Failed to copy: ", error);
  }
}

const share = async (platform) => {
  const encodedUrl = encodeURIComponent(props.url) + "?utm_source=Sharebtn";
  const encodedTitle = encodeURIComponent("4C Predictions - " + props.title);
  const encodedDescription = encodeURIComponent(props.description);
  const encodedImageUrl = encodeURIComponent(props.imageUrl);

  const shareUrls = {
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&via=4cpredictions&image=${encodedImageUrl}`,
    Linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`,
    Pinterest: `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=${encodedImageUrl}&description=${encodedDescription}`,
    WhatsApp: `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`,
  };

  if (shareUrls[platform]) {
    window.open(shareUrls[platform], "_blank");
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Sharing platform not supported",
      life: 3000,
    });
  }
};
</script>
