<template>
  <div>
    <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_352_6565)">
        <path
          d="M13.3409 1.96631L13.3401 1.9647C12.5903 1.21245 11.5939 0.797913 10.5336 0.797913H10.5328C9.47241 0.797913 8.47528 1.21245 7.72463 1.9647L7.50055 2.18958L7.27647 1.9647C6.52662 1.21245 5.52949 0.797913 4.46833 0.797913C3.40718 0.797913 2.41004 1.21245 1.66019 1.9647C0.11327 3.51642 0.11327 6.04207 1.66019 7.59299L7.07481 13.0252C7.18764 13.1389 7.3405 13.2021 7.49975 13.2021C7.659 13.2021 7.81265 13.1389 7.92469 13.0252L13.3393 7.59299C14.8862 6.04127 14.887 3.51723 13.3409 1.96631Z"
          fill="#0EA5E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_352_6565">
          <rect x="0.5" width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
