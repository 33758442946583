<template>
  <div>
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2463_14821)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.1 1.1C8.1 0.768629 7.83137 0.5 7.5 0.5C7.16863 0.5 6.9 0.768629 6.9 1.1V8.45147L4.72426 6.27574C4.48995 6.04142 4.11005 6.04142 3.87574 6.27574C3.64142 6.51005 3.64142 6.88995 3.87574 7.12426L7.07574 10.3243C7.13326 10.3818 7.19956 10.4252 7.27033 10.4545C7.34107 10.4838 7.41865 10.5 7.5 10.5C7.58135 10.5 7.65893 10.4838 7.72967 10.4545C7.80044 10.4252 7.86674 10.3818 7.92426 10.3243L11.1243 7.12426C11.3586 6.88995 11.3586 6.51005 11.1243 6.27574C10.8899 6.04142 10.5101 6.04142 10.2757 6.27574L8.1 8.45147V1.1ZM1.1 9.3C1.43137 9.3 1.7 9.56863 1.7 9.9V12.3C1.7 12.9165 2.1354 13.3 2.524 13.3H12.476C12.8646 13.3 13.3 12.9165 13.3 12.3V9.9C13.3 9.56863 13.5686 9.3 13.9 9.3C14.2314 9.3 14.5 9.56863 14.5 9.9V12.3C14.5 13.4435 13.6554 14.5 12.476 14.5H2.524C1.3446 14.5 0.5 13.4435 0.5 12.3V9.9C0.5 9.56863 0.768629 9.3 1.1 9.3Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2463_14821">
          <rect x="0.5" y="0.5" width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
