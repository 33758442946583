<template>
  <Dialog
    :visible="visible"
    :style="{
      maxWidth: getWidth(),
      width: 'calc(100% - 2rem)',   // ensures some horizontal spacing on small screens
      margin: '0 auto'             // centers the dialog horizontally
    }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div class="leading-2 shrink grow basis-0 text-lg font-medium text-slate-700">
          {{ getTitle() }}
        </div>
        <Button
          @click="close"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <LoginForm v-if="variant == 'login'" />
    <RegisterForm v-if="variant == 'join'" />
    <ForgotPasswordForm v-if="variant == 'forgot'" />
    <ResetPasswordForm v-if="variant == 'reset'" />
    <MigratedModal
      v-if="variant == 'migrated_subscriber' || variant == 'migrated_non_subscriber'"
    />
    <div v-if="variant == 'join'" class="self-stretch text-center">
      <span class="text-sm font-normal leading-tight text-slate-700"
        >I already have an account!
      </span>
      <span
        @click="authStore.setActiveModal('login')"
        class="cursor-pointer text-sm font-normal leading-tight text-purple-600"
        >Sign In</span
      >
    </div>
    <div v-if="variant == 'login'" class="self-stretch text-center">
      <span class="text-sm font-normal leading-tight text-slate-700"
        >I don't have an account!
      </span>
      <span
        @click="authStore.setActiveModal('join')"
        class="cursor-pointer text-sm font-normal leading-tight text-purple-600"
        >Join</span
      >
    </div>
    <div
      v-if="!['join', 'login', 'migrated_subscriber', 'migrated_non_subscriber'].includes(variant)"
      class="mt-2 self-stretch text-center"
    >
      <span class="text-sm font-normal leading-tight text-slate-700">Remember password? </span>
      <span
        @click="authStore.setActiveModal('login')"
        class="cursor-pointer text-sm font-normal leading-tight text-purple-600"
        >Sign In</span
      >
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import Dialog from "primevue/dialog";
import { ref, watch } from "vue";
import SvgIcon from "../SvgIcon.vue";
import ForgotPasswordForm from "./ForgotPasswordForm.vue";
import LoginForm from "./LoginForm.vue";
import MigratedModal from "./MigratedModal.vue";
import RegisterForm from "./RegisterForm.vue";
import ResetPasswordForm from "./ResetPasswordForm.vue";

const authStore = useAuthStore();

const visible = ref(authStore.authModalVisible);
const variant = ref(authStore.activeAuthModal);

watch(
  () => authStore.authModalVisible,
  () => {
    visible.value = authStore.authModalVisible;
  },
  { immediate: true },
);

watch(
  () => authStore.activeAuthModal,
  () => {
    variant.value = authStore.activeAuthModal;
  },
  { immediate: true },
);

const close = () => {
  emit("update:visible", false);
  emit("close");
};

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "close"): void;
}>();

const getTitle = () => {
  switch (variant.value && variant.value.toLowerCase()) {
    case "login":
      return "Log In";
    case "forgot":
      return "Forgot Password";
    case "reset":
      return "Reset your Password";
    case "migrated_subscriber":
      return "🎉 Your 4C Predictions Subscription Just Got a Huge Upgrade!";
    case "migrated_non_subscriber":
      return "🎁 We've Unlocked Our Most Accurate AI Models for You!";
    default:
      return "Create an Account to Unlock AI-Powered Predictions!";
  }
};

const getWidth = () => {
  switch (variant.value && variant.value.toLowerCase()) {
    case "join":
      return "580px";
    case "migrated_subscriber":
    case "migrated_non_subscriber":
      return "720px";
    default:
      return "350px";
  }
};
</script>
