/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '@/api/axiosInstance';
import type { PaymentResponse, PromoCodeResponse } from '@/types/payments';
import type { User } from '@/types/user';
import axios, { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;
        const chashierBase = import.meta.env.VITE_CASHIER_API_BASE_URL || "";

export const paymentApi = {
    async requestLink(user: User, url: string) {
        const response = await axios.post(`${chashierBase}/subscribe`, {
            user_id: user.id,
            email: user.email,
            name: `${user.first_name} ${user.last_name}`,
            success_url_redirect: `${url}?payment=success&user=${user.id}`,
            failed_url_redirect: `${url}?payment=failed&user=${user.id}`,
        });

        return response.data;
    },

    async checkPayment(user: User): Promise<User | null> {
        const response = await axios.post(`${chashierBase}/subscribe/check-status`, {user_id: user.id});
        if (response.data.has_active_subscription) {
            const response = await api.post('/payment/subscribe');
            return response.data.data;
        }

        return null;
    },

    async submitPayment(data: Record<string, any>): Promise<PaymentResponse> {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.post('/payment', data);
        return response.data;
    },

    async checkIfCustomerExistsInVault(): Promise<PaymentResponse> {
      await api.get('/sanctum/csrf-cookie');
      const response = await api.get('/customer-exists-in-vault');
      console.log(response);
      return response.data;
    },

    async validatePromoCode(promoCode: string, modelId: string): Promise<PromoCodeResponse> {
      await api.get('/sanctum/csrf-cookie');
      const response = await api.get('/validate-promo-code', {
        params: {
          promo_code: promoCode,
          model_id: modelId
        }
      });
      console.log(response);
      return response.data;
    }
};
