import { eventBus } from '@/utils/eventBus';

export function showGlobalToast(severity, summary, detail, life = 10000) {
    if (eventBus.addToast) {
        eventBus.addToast({
            severity,
            summary,
            detail,
            life,
        });
    } else {
        console.error('ToastService is not available!');
    }
}
