<template>
  <div class="flex min-h-screen flex-col justify-between bg-slate-100">
    <div class="flex flex-1 flex-col">
      <Header />
      <div class="h-full flex-1">
        <slot></slot>
      </div>
    </div>
    <MainFooter />
  </div>
  <Dialog
    :visible="videoVisible"
    class="group relative h-[219px] w-full overflow-hidden border-0 !p-0 md:h-[484px] md:w-[860px]"
    pt:root:class="!border-0 !bg-zinc-950"
    pt:mask:class="bg-black/30"
  >
    <template #container>
      <iframe
        width="100%"
        height="100%"
        :src="embedUrl()"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
      <Button
        @click="videoVisible = false"
        class="absolute bottom-1 left-1 z-20 h-10 border-none bg-black/40 !text-white"
      >
        <i class="pi pi-times"></i>
        <span class="text-sm font-semibold">Close</span>
      </Button>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Header from "@/ui/layouts/MainHeader.vue";
import { onMounted, ref } from "vue";
import MainFooter from "./MainFooter.vue";

const videoVisible = ref(false);

const embedUrl = () => {
  const baseUrl = "https://www.youtube.com/embed/APv8yihoOaM";
  const params = new URLSearchParams({
    autoplay: "0",
    rel: "0",
    modestbranding: "0",
    controls: "1",
    showinfo: "0",
    fs: "1",
  });

  params.append("playlist", "APv8yihoOaM");
  params.append("loop", "1");
  params.append("iv_load_policy", "3");

  return `${baseUrl}?${params.toString()}`;
};

onMounted(() => {
  const isFirstVisit = localStorage.getItem("4c_visited") === null;
  if (isFirstVisit) {
    setTimeout(() => {
      // videoVisible.value = true;
      localStorage.setItem("4c_visited", "true");
    }, 8000);
  }
});
</script>
