<template>
  <div class="h-full">
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1670_14118)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.77273 0.636364C1.77273 0.28491 1.48782 0 1.13636 0C0.78491 0 0.5 0.28491 0.5 0.636364V13.3636C0.5 13.7151 0.78491 14 1.13636 14H13.8636C14.2151 14 14.5 13.7151 14.5 13.3636C14.5 13.0122 14.2151 12.7273 13.8636 12.7273H1.77273V0.636364ZM10.0455 4.24242C9.694 4.24242 9.40909 4.52733 9.40909 4.87879C9.40909 5.23024 9.694 5.51515 10.0455 5.51515H11.4788L9.19697 7.79702L7.10149 5.70154C6.85298 5.45302 6.45005 5.45302 6.20154 5.70154L3.65608 8.24699C3.40757 8.49551 3.40757 8.89843 3.65608 9.14695C3.9046 9.39546 4.30752 9.39546 4.55604 9.14695L6.65152 7.05147L8.74699 9.14695C8.99551 9.39546 9.39843 9.39546 9.64695 9.14695L12.3788 6.41511V7.92487C12.3788 8.27633 12.6637 8.56124 13.0152 8.56124C13.3666 8.56124 13.6515 8.27633 13.6515 7.92487V4.87879C13.6515 4.52733 13.3666 4.24242 13.0152 4.24242H10.0455Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1670_14118">
          <path
            d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H10.5C12.7091 0 14.5 1.79086 14.5 4V10C14.5 12.2091 12.7091 14 10.5 14H4.5C2.29086 14 0.5 12.2091 0.5 10V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
