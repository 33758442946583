
import axiosInstance from '@/api/axiosInstance';
import { type AxiosInstance } from 'axios';

const api: AxiosInstance = axiosInstance;

export const commonApi = {
    async searchGlobal(search: string) {
        const queryString = new URLSearchParams({ query: search }).toString();
        const response = await api.get(`/search?${queryString}`);
        return response.data;
    },
};
