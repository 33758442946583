import { modelsApi } from "@/api/modelsApi";
import type { Model } from "@/types/model";
import StringHelper from "@/utils/stringHelper";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useEventTypesStore } from "./eventTypesStore";

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const now = Date.now();

export const useModelStore = defineStore("models", () => {
  const eventTypeStore = useEventTypesStore();
  const models = ref<Model[]>([]);
  const relatedModels = ref<Model[]>([]);
  const isLoading = ref(false);
  const hasMore = ref([]);
  const lastFetched = ref({});

  function setIsLoading(loading: boolean) {
    isLoading.value = loading;
  }

  const fetchModels = async (filters: Record<string, string>, retry = true) => {
    const key = StringHelper.keyFromRecord(filters);

    if ((!filters.q || filters.q.length < 3) && models.value[key] && now - (lastFetched.value[key] || 0) < CACHE_DURATION) {
      return { models: models.value[key], more: hasMore.value[key] || false };
    }

    try {
      isLoading.value = true;
      const response = await modelsApi.getModels(filters);
      models.value[key] = response.data.data;

      if (response.data.pagination.current_page < response.data.pagination.last_page) {
        hasMore.value[key] = true;
      } else {
        hasMore.value[key] = false;
      }

      lastFetched.value[key] = Date.now(); // Update last fetched time

      // If no models are returned and this is the first attempt, refresh and retry
      if (models.value[key].length === 0 && retry) {
        console.warn("No models returned, refreshing cache and retrying...");
        refreshModels(filters);
        return fetchModels(filters, false); // Retry once
      }

      return { models: models.value[key], more: hasMore.value[key] };
    } catch (error) {
      console.error("Error fetching models:", error);
    } finally {
      isLoading.value = false;
    }
  };

  // Function to refresh cached models
  const refreshModels = (filters: Record<string, string>) => {
    const key = StringHelper.keyFromRecord(filters);
    delete models.value[key];
    delete hasMore.value[key];
    delete lastFetched.value[key];
    console.log("Cache cleared for key:", key);
  };

  const fetchRelatedModels = async (model: Model) => {
    try {
      const result = await modelsApi.getModels({ per_page: "4", sub_type: eventTypeStore.getChildTypeById(model.id)?.slug });
      relatedModels.value = result.data.data;
    } catch (error) {
      relatedModels.value = [];
      console.log((error as Error).toString());
    }
  };

  return {
    models,
    relatedModels,
    isLoading,
    hasMore,
    setIsLoading,
    fetchModels,
    fetchRelatedModels,
    refreshModels
  };
});
