// utils/dateFormatter.ts
type FormattedDateTime = {
    formattedDate: string;
    formattedTime: string;
};

/**
 * Formats a date-time string into a localized date and time.
 * @param dateString - The ISO date-time string to format.
 * @param locale - Optional locale; defaults to the user's browser locale.
 * @returns An object containing formatted date and time strings.
 */
export function formatDateTime(
  dateString: string,
  locale: string = navigator.language,
  excludeYear: boolean = false,
  monthFormat: 'short' | 'long' = 'long',
  hour12: boolean = true
): FormattedDateTime {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: monthFormat,
      day: 'numeric',
      ...(excludeYear ? {} : { year: 'numeric' })
  };

  const dayFormatter = new Intl.DateTimeFormat(locale, options);

  const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: hour12,
  });

  return {
      formattedDate: dayFormatter.format(date),
      formattedTime: timeFormatter.format(date).toLocaleUpperCase(),
  };
}


export function formatShortDateTime(
    dateString: string,
    locale: string = navigator.language
): FormattedDateTime {
    const date = new Date(dateString);

    const dayFormatter = new Intl.DateTimeFormat(locale, {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const timeFormatter = new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    return {
        formattedDate: dayFormatter.format(date),
        formattedTime: timeFormatter.format(date).toLocaleUpperCase(),
    };
}
