<template>
  <GridLayout>
    <template #leftSidebar>
      <ModelLeftSidebar />
    </template>
    <ModelsMobileCategories />
    <div class="bg-white p-2 pb-8 md:bg-transparent md:p-4">
      <div v-if="!loadingBuilder && builder">
        <h1
          class="mb-3 flex items-center gap-2 text-[18px] font-semibold text-slate-700 md:text-[22px]"
        >
          <Button
            @click="router.push({ name: 'creators' })"
            class="!h-[33px] !gap-1 !rounded-[6px] border-[#020617] bg-transparent !px-[10.5px] !py-0"
          >
            <ArrowBackIcon class="h-[14px] w-[14px]" />
            <span class="hidden !text-sm font-medium text-slate-950 md:block">Back</span> </Button
          >-
          <span>{{ builder.first_name }} {{ builder.last_name }}</span>
        </h1>
        <div
          class="mb-[16px] rounded-lg bg-white p-[16px] md:shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]"
        >
          <div class="flex flex-col items-start justify-between gap-3 md:flex-row md:items-center">
            <div class="flex items-center gap-2">
              <div
                class="h-[56px] w-[56px] rounded-[10.5px]"
                :style="{
                  backgroundImage: `url(${builder.avatar ?? '/images/avatar.png'})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                }"
              />
              <div
                class="flex flex-col items-start justify-center gap-1 font-semibold text-slate-700"
              >
                <div
                  class="relative justify-start font-['Inter'] text-[10px] font-medium uppercase leading-3 tracking-tight text-slate-400"
                >
                  Model Builder Name
                </div>
                <div class="text-[16px] font-bold leading-none text-slate-700">
                  {{ builder.first_name }} {{ builder.last_name }}
                </div>
              </div>
            </div>
            <a
              :href="'https://www.linkedin.com/in/' + builder.linkedin + '/'"
              target="_blank"
              class="flex h-[32px] w-full items-center justify-center gap-1 rounded-[5px] bg-sky-600 px-[7px] py-[8px] md:w-auto"
            >
              <SvgIcon name="linkedin" class="h-[16px] w-[16px]" />
              <span class="relative justify-start text-xs font-bold text-white"
                >View LinkedIn Profile</span
              >
            </a>
          </div>
          <div class="mt-2">{{ builder.bio }}</div>
        </div>
      </div>
      <!-- <div
        class="scroll-container mb-4 flex w-full items-center overflow-hidden overflow-x-auto border-b border-slate-200"
      >
        <div
          v-for="filter in filters"
          :key="filter.code"
          @click="selectFilter(filter)"
          class="text-grey-700 flex h-[46px] cursor-pointer items-center whitespace-nowrap px-4 font-[500] hover:text-black"
          :class="{ '!border-b !border-black !text-black': filter.code == selectedFilter }"
        >
          {{ filter.name }}
        </div>
      </div> -->

      <!-- <div class="mb-3 flex w-full items-center justify-between gap-4">
        <div
          class="flex h-[33px] flex-1 items-center rounded-md border border-slate-300 bg-white px-3"
        >
          <SvgIcon name="search" class="h-3.5 w-3.5" />
          <InputText
            placeholder="Search..."
            v-model="searchQuery"
            @input="debounceSearch"
            class="border-0 bg-transparent text-sm font-semibold text-slate-700 !shadow-none"
          />
        </div>
        <div class="relative flex h-[33px] items-center gap-2">
          <span class="text-xs font-normal text-slate-700">Sort by:</span>
          <Select
            v-model="selectedSortFilter"
            :options="displaySortFilters"
            optionLabel="name"
            @change="filterChanged"
            optionValue="code"
            class="h-[33px] min-w-[100px] border border-slate-300 bg-transparent text-sm"
          />
        </div>
      </div> -->
      <div v-if="!modelStore.isLoading && models.length > 0" class="flex flex-col gap-3">
        <ModelCard v-for="(model, index) in models" :model="model" :key="index" />
      </div>
      <div v-if="modelStore.isLoading" class="flex flex-col gap-3">
        <ModelCardSkeleton v-for="index in 8" :key="index" />
      </div>
      <div
        v-if="!modelStore.isLoading && models.length === 0"
        class="flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-50 p-3"
      >
        <SvgIcon name="info" class="mr-2" />
        <span class="text-sm font-medium text-yellow-600">
          No models found
          <span v-if="type"
            >under
            <strong
              >{{ type }} <span v-if="subType">→ {{ subType }}</span></strong
            ></span
          >
        </span>
      </div>

      <div v-if="hasMore && models.length > 0" class="my-4 flex items-center justify-center">
        <Button
          @click="loadMore"
          :disabled="modelStore.isLoading"
          class="rounded-md border bg-lime-400 px-4 py-2 text-sm"
        >
          <i v-if="modelStore.isLoading" class="pi pi-spin pi-spinner"></i> Show More
        </Button>
      </div>
    </div>
    <template #rightSidebar>
      <EventUpcoming />
    </template>
  </GridLayout>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/authStore";
import { useModelStore } from "@/stores/modelStore";
import type { Model } from "@/types/model";
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { buildersApi } from "@/api/buildersApi";
import type { User } from "@/types/user";
import EventUpcoming from "@/ui/components/events/EventUpcoming.vue";
import ArrowBackIcon from "@/ui/components/icons/ArrowBackIcon.vue";
import ModelCard from "@/ui/components/models/ModelCard.vue";
import ModelCardSkeleton from "@/ui/components/models/ModelCardSkeleton.vue";
import ModelLeftSidebar from "@/ui/components/models/ModelLeftSidebar.vue";
import ModelsMobileCategories from "@/ui/components/models/ModelsMobileCategories.vue";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import GridLayout from "@/ui/layouts/GridLayout.vue";
import { showGlobalToast } from "@/utils/toast";

const route = useRoute();
const router = useRouter();
const updateMeta = inject("updateMeta") as (meta) => void;
const authStore = useAuthStore();
const modelStore = useModelStore();

const selectedFilter = ref((route.query.filter as string) || "all");
const selectedSortFilter = ref((route.query.sort as string) || "most_accurate");

const searchQuery = ref((route.query.q as string) || "");
const type = ref((route.params.type as string) || "");
const subType = ref("");
const models = ref<Model[]>([]);
const page = ref(1);
const hasMore = ref(false);
const pageSize = 16;
const builder = ref<User | null>(null);
const loadingBuilder = ref(true);

const filters = ref([
  { name: "All AI Models", code: "all" },
  { name: "Premium AI Models", code: "paid" },
  { name: "Free AI Models", code: "free" },
]);

// const sortFilters = ref([
//   { name: "Recently Updated", code: "recently_updated" },
//   { name: "Least Accurate", code: "least_accurate" },
//   { name: "Most Accurate", code: "most_accurate" },
//   { name: "Most Expensive", code: "most_expensive" },
//   { name: "Least Expensive", code: "least_expensive" },
// ]);

// const displaySortFilters = computed(() =>
//   selectedFilter.value === "free"
//     ? sortFilters.value.filter((item) => !["most_expensive", "least_expensive"].includes(item.code))
//     : sortFilters.value,
// );

const fetchBuilder = async () => {
  if (route.params.builderId) {
    const result = await buildersApi.getSingleBuilder(route.params.builderId as string);
    if (result.id) {
      builder.value = result;
    }
  }
  loadingBuilder.value = false;
};

const fetchItems = async () => {
  try {
    fetchBuilder().then(async () => {
      const result = await modelStore.fetchModels({
        q: searchQuery.value,
        type: type.value,
        sub_type: subType.value,
        per_page: pageSize.toString(),
        page: page.value.toString(),
        sort: selectedSortFilter.value,
        filter: selectedFilter.value,
        builder_id: builder.value ? builder.value.id : "",
      });

      models.value = page.value === 1 ? result.models : [...models.value, ...result.models];
      hasMore.value = result.more;
      page.value++;
    });
  } catch ({}) {
    showGlobalToast("error", "", "Error fetching models");
  }
};

// const selectFilter = (filter) => {
//   selectedFilter.value = filter.code;

//   if (
//     filter.code === "free" &&
//     ["most_expensive", "least_expensive"].includes(selectedSortFilter.value)
//   ) {
//     selectedSortFilter.value = "recently_updated";
//   }

//   router
//     .push({ path: route.path, query: { ...route.query, filter: selectedFilter.value } })
//     .then(resetSearch);
// };

// const filterChanged = () => {
//   router
//     .push({ path: route.path, query: { ...route.query, filter: selectedFilter.value } })
//     .then(resetSearch);
// };

const resetSearch = () => {
  page.value = 1;
  models.value = [];
  hasMore.value = true;
  fetchItems();
};
const loadMore = () => fetchItems();
//const debouncedSearch = useDebounceFn(resetSearch, 300);
//const debounceSearch = () => debouncedSearch();

updateMeta({
  title: "4CPredictions - All AI Models",
});

watch(
  () => authStore.user,
  () => {
    if (authStore.user && !filters.value.find((x) => x.code == "subscribed")) {
      filters.value.splice(1, 0, { name: "My Ai Models", code: "subscribed" });
    }
  },
  { immediate: true },
);

watch(
  () => route.params.type,
  () => {
    if (route.params.type) {
      type.value = route.params.type as string;
      selectedFilter.value = "all";
    } else {
      type.value = null;
      subType.value = null;
    }
    resetSearch();
  },
);

watch(
  () => route.params.subType,
  () => {
    if (route.params.subType) {
      subType.value = route.params.subType as string;
      selectedFilter.value = "all";
    } else {
      subType.value = null;
    }
    resetSearch();
  },
);

onMounted(fetchItems);
</script>
