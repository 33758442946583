<template>
  <div class="min-w-screen min-h-screen bg-white p-2 md:p-6">
    <div
      class="mx-auto flex flex-col justify-center rounded-xl shadow-none md:max-w-5xl md:flex-row md:shadow-md"
    >
      <!-- Left side -->
      <div
        class="flex-1 rounded-t-xl bg-gray-50 p-4 md:mt-0 md:rounded-l-xl md:rounded-tr-none md:p-6"
      >
        <div class="mb-4 flex items-center gap-2 text-lg font-bold md:text-xl">
          <a @click.prevent="goBack" title="Go Back" style="cursor: pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              class="h-6 w-6 text-gray-500 hover:text-gray-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12H3m0 0l6-6m-6 6l6 6"
              />
            </svg>
          </a>
          <span class="text-primary">4C</span>
          <span>Predictions</span>
        </div>
        <div>
          <h2 class="mb-1">Subscribe to {{ model?.name }}</h2>
          <div class="mb-8 flex items-center">
            <span class="text-sm font-bold">${{ model?.price.toFixed(2) }}</span>
            <span class="ml-2 text-sm leading-none text-gray-500"> per month </span>
          </div>
          <div class="hidden items-start justify-start gap-3 md:flex">
            <img
              :src="modelImageSrc"
              @error="onModelImageError"
              class="h-10 w-10 flex-shrink-0 rounded"
            />
            <div class="relative top-[-5px] text-gray-700">
              <p class="font-medium">{{ model?.name }}</p>
              <p class="hidden text-sm md:block">
                {{ model?.description }}
              </p>
              <p class="text-sm md:hidden">
                {{ model && StringHelper.truncateText(model.description, 65) }}
              </p>
            </div>
          </div>

          <div class="mt-4 flex flex-col items-center justify-center gap-2 self-stretch">
            <div class="self-stretch text-sm font-semibold leading-tight text-slate-950">
              ✨ Included in This AI Model:
            </div>
            <div
              class="inline-flex items-center justify-start gap-2 self-stretch overflow-hidden px-4"
            >
              <div class="text-sm font-normal leading-tight text-black">
                ✅ Get all {{ modelType?.name }} predictions from this AI model.
              </div>
            </div>
            <div
              class="inline-flex items-center justify-start gap-2 self-stretch overflow-hidden px-4"
            >
              <div class="text-sm font-normal leading-tight text-black">
                ✅ Full access to all future predictions from this AI model.
              </div>
            </div>
            <div
              class="inline-flex items-center justify-start gap-2 self-stretch overflow-hidden px-4"
            >
              <div class="text-sm font-normal leading-tight text-black">
                ✅ Real-time updates—see this AI model’s latest insights.
              </div>
            </div>
            <div
              class="inline-flex items-center justify-start gap-2 self-stretch overflow-hidden px-4"
            >
              <div class="text-sm font-normal leading-tight text-black">
                ✅ No hidden fees—just one simple monthly price.
              </div>
            </div>
          </div>
          <div class="mt-4 flex w-full flex-row items-center justify-between gap-2 border-t pt-4">
            <div v-if="!isMobile">Add promo code</div>
            <div class="flex flex-1 items-center gap-2 font-bold">
              <InputText
                v-model="promoCode"
                class="w-full"
                :placeholder="isMobile ? 'Enter promo code' : ''"
                @blur="validatePromoCode"
                :disabled="isPromoDisabled || currentView == 'success'"
              />
              <Button
                @click="validatePromoCode"
                class="px-6 text-sm"
                :loading="isValidatingPromoCode"
                :disabled="isPromoDisabled || currentView == 'success'"
                ><span class="font-semibold">Apply</span></Button
              >
            </div>
          </div>
          <div
            v-if="promoCodeMessage"
            class="mt-2 text-sm"
            :class="{
              'text-green-600': promoCodeStatus === 'valid',
              'text-red-600': promoCodeStatus === 'invalid',
            }"
          >
            {{ promoCodeMessage }}
          </div>
        </div>
      </div>

      <!-- Right side -->
      <div
        class="flex-1 rounded-b-xl bg-white p-4 pb-6 md:rounded-b-none md:rounded-r-xl md:p-6 md:pb-0"
      >
        <div v-if="currentView === 'loading-page'" class="text-center">
          <div class="flex animate-pulse justify-center">
            <div class="h-12 w-12 rounded-full bg-gray-200"></div>
          </div>
          <p class="mt-4 text-sm">Loading ...</p>
        </div>

        <div v-else-if="currentView === 'form'">
          <h2 class="mb-6 text-[15px] font-semibold md:text-xl">
            Join 13,000+ fans using genius AI predictions
          </h2>
          <div class="mb-6 flex gap-4 md:hidden">
            <img :src="modelImageSrc" @error="onModelImageError" class="h-12 w-12 rounded" />
            <div class="flex flex-col text-gray-700">
              <span class="font-medium">{{ model?.name }}</span>
              <span class="text-sm">${{ model?.price.toFixed(2) }}</span>
            </div>
          </div>
          <div>
            <CollectJsForm
              v-if="!existingCustomer"
              @token-received="handleToken"
              @error="handleError"
            />
            <ExistingCustomerForm
              v-else
              :cc_number_used="cc_number_used"
              @token-received="handleToken"
              @error="handleError"
            />
          </div>
          <div class="mt-4 flex justify-between">
            <img src="/images/trusted-icons/ssl-secure.svg" alt="SSL Secure Safe Checkout" />
            <img src="/images/trusted-icons/money-back.svg" alt="100% Money Back Guarantee" />
            <img src="/images/trusted-icons/mcafee-secure.svg" alt="Mcafee Secure" />
            <img src="/images/trusted-icons/google-trusted.svg" alt="Google Trusted Store" />
          </div>
        </div>

        <div v-else-if="currentView === 'success'" class="text-center">
          <h2 class="mb-4 text-green-600">Success!</h2>
          <p class="mb-4 text-sm">
            Welcome to the future of smarter decisions! You now have access to premium AI
            predictions—giving you powerful insights to stay ahead.
          </p>
          <Button @click="goBack" class="h-9 rounded border-sky-500 bg-sky-500 px-4 !text-white"
            >Go Back</Button
          >
        </div>

        <div v-else-if="currentView === 'error'" class="text-center">
          <h2 class="mb-4 text-rose-600">Payment Error</h2>
          <p class="mb-4 text-sm">{{ errorMessage }}</p>
          <Button
            @click="resetForm"
            class="h-9 rounded border-yellow-500 bg-yellow-500 px-4 !text-slate-700"
            >Try Again</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { modelsApi } from "@/api/modelsApi";
import { paymentApi } from "@/api/paymentApi";
import { useScreenSize } from "@/composables/useScreenSize";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Model } from "@/types/model";
import CollectJsForm from "@/ui/components/payments/CollectJsForm.vue";
import ExistingCustomerForm from "@/ui/components/payments/ExistingCustomerForm.vue";
import StringHelper from "@/utils/stringHelper";
import { showGlobalToast } from "@/utils/toast";
import { AxiosError } from "axios";
import { InputText } from "primevue";
import Button from "primevue/button";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const { isMobile } = useScreenSize();
const router = useRouter();
const route = useRoute();
const loading = ref(true);
const model = ref<Model>(null);
const currentView = ref<"form" | "success" | "error" | "loading-page">("loading-page");
const errorMessage = ref("");
const eventTypeStore = useEventTypesStore();
const existingCustomer = ref(false);
const cc_number_used = ref("");
const promoCode = ref("");
const priceWithoutPromo = ref(0);
const isPromoDisabled = ref(false);
const modelType = ref(null);
const modelImageSrc = ref(null);

const modelImagePlaceholderSrc = "/images/placeholder.svg";

const onModelImageError = () => {
  modelImageSrc.value = modelImagePlaceholderSrc;
};

const fetchData = async (id: string) => {
  model.value = await modelsApi.getSingleModel(id);
  const metadataItem = model.value.metadata.find((metadata) => metadata.key === "Avatar");
  modelImageSrc.value = metadataItem?.value;

  modelType.value = eventTypeStore.getChildTypeById(model.value.event_sub_type_id);
  priceWithoutPromo.value = model.value.price;

  const customerExistsInVaultData = await paymentApi.checkIfCustomerExistsInVault();
  existingCustomer.value = customerExistsInVaultData["exists"];
  cc_number_used.value = customerExistsInVaultData["cc_number"] || "**** **** **** ****";

  loading.value = false;
  currentView.value = "form";
};

onMounted(() => {
  const orderId = route.params.orderId as string;

  if (orderId) {
    fetchData(orderId);
  } else {
    showGlobalToast("error", "Failed", "Payment details format");
    router.push("/");
  }
});

const promoCodeStatus = ref<"valid" | "invalid" | null>(null);
const promoCodeMessage = ref("");
const isValidatingPromoCode = ref(false);

const validatePromoCode = async () => {
  // If no promo code was entered, clear any messages.
  if (!promoCode.value) {
    promoCodeStatus.value = null;
    promoCodeMessage.value = "";
    model.value.price = priceWithoutPromo.value;
    return;
  }
  isValidatingPromoCode.value = true;
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "promoCodeEntered",
    });
    const result = await paymentApi.validatePromoCode(promoCode.value, model.value.id);
    console.log(result);

    if (result.success) {
      promoCodeStatus.value = "valid";
      promoCodeMessage.value = result.message;
      model.value.price = result.data;
    } else {
      promoCodeStatus.value = "invalid";
      promoCodeMessage.value = result.message;
      model.value.price = priceWithoutPromo.value;
    }
  } catch (error) {
    console.error("Error validating promo code:", error);
    promoCodeStatus.value = "invalid";
    promoCodeMessage.value = "An error occurred while validating the promo code";
    model.value.price = priceWithoutPromo.value;
  } finally {
    isValidatingPromoCode.value = false;
  }
};

const handleToken = async (token: string) => {
  isPromoDisabled.value = true;
  try {
    const result = await paymentApi.submitPayment({
      model_id: model.value.id,
      ...(token !== "EXISTING_CUSTOMER" ? { token } : {}), // Don't send a token for existing customers
      promo_code: promoCode.value,
    });

    if (result.success) {
      currentView.value = "success";
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "subscribeFE",
        ecommerce: {
          value: model.value.price,
          currency: "USD",
        },
      });
    } else {
      currentView.value = "error";
      errorMessage.value = result.message;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "subscribeFailed",
      });
    }
  } catch (error) {
    handleError(error);
  } finally {
    isPromoDisabled.value = false;
  }
};

const handleError = (error: unknown) => {
  console.error("Payment form error:", error);
  currentView.value = "error";
  errorMessage.value =
    error instanceof AxiosError
      ? error.response.data.message
      : "An unknown error occurred during payment processing";
};

const goBack = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentCanceled",
  });
  history.back();
};

const resetForm = () => {
  currentView.value = "form";
  errorMessage.value = "";
};
</script>
