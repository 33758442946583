<template>
  <div class="h-full">
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1670_10350)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.14128 1.25717C4.10846 1.28747 4.1 1.31653 4.1 1.33846V5.76923C4.1 6.88899 4.40898 7.84919 4.96669 8.51799C5.51255 9.17258 6.33835 9.6 7.5 9.6C8.66165 9.6 9.48745 9.17258 10.0333 8.51799C10.591 7.84919 10.9 6.88899 10.9 5.76923V1.33846C10.9 1.31653 10.8915 1.28747 10.8587 1.25717C10.8251 1.22613 10.7705 1.2 10.7 1.2H4.3C4.22951 1.2 4.17492 1.22613 4.14128 1.25717ZM2.9 1.33846C2.9 0.554935 3.57292 0 4.3 0H10.7C11.4271 0 12.1 0.554935 12.1 1.33846V1.6H13.1C13.8732 1.6 14.5 2.2268 14.5 3V4.6C14.5 6.12656 13.2787 7.33697 11.8542 7.56229C11.6664 8.20265 11.3691 8.78981 10.9549 9.28652C10.273 10.1042 9.30696 10.6364 8.1 10.7681V12.8H9.9C10.2314 12.8 10.5 13.0686 10.5 13.4C10.5 13.7314 10.2314 14 9.9 14H5.1C4.76863 14 4.5 13.7314 4.5 13.4C4.5 13.0686 4.76863 12.8 5.1 12.8H6.9V10.7681C5.69304 10.6364 4.72697 10.1042 4.04508 9.28652C3.63088 8.78981 3.33356 8.20265 3.14585 7.56229C1.72135 7.33697 0.5 6.12656 0.5 4.6V3C0.5 2.2268 1.1268 1.6 1.9 1.6H2.9V1.33846ZM2.9 2.8H1.9C1.78954 2.8 1.7 2.88954 1.7 3V4.6C1.7 5.32778 2.21543 5.99055 2.91741 6.26559C2.90577 6.1016 2.9 5.93598 2.9 5.76923V2.8ZM12.0826 6.26559C12.7846 5.99055 13.3 5.32778 13.3 4.6V3C13.3 2.88954 13.2105 2.8 13.1 2.8H12.1V5.76923C12.1 5.93598 12.0942 6.1016 12.0826 6.26559Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1670_10350">
          <rect x="0.5" width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
