<template>
  <div
    class="min-h-[181px] rounded-xl bg-white/50 p-[17.50px] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10)]"
  >
    <div
      class="mb-2 flex w-full flex-1 grow-0 flex-col gap-3 border-b border-slate-200 pb-2 md:flex-row md:items-center md:justify-between md:border-b-2 md:border-slate-100"
    >
      <div class="flex h-[36px] flex-1 flex-col justify-center gap-1">
        <Skeleton height="8px" width="50px" class="hidden md:block" />
        <Skeleton height="6px" width="180px" />
      </div>
      <div class="hidden flex-1 flex-col items-center justify-center gap-1 md:flex">
        <Skeleton height="5px" width="25px" />
        <Skeleton height="30px" width="55px" class="h-[28px] min-w-[136px] rounded-md" />
      </div>
      <div class="hidden flex-1 flex-col items-end justify-center gap-1 md:flex">
        <Skeleton height="5px" width="60px" />
        <Skeleton height="5px" width="40px" />
      </div>
      <div class="flex items-center justify-between md:hidden">
        <div class="flex flex-col items-start justify-center">
          <Skeleton />
          <Skeleton />
        </div>
        <div class="flex flex-col items-end justify-center">
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    </div>
    <div class="flex w-full flex-1 flex-col gap-1">
      <div
        v-for="(option, index) in Array.from({ length: 2 })"
        :key="index"
        class="inline-flex items-center justify-between self-stretch py-1"
      >
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
          <div class="inline-flex items-center justify-start gap-3">
            <Skeleton height="30px" width="30px" />
            <Skeleton height="8px" width="50px" />
          </div>
        </div>
        <Skeleton height="30px" width="30px" />
      </div>
    </div>
  </div>
</template>
