<template>
  <form @submit.prevent="submit" v-if="!showResendButton">
    <div class="mb-4 self-stretch">
      <span class="font-['Inter'] text-sm font-normal leading-tight text-slate-700">
        Instructions will be sent to your registered email address.
      </span>
    </div>
    <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
      <div
        class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
      >
        <InputText
          v-model="emailAddress"
          :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
          placeholder="Enter your email"
          class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
        />
      </div>
    </div>
    <Button type="submit" class="mt-4 w-full px-[10.50px] py-[7px]">
      <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
      <div class="text-sm font-medium text-slate-700">Recover Password</div>
    </Button>
  </form>
  <div v-else>
    <p
      class="rounded-md border border-green-200 bg-green-50 p-2 py-4 text-center font-['Inter'] text-sm font-normal leading-tight text-green-500"
    >
      We have sent you some instructions.<br />Please check spam and junk folders in case you don't
      see it.
    </p>
    <Button
      v-if="countdown > 0"
      text
      disabled
      class="w-full px-[10.50px] py-[7px] !text-slate-700 opacity-100 hover:bg-transparent"
    >
      Resend available in<br />
      {{ countdown }}
    </Button>
    <Button
      v-else
      text
      @click="resendLink"
      :disabled="isResending"
      :loading="isResending"
      class="w-full px-[10.50px] py-[7px] !text-sky-500 hover:bg-transparent hover:underline"
    >
      <span class="text-sm font-semibold">Resend link</span>
      <i :class="`pi pi-${resendSuccess ? 'check-circle' : 'sync'} text-xs`"></i>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { useAuthStore } from "@/stores/authStore";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { computed, onUnmounted, ref } from "vue";

const authStore = useAuthStore();

const countdown = ref(0);
const showResendButton = ref(false);
const isResending = ref(false);
const resendSuccess = ref(false);
const emailAddress = ref("");
let countdownTimer = null;

const startCountdown = (duration) => {
  countdown.value = duration;
  countdownTimer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(countdownTimer);
    }
  }, 1000);
};

const rules = computed(() => ({
  emailAddress: { required, email },
}));

const v$ = useVuelidate(rules, {
  emailAddress,
});

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    authStore.setIsLoading(true);
    try {
      const result = await authApi.forgot({
        email: emailAddress.value,
      });

      if (result.status === 204) {
        showResendButton.value = true;
        startCountdown(60);
      }
    } finally {
      authStore.setIsLoading(false);
    }
  }
};

const resendLink = async () => {
  isResending.value = true;
  try {
    const result = await authApi.resendPasswordLink({
      email: emailAddress.value,
    });

    if (result.status === 204) {
      showResendButton.value = true;
      startCountdown(60);
    }
  } finally {
    isResending.value = false;
  }
};
onUnmounted(() => {
  if (countdownTimer) {
    clearInterval(countdownTimer);
  }
});
</script>
