<script setup lang="ts">
import PlainLayout from "@/ui/layouts/PlainLayout.vue";

import { userApi } from "@/api/userApi";
import { useAuthStore } from "@/stores/authStore";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, maxLength, minLength, required, sameAs } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { computed, onMounted, ref } from "vue";

const authStore = useAuthStore();
const loading = ref(false);
const firstName = ref("");
const lastName = ref("");
const emailAddress = ref("");
const phone = ref("");
const message = ref("");
const acceptTerms = ref(null);
const success = ref(false);

const rules = computed(() => ({
  firstName: { required, minLength: minLength(2), maxLength: maxLength(50) },
  lastName: { required, minLength: minLength(2), maxLength: maxLength(50) },
  emailAddress: { required, email },
  phone: { required, minLength: minLength(3) },
  message: { required, minLength: minLength(3) },
  acceptTerms: { required: helpers.withMessage("You must accept the terms.", sameAs(true)) },
}));

const v$ = useVuelidate(rules, {
  firstName,
  lastName,
  emailAddress,
  phone,
  message,
  acceptTerms,
});

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    loading.value = true;
    try {
      const result = await userApi.cancelSubscription({
        first_name: firstName.value,
        last_name: lastName.value,
        email: emailAddress.value,
        phone: phone.value,
        message: message.value,
      });

      if (result) {
        success.value = true;
      }
    } finally {
      loading.value = false;
    }
  }
};
onMounted(() => {
  if (authStore.user) {
    emailAddress.value = authStore.user?.email;
  }
});
</script>

<template>
  <PlainLayout>
    <div className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-lg md:px-10">
        <h1 class="mb-6 text-center text-3xl font-bold">
          {{ success ? "We've Received Your Request!" : "Cancel Subscription" }}
        </h1>
        <form v-if="!success" @submit.prevent="submit">
          <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
            <div
              class="inline-flex w-full flex-col items-start justify-start gap-2.5 self-stretch md:flex-row"
            >
              <div
                class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  First Name
                </div>
                <InputText
                  v-model="firstName"
                  :class="{ 'p-invalid': v$.firstName.$invalid && v$.firstName.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
              <div
                class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  Last Name
                </div>
                <InputText
                  v-model="lastName"
                  :class="{ 'p-invalid': v$.lastName.$invalid && v$.lastName.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
            </div>

            <div
              class="mt-4 inline-flex w-full flex-col items-start justify-start gap-2.5 self-stretch md:flex-row"
            >
              <div
                class="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-bold leading-none text-slate-700">
                  Email Address
                </div>
                <InputText
                  v-model="emailAddress"
                  :readonly="authStore.isAuthenticated"
                  :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
                  type="email"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
              <div
                class="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
              >
                <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                  Phone number
                </div>
                <InputText
                  v-model="phone"
                  :class="{ 'p-invalid': v$.phone.$invalid && v$.phone.$dirty }"
                  class="h-[35px] w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
                />
              </div>
            </div>
            <div
              class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
            >
              <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                How can we help?
              </div>
              <Textarea
                v-model="message"
                rows="5"
                :class="{ 'p-invalid': v$.message.$invalid && v$.message.$dirty }"
                placeholder="Type your message..."
                class="w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
              />
            </div>
            <div class="mt-2 flex items-start gap-[7px]">
              <Checkbox
                v-model="acceptTerms"
                :class="{ 'p-invalid': v$.acceptTerms.$invalid && v$.acceptTerms.$dirty }"
                inputId="acceptTerms"
                name="acceptTerms"
                :binary="true"
              />
              <label
                for="acceptTerms"
                class="relative top-[2px] cursor-pointer text-xs font-bold leading-[17.50px] text-slate-700"
              >
                You agree to our friendly
                <router-link to="/privacy-policy" class="underline">privacy policy.</router-link>
              </label>
            </div>
            <div
              v-if="v$.acceptTerms.$dirty && v$.acceptTerms.$invalid"
              class="p-error mt-1 text-xs text-red-600"
            >
              <div v-if="(v$.acceptTerms.required as any)?.$invalid">
                You must agree to the privacy policy.
              </div>
            </div>
          </div>
          <Button type="submit" class="mt-4 w-full px-12 py-[7px] md:w-auto">
            <i v-if="loading" class="pi pi-spin pi-spinner"></i>
            <div class="text-sm font-medium text-slate-700">Submit Request</div>
          </Button>
        </form>
        <div v-else class="text-center">
          <p class="text-gray-600">
            Thank you for reaching out — your request has been safely received and is now in the
            hands of our team. We'll review everything and get back to you shortly.
          </p>
          <p class="mt-4 text-gray-600">
            In the meantime, if you have any questions or need help, we're just an email away at
            <a
              href="mailto:devteam@4cpredictions.ai"
              class="font-semibold text-sky-500 hover:underline"
            >
              devteam@4cpredictions.ai</a
            >.
          </p>
          <p class="mt-4 text-gray-600">
            Please rest assured: once you've submitted your request to unsubscribe, you won’t be
            charged again. If there's ever a system hiccup and you are charged by mistake, we'll
            refund you immediately — no questions asked.
          </p>
          <p class="mt-4 font-medium text-gray-700">
            We appreciate you being part of the
            <span class="text-sky-600">4C Predictions</span> community!
          </p>
        </div>
      </div>
    </div>
  </PlainLayout>
</template>
