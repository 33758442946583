<script setup lang="ts">
import { modelsApi } from "@/api/modelsApi";
import { paymentApi } from "@/api/paymentApi";
import { useAuthStore } from "@/stores/authStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import type { Model } from "@/types/model";
import { showGlobalToast } from "@/utils/toast";
import { onMounted, ref, watchEffect } from "vue";
import SvgIcon from "../SvgIcon.vue";
import HeartIcon from "../icons/HeartIcon.vue";
import UnLockIcon from "../icons/UnLockIcon.vue";

const props = defineProps<{
  model: Model;
  btnClass?: string;
}>();

const buttonClass = ref("");
const eventTypeStore = useEventTypesStore();
const authStore = useAuthStore();
const isLoading = ref(false);
const subscribeVisible = ref(false);
const unSubscribeVisible = ref(false);

const modelType = ref(null);

const getButtonText = () => {
  if (authStore.user && authStore.user?.has_access) {
    return "Subscribed";
  } else {
    return "Get Predictions";
  }
};

const getButtonIcon = () => {
  if (authStore.user && authStore.user?.has_access) {
    return HeartIcon;
  } else {
    return UnLockIcon;
  }
};

const getButtonColor = () => {
  if (authStore.user && authStore.user?.has_access) {
    return "!bg-sky-100 !border !cursor-default !border-sky-500 !text-sky-500";
  } else {
    return "bg-sky-500 !border-sky-500 !text-white";
  }
};

const getButtonClass = () => {
  return `${buttonClass.value} ` + getButtonColor();
};

const buttonAction = () => {
  if (!authStore.user) {
    openAuthModal();
  } else if (authStore.user && !authStore.user?.has_access) {
    subscribe();
  } else {
    return;
  }
};

const requestPaymentLink = async () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "initiateCheckout",
    ecommerce: {
      value: props.model.price,
      currency: "USD",
    },
  });

  isLoading.value = true;
  const result = await paymentApi.requestLink(authStore.user, window.location.href);
  window.location.href = result.payment_url;
};

async function unFollowModel() {
  isLoading.value = true;
  try {
    await modelsApi.unSubscribeModel(props.model.id, authStore.user.id);
    showGlobalToast("success", "Success", "Model unsubscribed successfully");
  } finally {
    unSubscribeVisible.value = false;
    await authStore.fetchUser();
  }
  isLoading.value = false;
}

async function openAuthModal() {
  authStore.setAuthAndGoPay(false);
  authStore.setSelectedModel(props.model.id);
  authStore.setActiveModal("join");
  authStore.setAuthModalVisible(true);
}

async function subscribe() {
  subscribeVisible.value = true;
}

watchEffect(() => {
  if (
    (authStore.selectedModel == props.model.id && !authStore.authModalVisible && authStore.user) ||
    (authStore.user && props.model.price == 0)
  ) {
    subscribeVisible.value = true;
    authStore.setSelectedModel(null);
  }
});

onMounted(() => {
  buttonClass.value = props.btnClass ?? "h-[40px] px-4 bg-sky-500";
  modelType.value = eventTypeStore.getChildTypeById(props.model.event_sub_type_id);
});
</script>

<template>
  <Button
    @click="buttonAction"
    :class="getButtonClass()"
    class="flex w-full items-center justify-center gap-1 text-nowrap !rounded-md"
  >
    <component :is="getButtonIcon()" class="h-3.5 w-3.5" />
    <div class="justify-start text-sm font-semibold">
      {{ getButtonText() }}
    </div>
  </Button>

  <Dialog
    :visible="subscribeVisible"
    :style="{ maxWidth: '420px', width: '100%' }"
    :modal="true"
    :closable="false"
    :closeOnEscape="false"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between">
        <div class="justify-start self-stretch text-2xl font-bold leading-normal text-slate-700">
          March Madness Exclusive!
        </div>
        <Button
          @click="subscribeVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <div class="inline-flex w-full flex-col items-start justify-start gap-3.5">
      <div class="flex flex-col items-start justify-start self-stretch">
        <div class="flex flex-col items-center justify-center gap-2 self-stretch">
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Unlimited Access.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Every
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Sport,
              </span>
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Every
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Prediction.
              </span>
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Instant Picks,
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >No Waiting.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >Get the edge
              </span>
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >sports books fear.</span
              >
            </div>
          </div>
          <div class="inline-flex items-start justify-start gap-2 self-stretch">
            <div class="justify-center text-sm font-normal text-slate-700 md:text-base">✅</div>
            <div class="flex-1 justify-center">
              <span class="text-sm font-semibold leading-tight text-slate-700 md:text-base"
                >Sharp bettors
              </span>
              <span class="text-sm font-normal leading-tight text-slate-500 md:text-base"
                >use AI.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="justify-start self-stretch">
        <span class="text-xs font-normal leading-normal text-slate-500 md:text-sm">🏆 </span
        ><span class="text-xs font-semibold leading-normal text-slate-700 md:text-sm"
          >Trusted by 50,000+ Fans</span
        ><span class="text-xs font-normal leading-normal text-slate-500 md:text-sm">
          Winning with AI Predictions!</span
        >
      </div>
      <div
        class="flex flex-col items-center justify-center gap-2 self-stretch rounded-lg bg-sky-50 p-4 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="inline-flex items-center justify-between self-stretch">
          <div class="justify-start text-center">
            <span class="text-sm font-normal leading-normal text-slate-700">🚀 </span
            ><span class="text-sm font-semibold leading-normal text-slate-700"
              >March Madness Special</span
            >
          </div>
          <div class="flex items-center justify-start gap-1">
            <div
              class="justify-center text-center text-sm font-semibold leading-none text-slate-700 md:text-base"
            >
              $19
            </div>
            <div class="relative top-[-0.5px] justify-start text-center">
              <span class="text-sm font-normal leading-none text-red-600 line-through">$199</span>
              <span class="text-sm font-bold leading-none text-red-600"> </span>
            </div>
          </div>
        </div>
        <div class="flex w-full flex-col items-start justify-start gap-1">
          <Button
            :disabled="isLoading"
            @click="requestPaymentLink"
            class="flex w-full flex-1 items-center justify-center gap-1 rounded-md border-sky-500 bg-sky-500 px-[7px] py-3"
          >
            <i v-if="isLoading" class="pi pi-spin pi-spinner text-white"></i>
            <UnLockIcon v-if="!isLoading" class="h-4 w-4" />
            <div class="justify-start text-sm font-semibold text-white">Unlock Predictions</div>
          </Button>
          <div
            class="w-full justify-start text-center text-[11px] font-normal leading-normal text-slate-500"
          >
            (Limited-time offer. Cancel Anytime. Don't miss out!)
          </div>
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog
    :visible="unSubscribeVisible"
    :style="{ maxWidth: '380px', width: '100%' }"
    :modal="true"
    appends-to="self"
    :closable="false"
    :closeOnEscape="false"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div class="shrink grow basis-0 text-lg font-medium leading-loose text-slate-700">
          Your Decision, Your Insights
        </div>
        <Button
          @click="unSubscribeVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <div v-if="model.price > 0">
      <div>
        With 4C Predictions, you have full control and transparency. If this model isn’t meeting
        your expectations, feel free to unfollow or explore alternatives tailored to your needs.
      </div>
      <!-- Submit Button -->
      <div class="mt-6">
        <Button
          :disabled="isLoading"
          loading-icon="pi-spin pi-spinner"
          class="h-8 w-full border-sky-500 bg-sky-500 text-white"
          @click="unFollowModel"
        >
          <i v-if="isLoading" class="pi pi-spin pi-spinner text-white"></i>
          <span class="text-sm text-white">Unsubscribe</span>
        </Button>
      </div>
    </div>
    <div v-if="model.price == 0">
      <p>You can not unsubscribe from a free model</p>
    </div>
  </Dialog>
</template>
