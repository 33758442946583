<script setup lang="ts">
import type { Prediction } from "@/types/prediction";
import { onMounted, ref } from "vue";
import MobileLast10PredictionsBadge from "../models/MobileLast10PredictionsBadge.vue";
import ModelAccuracyBadge from "../models/ModelAccuracyBadge.vue";

const props = defineProps<{
  prediction: Prediction;
}>();

const modelAvatar = ref(null);
onMounted(() => {
  const metadataItem = props.prediction.model.metadata.find(
    (metadata) => metadata.key === "Avatar",
  );
  modelAvatar.value = metadataItem?.value;
});
</script>

<template>
  <div class="inline-flex items-center justify-between self-stretch px-4 md:hidden">
    <div class="flex flex-1 flex-col items-start justify-start gap-2 md:flex-row md:items-center">
      <div class="flex flex-1 items-center justify-start gap-2">
        <div class="flex items-center justify-start gap-2">
          <div
            class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
            :style="{
              backgroundImage: `url(${modelAvatar ?? '/images/avatar.png'})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          ></div>
          <div class="inline-flex flex-1 flex-col items-start justify-center">
            <div class="flex flex-col items-start justify-start">
              <div
                class="justify-start text-[10px] font-bold uppercase leading-3 tracking-tight text-slate-500"
              >
                Ai Model
              </div>
            </div>
            <div class="justify-start text-sm font-bold leading-none text-slate-700 md:text-base">
              {{ prediction.model.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-1 items-center justify-start gap-2">
        <div
          class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
          :style="{
            backgroundImage: `url(${prediction.model.creator.avatar ?? '/images/avatar.png'})`,
            // backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        ></div>
        <div class="inline-flex flex-1 flex-col items-start justify-center">
          <div class="flex flex-col items-start justify-start">
            <div
              class="justify-start text-[10px] font-bold uppercase leading-3 tracking-tight text-slate-500"
            >
              Built by AI Genius
            </div>
            <div
              class="justify-start text-sm font-semibold leading-none text-slate-700 md:text-base"
            >
              {{ prediction.model.creator.first_name }} {{ prediction.model.creator.last_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 items-center justify-end">
      <ModelAccuracyBadge :model="prediction.model" />
    </div>
  </div>
  <MobileLast10PredictionsBadge :last10PredictionResults="prediction.model.last_ten_prediction" />
</template>
