<script setup lang="ts">
import type { Model } from "@/types/model";
import { ModelHelper } from "@/utils/modelHelper";
import { onMounted, onUnmounted, ref } from "vue";
import SvgIcon from "../SvgIcon.vue";

const props = defineProps<{
  model: Model;
}>();

const targetNumber = ref();
const accuracyModalVisible = ref(false);
const loaded = ref(false);
const currentNumber = ref(0);
const observer = ref(null);
const counterElement = ref(null);
let animationFrame;

const animateCountUp = (start, end, duration) => {
  const startTime = performance.now();

  const step = (timestamp) => {
    const progress = Math.min((timestamp - startTime) / duration, 1);
    const interpolatedValue = progress * (end - start) + start;
    currentNumber.value = interpolatedValue.toFixed(2);

    if (progress < 1) {
      animationFrame = requestAnimationFrame(step);
    } else {
      loaded.value = true;
    }
  };

  animationFrame = requestAnimationFrame(step);
};

const handleIntersection = (entries) => {
  if (entries[0].isIntersecting) {
    animateCountUp(0, targetNumber.value, 2000);
    observer.value.disconnect();
  }
};

onMounted(() => {
  observer.value = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
  if (counterElement.value) {
    observer.value.observe(counterElement.value);
  }

  targetNumber.value = props.model.current_accuracy;
});

onUnmounted(() => {
  if (observer.value) observer.value.disconnect();
  cancelAnimationFrame(animationFrame);
});
</script>

<template>
  <div
    @click="accuracyModalVisible = true"
    :class="ModelHelper.getAccuracyColor(model.current_accuracy)"
    class="inline-flex min-h-[93px] min-w-[146px] cursor-pointer flex-col items-center justify-center gap-0.5 self-stretch rounded-lg px-5 py-2 text-white outline-1 outline-offset-[-1px] outline-green-500 md:min-h-[68px]"
  >
    <div v-if="loaded" class="inline-flex items-center justify-start gap-1">
      <div class="flex items-center justify-start gap-2">
        <div class="justify-start text-[10px] font-semibold uppercase leading-3 tracking-tight">
          Model Accuracy
        </div>
      </div>
      <i class="pi pi-info-circle text-[10px]"></i>
    </div>
    <div v-else class="justify-start text-[10px] font-semibold uppercase leading-3 tracking-tight">
      Calculating...
    </div>
    <div class="inline-flex items-center justify-center gap-1">
      <i v-if="loaded" class="pi pi-star-fill text-sm"></i>
      <div class="flex items-center justify-start text-base font-bold">
        <p ref="counterElement">{{ currentNumber }}</p>
        <span v-if="loaded">%</span>
      </div>
    </div>
    <div v-if="loaded" class="inline-flex items-start justify-start gap-0.5">
      <div class="flex items-center justify-start gap-2">
        <div class="justify-start text-[9px] font-semibold uppercase leading-3 tracking-normal">
          {{ model.events_predicted }} Games Predicted
        </div>
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="accuracyModalVisible"
    class="w-full max-w-[380px] md:max-w-[600px]"
    :modal="true"
    :closable="false"
    :closeOnEscape="true"
    :dismissableMask="true"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div class="shrink grow basis-0 text-lg font-bold leading-loose text-slate-700">
          Current Accuracy
        </div>
        <Button
          @click="accuracyModalVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <div class="py-2">
      This shows how well the model has performed in predicting real events. It starts at 0% and
      updates with every prediction—rising when correct and dropping when wrong.
    </div>
  </Dialog>
</template>
