import { eventsApi } from "@/api/eventsApi";
import type { Event } from "@/types/event";
import StringHelper from "@/utils/stringHelper";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useEventTypesStore } from "./eventTypesStore";

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const now = Date.now();

export const useEventStore = defineStore("events", () => {
    const eventTypesStore = useEventTypesStore();
  const currentEvent = ref<Event | null>(null);
  const events = ref<Event[]>([]);
  const relatedEvents = ref<Event[]>([]);
  const upcomingEvents = ref<Event[]>([]);
  const isLoading = ref(false);
  const isPredictionsLoading = ref(false);
  const relatedEventsLoading = ref(false);
  const upcomingEventsLoading = ref(false);
  const hasMore = ref([]);
  const lastFetched = ref({});

  function setIsLoading(loading: boolean) {
    isLoading.value = loading;
  }

  function clearCurrentEvent() {
    currentEvent.value = null;
  }

  const fetchEvents = async (filters: Record<string, string>) => {
    const key = StringHelper.keyFromRecord(filters);
    if (events.value[key] && now - (lastFetched.value[key] || 0) < CACHE_DURATION) {
      return { events: events.value[key], more: hasMore.value[key] || false };
    }

    try {
      isLoading.value = true;
      const response = await eventsApi.getEvents(filters);
      events.value[key] = response.data.data;
      if (response.data.pagination.current_page < response.data.pagination.last_page) {
        hasMore.value[key] = true;
      } else {
        hasMore.value[key] = false;
      }
      lastFetched.value[key] = now;
      return { events: events.value[key], more: hasMore.value[key] };
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchUpcomingEvents = async () => {
    if (upcomingEvents.value.length > 0) return;
    try {
      upcomingEventsLoading.value = true;
      const result = await eventsApi.getEvents({ page: "1", per_page: "4" });
      upcomingEvents.value = result.data.data;
    } catch (error) {
      events.value = [];
      console.log((error as Error).toString());
    } finally {
      upcomingEventsLoading.value = false;
    }
  };

  const fetchPredictions = async (id: string) => {
    try {
      isPredictionsLoading.value = true;
      return await eventsApi.getPredictions(id, {});
    } catch (error) {
      console.log((error as Error).toString());
      return [];
    } finally {
      isPredictionsLoading.value = false;
    }
  };

  const fetchRelatedEvents = async () => {
    if (currentEvent.value) {
        const eventType = eventTypesStore.getChildTypeById(currentEvent.value?.event_sub_type_id);
      const key = StringHelper.keyFromRecord({ type: eventType.slug });
      if (relatedEvents.value[key]) {
        return relatedEvents.value[key];
      }

      try {
        relatedEventsLoading.value = true;
        const result = await eventsApi.getEvents({
          per_page: "4",
          sub_type: eventType.slug,
        });
        relatedEvents.value[key] = result.data.data;
        return relatedEvents.value[key];
      } catch (error) {
        relatedEvents.value = [];
        console.log((error as Error).toString());
      } finally {
        relatedEventsLoading.value = false;
      }
    }
  };

  const fetchSingleEvent = async (id: string) => {
    try {
      const event: Event = await eventsApi.getSingleEvent(id);
      currentEvent.value = event;
    } catch (error) {
      currentEvent.value = null;
      console.log((error as Error).toString());
    }
  };

  return {
    currentEvent,
    events,
    upcomingEvents,
    relatedEvents,
    isLoading,
    isPredictionsLoading,
    relatedEventsLoading,
    upcomingEventsLoading,
    hasMore,
    setIsLoading,
    fetchEvents,
    fetchRelatedEvents,
    fetchSingleEvent,
    fetchUpcomingEvents,
    fetchPredictions,
    clearCurrentEvent,
  };
});
