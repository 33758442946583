<template>
  <div
    class="inline-flex w-full flex-col items-start justify-start gap-12 overflow-hidden bg-white pb-[62px]"
  >
    <div
      class="mx-auto mt-6 inline-flex w-full max-w-[1440px] flex-col items-center justify-start gap-12 self-stretch px-5 md:px-80"
    >
      <div class="flex flex-col items-start justify-start gap-4 self-stretch">
        <div class="self-stretch text-[40px] font-semibold leading-[48px] text-black">
          10 Reasons This Revolutionary Soccer Prediction Marketplace Is Taking Over 2025
        </div>
        <div class="inline-flex w-[303px] items-center justify-start gap-5">
          <div class="inline-flex flex-col items-start justify-start gap-0.5">
            <div class="self-stretch text-xs font-normal text-black">
              By Sports Analytics Weekly
            </div>
            <div class="self-stretch text-xs font-normal text-black">Last Updated Jan 23, 2025</div>
          </div>
        </div>
        <div class="flex flex-col items-start justify-start gap-10 self-stretch">
          <div class="self-stretch text-lg font-normal leading-loose text-black">
            Summary: Meet 4C Predictions - your gateway to the world's top independent AI prediction
            models. Take Dr. Adam's model, crushing it with 75%+ accuracy across dozens of matches.
            Or explore dozens of other proven models, each with transparent track records. For the
            first time, you can compare, choose, and follow the most successful AI prediction models
            in one place.
          </div>
          <div class="h-px self-stretch border-t border-[#cccccc]"></div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/1.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            1. Find Your Edge Through Transparency
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Gone are the days of trusting anonymous "expert picks." On 4C Predictions, every AI
            model's complete performance history is transparent. Compare accuracy rates, explore
            prediction histories, and choose models that match your strategy.
            <br />
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/2.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            2. Access the Best Independent AI Models
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Browse and follow prediction models created by leading independent data scientists. Each
            model analyzes up to 200 variables per match - from player fitness to team dynamics.
            Finally, a marketplace that lets you choose predictions based on proven performance.<br />
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/3.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            3. Track Record You Can Verify
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            See exactly how each model has performed. Many consistently achieve 70%+ accuracy rates
            across Premier League matches. Every prediction is recorded and verified, so you know
            exactly what you're getting.<br /><br />*Expert Tip: Follow multiple top-performing
            models to diversify your insights
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/4.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            4. Perfect for Every Strategy
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Whether you're a casual viewer or dedicated analyst, 4C Predictions helps you find
            models that match your goals. Filter by league, prediction type, or accuracy threshold.
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/5.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            5. Compare Models in Real-Time
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Watch how different AI models predict the same match. Spot consensus picks or find
            models that excel in specific scenarios.<br /><br />
          </div>
        </div>
      </div>
      <div
        class="flex h-full w-full flex-col items-center justify-center gap-2.5 rounded-2xl bg-gradient-to-r from-[#010516] to-[#334154] px-6 py-6 pb-4 shadow-[0px_6px_10px_0px_rgba(0,0,0,0.10)]"
      >
        <YoutubePlayerDialog videoId="XFxQf7_2Z5g">
          <template #trigger>
            <img
              src="/images/landing/soccer/video.svg"
              class="cursor-pointer rounded-xl md:max-w-[480px]"
            />
          </template>
        </YoutubePlayerDialog>
        <div class="flex flex-col items-center justify-start gap-3">
          <div class="inline-flex items-center justify-center gap-1.5 self-stretch">
            <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
            <div class="text-center text-lg font-semibold uppercase leading-loose text-[#bdff1c]">
              GET ACCESS
            </div>
            <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
          </div>
          <div
            class="self-stretch text-center text-[26px] font-bold uppercase leading-[34px] text-[#bae8f1]"
          >
            GET ACCESS TO AI MODELS
          </div>
          <div class="inline-flex items-center justify-center pb-1 pt-[3px]">
            <div class="inline-flex items-center justify-start gap-1 self-stretch">
              <div class="text-base font-bold text-[#e03e3e]">
                Offer Ends In: {{ formattedTime }} |
              </div>
              <div class="text-base font-semibold text-[#e03e3e]">Remaining Spots: 47</div>
            </div>
          </div>
          <div class="flex flex-col items-center justify-start gap-2 px-6">
            <div class="flex flex-col items-start justify-start gap-2.5">
              <router-link
                to="/"
                class="inline-flex items-center justify-start gap-2.5 rounded-lg bg-[#bdff1c] px-10 py-2"
              >
                <div class="text-md text-center font-semibold leading-loose text-black">
                  CLAIM YOUR SPOT NOW
                </div>
                <i class="pi pi-arrow-right"></i>
              </router-link>
            </div>
            <div class="w-[345.30px] text-center text-xs font-normal text-white">
              Instant Access | 30-Day Money-Back Guarantee
            </div>
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/6.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            6. Save Time and Money
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Instead of spending hours analyzing matches yourself or paying expensive prediction
            services, access multiple top AI models for less than $1 per day.<br /><br />Bonus: Get
            FREE predictions NOW and get started immediately!
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/7.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            7. True AI Transparency
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-[#010101]">
            Each independent model on 4C Predictions processes thousands of data points per match.
            No more black boxes - see exactly how models perform over time.<br /><br />Shocking
            Fact: Top models on our platform analyze more match data in one second than human
            analysts process in a month!
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/8.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            8. Perfect for Champions League Season
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Editor's Note: Multiple models specialize in European competitions Find models with
            proven track records in international matches. Compare predictions across different
            experts for maximum insight.
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/9.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            9. Join Our Growing Community
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Over 100,000 members use 4C Predictions to find and follow top-performing AI models. Our
            transparent marketplace helps you make informed decisions.<br /><br />
          </div>
        </div>
      </div>
      <div
        class="inline-flex flex-col items-start justify-start gap-[25px] self-stretch md:flex-row"
      >
        <img class="w-full rounded-2xl md:max-w-[340px]" src="/images/landing/soccer/10.svg" />
        <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-6">
          <div class="self-stretch text-[20px] font-semibold leading-10 text-black md:text-[32px]">
            10. Cancel Anytime
          </div>
          <div class="self-stretch text-base font-normal leading-normal text-black">
            Not happy with your model? Cancel anytime—no hassle, no questions, no risk.
          </div>
        </div>
      </div>
      <div
        class="relative flex h-full w-full flex-col items-center justify-center gap-2.5 rounded-2xl bg-gradient-to-r from-[#010516] to-[#334154] px-6 py-6 shadow-[0px_6px_10px_0px_rgba(0,0,0,0.10)]"
      >
        <YoutubePlayerDialog videoId="XFxQf7_2Z5g">
          <template #trigger>
            <img
              src="/images/landing/soccer/video.svg"
              class="cursor-pointer rounded-xl md:max-w-[480px]"
            />
          </template>
        </YoutubePlayerDialog>
        <div class="flex flex-col items-center justify-start gap-3 self-stretch">
          <div class="inline-flex items-center justify-center gap-1.5 self-stretch">
            <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
            <div class="text-center text-lg font-semibold uppercase leading-loose text-[#bdff1c]">
              GET ACCESS
            </div>
            <div class="h-px w-[30px] border-t border-[#bdff1c]"></div>
          </div>
          <div
            class="text-md text-center font-bold uppercase leading-[34px] text-[#bae8f1] md:text-[26px]"
          >
            GET ACCESS TO AI MODELS
          </div>
          <div class="inline-flex items-center justify-center pb-1 pt-[3px]">
            <div class="inline-flex items-center justify-start gap-1 self-stretch">
              <div class="text-base font-bold text-[#e03e3e]">
                Offer Ends In: {{ formattedTime }} |
              </div>
              <div class="text-base font-semibold text-[#e03e3e]">Remaining Spots: 47</div>
            </div>
          </div>
          <div class="flex flex-col items-center justify-start gap-2">
            <div class="flex flex-col items-start justify-start gap-2.5 px-6">
              <router-link
                to="/"
                class="inline-flex items-center justify-start gap-2.5 rounded-lg bg-[#bdff1c] px-10 py-2"
              >
                <div class="text-md text-center font-semibold leading-loose text-black">
                  CLAIM YOUR SPOT NOW
                </div>
                <i class="pi pi-arrow-right"></i>
              </router-link>
            </div>
            <div class="w-[345.30px] text-center text-xs font-normal text-white">
              Instant Access | 30-Day Money-Back Guarantee
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCountdownTimer } from "@/composables/useCountdownTimer";
import YoutubePlayerDialog from "@/ui/components/YoutubePlayerDialog.vue";
import { onMounted, ref, watch } from "vue";

const hours = ref<number>(24);
const minutes = ref<number>(0);
const seconds = ref<number>(0);
const initialSeconds = hours.value * 3600 + minutes.value * 60 + seconds.value;

const { isRunning, formattedTime, startTimer, setTime } = useCountdownTimer(initialSeconds);

const updateTimer = (): void => {
  setTime(hours.value, minutes.value, seconds.value);
};

watch([hours, minutes, seconds], () => {
  if (!isRunning.value) {
    updateTimer();
  }
});

onMounted(() => startTimer());
</script>
