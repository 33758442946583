<script setup lang="ts">
import DefaultBgImage from "@/assets/images/march-mad-bg.jpg";
import PredictionsBgImage from "@/assets/images/march-mandess-predictions-bg.png";
import { onMounted, ref } from "vue";

const bgImage = ref(DefaultBgImage);

const props = defineProps<{
  variant?: string;
}>();

onMounted(() => {
  if (props.variant && props.variant == "get-predictions") {
    bgImage.value = PredictionsBgImage;
  }
});
</script>

<template>
  <div
    class="inline-flex min-h-screen w-full flex-col justify-start bg-slate-100 md:flex-row md:items-center"
  >
    <div
      :style="{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
      class="relative hidden flex-1 flex-col items-start justify-start gap-2.5 self-stretch p-16 md:inline-flex"
    >
      <div
        class="absolute left-0 top-0 z-10 h-full w-full bg-[radial-gradient(ellipse_66.41%_66.41%_at_50.00%_50.00%,_rgba(0,_0,_0,_0)_0%,_black_100%)]"
      ></div>
      <div class="inline-flex items-center justify-start gap-4 self-stretch">
        <div class="absolute z-20 inline-flex flex-col items-start justify-start">
          <div class="relative h-9 self-stretch">
            <div
              class="absolute left-0 top-0 justify-start text-2xl font-bold leading-9 text-[#bdff1c]"
            >
              4C
            </div>
            <div
              class="absolute left-[34px] top-0 justify-start text-2xl font-bold leading-9 text-white"
            >
              Predictions
            </div>
          </div>
          <div class="justify-start">
            <span class="text-sm font-bold leading-tight text-white">The Worlds </span
            ><span class="text-sm font-bold leading-tight text-lime-400"
              >#1 Prediction Marketplace</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="w-full md:hidden">
      <div
        class="inline-flex w-full flex-col items-start justify-start gap-2 self-stretch border-b border-lime-400 bg-zinc-950 p-4"
      >
        <div class="inline-flex items-center justify-start self-stretch">
          <div class="flex flex-1 items-center justify-start gap-4">
            <div class="flex flex-1 items-center justify-start gap-4">
              <div class="inline-flex flex-col items-start justify-start">
                <div class="relative h-9 self-stretch">
                  <div
                    class="absolute left-0 top-0 justify-start font-['Inter'] text-2xl font-bold leading-9 text-[#bdff1c]"
                  >
                    4C
                  </div>
                  <div
                    class="absolute left-[34px] top-0 justify-start font-['Inter'] text-2xl font-bold leading-9 text-white"
                  >
                    Predictions
                  </div>
                </div>
                <div class="justify-start">
                  <span class="font-['Inter'] text-xs font-medium leading-none text-white"
                    >The Worlds </span
                  ><span class="font-['Inter'] text-xs font-medium leading-none text-lime-400"
                    >#1 Prediction Marketplace</span
                  ><span class="font-['Inter'] text-xs font-medium leading-none text-white">.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4">
        <div
          :style="{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
          class="relative h-[149px] w-full rounded-lg"
        >
          <div
            class="absolute left-0 top-0 h-full w-full rounded-lg bg-[radial-gradient(ellipse_50.00%_50.00%_at_50.00%_50.00%,_rgba(2,_6,_23,_0)_0%,_rgba(2,_6,_23,_0.60)_100%)]"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="inline-flex flex-1 flex-col items-center md:justify-center gap-2.5 self-stretch px-4 md:px-[76px]"
    >
      <slot></slot>
    </div>
  </div>
</template>
