<template>
  <div @click="showVideo" class="cursor-pointer">
    <slot name="trigger" />
  </div>
  <Dialog
    :visible="videoVisible"
    class="group relative h-[219px] w-full overflow-hidden border-0 !p-0 md:h-[484px] md:w-[860px]"
    pt:root:class="!border-0 !bg-zinc-950"
    pt:mask:class="bg-black/30"
    append-to="body"
  >
    <template #container>
      <iframe
        width="100%"
        height="100%"
        :src="embedUrl"
        frameborder="0"
        referrerpolicy="no-referrer-when-downgrade"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
      <Button
        @click="videoVisible = false"
        class="absolute bottom-1 left-1 z-20 h-10 border-none bg-black/40 !text-white"
      >
        <i class="pi pi-times"></i>
        <span class="text-sm font-semibold">Close</span>
      </Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  videoId: {
    type: String,
    required: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
});

const videoVisible = ref(false);

const embedUrl = computed(() => {
  const baseUrl = `https://www.youtube-nocookie.com/embed/${props.videoId}`;
  const params = new URLSearchParams({
    autoplay: props.autoPlay ? "1" : "0",
    rel: "0",
    modestbranding: "1",
    controls: "0",
    showinfo: "0",
    fs: "1",
    loop: "0",
    iv_load_policy: "3",
  });

  return `${baseUrl}?${params.toString()}`;
});

const showVideo = () => {
  videoVisible.value = true;
};
</script>
