<template>
  <div
    :class="{ 'mt-3': !isHome }"
    class="mb-2 flex flex-col items-start justify-between gap-2 px-2 md:flex-row md:px-4"
  >
    <div class="scroll-container w-full overflow-hidden overflow-x-auto">
      <div class="inline-flex h-[40px] gap-0 md:h-[33px]">
        <Button
          text
          v-for="(item, index) in subTypes"
          :key="index"
          @click="setSubType(item)"
          :class="`flex items-center text-nowrap rounded-md px-3 text-sm font-medium ${selectedSubType == item.slug ? 'bg-slate-200 text-slate-800' : 'bg-transparent text-slate-600'}`"
        >
          {{ item.name }}
        </Button>
      </div>
    </div>
  </div>

  <div v-if="route.params.type == 'march-madness'" class="mb-4 items-center px-4 text-center">
    <div class="mb-4 grid grid-cols-1 items-stretch gap-4 lg:grid-cols-4">
      <img
        src="/images/mm-hero.svg"
        alt="March Madness"
        class="h-36 w-full rounded-xl object-cover md:h-full lg:col-span-3"
      />
      <LeaderBoard />
    </div>
    <BracketDownloanButtons />
  </div>

  <div class="mb-10 p-2 pb-8 md:px-4 md:py-2">
    <div v-if="!loading || page > 1">
      <div v-if="events.length > 0"></div>
      <div v-if="unrelatedEvents.length > 0 || events.length > 0">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <EventCard v-for="(event, index) in events" :event="event" :key="index" />
        </div>
      </div>
    </div>
    <div
      v-if="loading && page == 1"
      class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <EventCardSkeleton v-for="(_, index) in Array.from({ length: 8 })" :key="index" />
    </div>
    <div v-if="hasMore" class="my-4 flex items-center justify-center self-stretch">
      <Button
        v-on:click="loadMore()"
        :disabled="loading"
        class="flex items-center justify-start gap-[7px] rounded-md border border-lime-400 bg-lime-400 px-[10.50px] py-[7px] text-sm"
      >
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
        <span>Show More</span>
      </Button>
    </div>
    <div
      v-if="
        !loading &&
        events.length == 0 &&
        unrelatedEvents.length == 0 &&
        route.params.type != 'football'
      "
      class="items-startflex h-[33px] justify-center rounded-md border border-yellow-200 shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
    >
      <div
        class="flex h-[31px] shrink grow basis-0 items-center justify-center gap-[7px] bg-yellow-50/95 px-[10.50px] py-[7px] shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
      >
        <div class="relative overflow-hidden">
          <SvgIcon name="info" />
        </div>
        <div>
          <span class="font-['Inter'] text-sm font-medium text-yellow-600"
            >No events were found</span
          >
        </div>
      </div>
    </div>
    <div
      v-else-if="
        !loading &&
        events.length == 0 &&
        unrelatedEvents.length == 0 &&
        route.params.type == 'football'
      "
      class="flex shrink grow basis-0 flex-col justify-center gap-[7px] bg-yellow-50/95 px-[10.50px] py-[7px] shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)] md:items-center"
    >
      <div>🏈 The NFL Season is Over… But the Predictions Keep Coming!</div>
      <div>🔥 Last season, the top NFL AI model hit over 77% accuracy! 🔥</div>
      <div>
        Want to stay ahead of the game? Explore AI predictions in other categories and keep winning.
      </div>
      <!-- <router-link to="/events/all">👉 Discover More Predictions</router-link> -->
      <Button
        v-on:click="router.push({ name: 'home' })"
        label="Discover More Predictions"
        class="h-9 text-sm font-medium max-sm:w-full sm:w-auto"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore, type EventType } from "@/stores/eventTypesStore";
import type { Event } from "@/types/event";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import { showGlobalToast } from "@/utils/toast";
// import { useDebounceFn } from "@vueuse/core";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import BracketDownloanButtons from "../BracketDownloanButtons.vue";
import LeaderBoard from "../march-madness/LeaderBoard.vue";
import EventCard from "./EventCard.vue";
import EventCardSkeleton from "./EventCardSkeleton.vue";

const eventStore = useEventStore();
const eventTypesStore = useEventTypesStore();
const route = useRoute();
const searchQuery = ref(route.query.q ? (route.query.q as string) : "");
const selectedSubType = ref("");
const selectedType = ref("");
const subTypes = ref<EventType[]>([]);
const events = ref<Event[]>([]);
// const userEvents = ref<Event[]>([]);
const unrelatedEvents = ref<Event[]>([]);
const page = ref(1);
const loading = ref(false);
const hasMore = ref(false);

const isHome = ref<boolean>((route.name as string).toLocaleLowerCase() == "home");

const pageSize = 16;

console.log(route.params.type);

watch(
  () => route.params.subType,
  () => {
    setSelectedSubType();
    resetSearch();
  },
);

watch(
  () => route.params.type,
  () => {
    setSelectedType();
    resetSearch();
  },
);

const setSubType = (item: EventType) => {
  console.log(item.slug);
  searchQuery.value = "";
  router.replace({ name: "events", params: { type: item.parent.slug, subType: item.slug } });
};

const fetchItems = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const result = await eventStore.fetchEvents({
      q: searchQuery.value,
      type: route.params.type ? (route.params.type as string) : "",
      sub_type: route.params.subType ? (route.params.subType as string) : "",
      per_page: pageSize.toString(),
      page: page.value.toString(),
    });

    if (page.value === 1) {
      events.value = result.events;
    } else {
      //ToDo try fix this
      events.value = [
        ...new Map([...events.value, ...result.events].map((event) => [event.id, event])).values(),
      ];
    }

    hasMore.value = result.more;

    console.log(getDuplicateEvents());

    // if (isHome.value) {
    //   arrangeEvents();
    // }
    page.value++;
  } catch (error) {
    console.error("Error fetching items:", error);
    showGlobalToast("error", "", "Error fetching items");
  } finally {
    loading.value = false;
  }
};

function getDuplicateEvents(): Record<number | string, Event[]> {
  const seenIds = new Map<number | string, Event[]>(); // Store events by ID
  const duplicates: Record<number | string, Event[]> = {}; // Well-formatted duplicate list

  for (const event of events.value) {
    if (!seenIds.has(event.id)) {
      seenIds.set(event.id, []);
    }
    seenIds.get(event.id)!.push(event);
  }

  // Filter only those IDs that have duplicates
  for (const [id, eventList] of seenIds.entries()) {
    if (eventList.length > 1) {
      duplicates[id] = eventList;
    }
  }

  return duplicates;
}

const loadMore = () => {
  fetchItems();
};

// const debouncedSearch = useDebounceFn(() => {
//   resetSearch();
// }, 300);

// const debounceSearch = () => {
//   debouncedSearch();
// };

const resetSearch = () => {
  page.value = 1;
  events.value = [];
  hasMore.value = true;
  fetchItems();
};

// const arrangeEvents = () => {
//   userEvents.value = events.value.filter((item) => item.is_user_interest).slice(0, 4);
//   unrelatedEvents.value = events.value.filter((item) => !item.is_user_interest);
// };

const setSelectedType = () => {
  if (route.params.type) {
    selectedType.value = route.params.type as string;
  }
  if (!route.params.subType) {
    selectedSubType.value = "";
  }
};

const setSelectedSubType = () => {
  if (route.params.subType) {
    selectedSubType.value = route.params.subType as string;
  }
};

const fetchTypes = () => {
  subTypes.value = eventTypesStore.getAllChildren();
};

watch(
  () => eventTypesStore.types,
  () => fetchTypes(),
  { immediate: true },
);

onMounted(() => {
  resetSearch();
});
</script>
