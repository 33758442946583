export function useCookieTracking() {
  function setCookie(name: string, value: string, days: number) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + "=" + encodeURIComponent(value) + ";expires=" + expires + ";path=/;SameSite=Lax;Secure";
  }

  function getCookieValue(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const url = new URL(window.location.href);
  const aid = url.searchParams.get("a_aid");
  const bid = url.searchParams.get("a_bid");
  const did = url.searchParams.get("a_did");
  const click_id = url.searchParams.get("click_id");
  const gclid = url.searchParams.get("gclid");

  const share_a_sale_id_cookie = url.searchParams.get("sscid");
  const cactus_media_data_1_cookie = url.searchParams.get("data1");
  const cactus_media_data_2_cookie = url.searchParams.get("data2");

  const utm_source = url.searchParams.get("utm_source");
  const utm_medium = url.searchParams.get("utm_medium");
  const utm_campaign = url.searchParams.get("utm_campaign");
  const utm_term = url.searchParams.get("utm_term");
  const utm_content = url.searchParams.get("utm_content");

  if (aid) setCookie("4c_affiliate_aid", aid, 7);
  if (bid) setCookie("4c_affiliate_bid", bid, 7);
  if (did) setCookie("4c_affiliate_did", did, 7);
  if (click_id) setCookie("4c_click_id", click_id, 7);
  if (gclid) setCookie("4c_Google_GCLID", gclid, 7);

  const params: Record<string, string> = {};
  if (utm_source) params.utm_source = utm_source;
  if (utm_medium) params.utm_medium = utm_medium;
  if (utm_campaign) params.utm_campaign = utm_campaign;
  if (utm_term) params.utm_term = utm_term;
  if (utm_content) params.utm_content = utm_content;

  if (Object.keys(params).length) {
    setCookie("4c_params", encodeURIComponent(JSON.stringify(params)), 30);
  }

  if (share_a_sale_id_cookie) setCookie("4c_share_a_sale_id", share_a_sale_id_cookie, 7);
  if (cactus_media_data_1_cookie) setCookie("4c_cactus_media_data_1", cactus_media_data_1_cookie, 7);
  if (cactus_media_data_2_cookie) setCookie("4c_cactus_media_data_2", cactus_media_data_2_cookie, 7);

  // isFreeTrial.value = (getCookieValue("4c_affiliate_did") === "ft");

  function getTrackingCookies(): Record<string, string> {
    const trackingCookies: Record<string, string> = {};

    if (getCookieValue("_ga")) trackingCookies._ga = getCookieValue("_ga");
    if (getCookieValue("_fb")) trackingCookies._fb = getCookieValue("_fb");
    if (getCookieValue("_fbp")) trackingCookies._fbp = getCookieValue("_fbp");
    if (getCookieValue("_fbc")) trackingCookies._fbc = getCookieValue("_fbc");
    if (getCookieValue("fbclid")) trackingCookies.fbclid = getCookieValue("fbclid");
    // if (getCookieValue("_ga_L6K37PYL28")) trackingCookies._ga_L6K37PYL28 = getCookieValue("_ga_L6K37PYL28");
    // if (getCookieValue("_ga_RWR9DVFFMY")) trackingCookies._ga_RWR9DVFFMY = getCookieValue("_ga_RWR9DVFFMY");
    if (getCookieValue("_ga_J58FN3GBCZ")) trackingCookies._ga_J58FN3GBCZ = getCookieValue("_ga_J58FN3GBCZ");
    // if (getCookieValue("_ga_Q1RSGTT8ZM")) trackingCookies._ga_Q1RSGTT8ZM = getCookieValue("_ga_Q1RSGTT8ZM");

    if (getCookieValue("4c_params")) trackingCookies.params = decodeURIComponent(decodeURIComponent(getCookieValue("4c_params")));

    if (getCookieValue("4c_affiliate_aid")) trackingCookies.a_aid = getCookieValue("4c_affiliate_aid");
    if (getCookieValue("4c_affiliate_bid")) trackingCookies.a_bid = getCookieValue("4c_affiliate_bid");
    if (getCookieValue("4c_affiliate_did")) trackingCookies.a_did = getCookieValue("4c_affiliate_did");
    if (getCookieValue("4c_click_id")) trackingCookies.click_id = getCookieValue("4c_click_id");
    if (getCookieValue("4c_Google_GCLID")) trackingCookies.gclid = getCookieValue("4c_Google_GCLID");
    if (getCookieValue("4c_share_a_sale_id")) trackingCookies.share_a_sale_id = getCookieValue("4c_share_a_sale_id");
    if (getCookieValue("4c_cactus_media_data_1")) trackingCookies.cactus_media_data_1 = getCookieValue("4c_cactus_media_data_1");
    if (getCookieValue("4c_cactus_media_data_2")) trackingCookies.cactus_media_data_2 = getCookieValue("4c_cactus_media_data_2");
    if (getCookieValue("4c_pap_visitor_id")) trackingCookies.pap_visitor_id = getCookieValue("4c_pap_visitor_id");

    if (getCookieValue("free_model_unlock")) trackingCookies.free_model_unlock = getCookieValue("free_model_unlock");

    return trackingCookies;
  }

  return {
    getTrackingCookies
  };
}
