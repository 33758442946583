<script setup lang="ts">
import { useEventStore } from "@/stores/eventStore";
import type { Event } from "@/types/event";
import SvgIcon from "@/ui/components/SvgIcon.vue";
import EventCard from "@/ui/components/events/EventCard.vue";
import EventCardSkeleton from "@/ui/components/events/EventCardSkeleton.vue";
import { showGlobalToast } from "@/utils/toast";
import { onMounted, ref } from "vue";
import PlainLayout from "../layouts/PlainLayout.vue";

const eventStore = useEventStore();
const events = ref<Event[]>([]);
const page = ref(1);
const loading = ref(false);
const hasMore = ref(false);

const pageSize = 16;

const fetchItems = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const result = await eventStore.fetchEvents({
      per_page: pageSize.toString(),
      page: page.value.toString(),
      filter: "free-predictions",
    });

    if (page.value === 1) {
      events.value = result.events;
    } else {
      events.value = [
        ...new Map([...events.value, ...result.events].map((event) => [event.id, event])).values(),
      ];
    }

    hasMore.value = result.more;
    page.value++;
  } catch (error) {
    console.error("Error fetching items:", error);
    showGlobalToast("error", "", "Error fetching items");
  } finally {
    loading.value = false;
  }
};

const loadMore = () => {
  fetchItems();
};

onMounted(() => {
  page.value = 1;
  fetchItems();
});
</script>

<template>
  <PlainLayout>
    <div class="mb-2 px-2 md:px-4">
      <h1 class="my-2 text-[18px] font-semibold text-slate-700 md:text-[22px]">Free Predictions</h1>
      <div class="mb-10 w-full p-2 px-0 pb-8 md:py-2">
        <div v-if="!loading || page > 1">
          <div v-if="events.length > 0"></div>
          <div v-if="events.length > 0">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <EventCard v-for="(event, index) in events" :event="event" :key="index" />
            </div>
          </div>
        </div>
        <div
          v-if="loading && page == 1"
          class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <EventCardSkeleton v-for="(_, index) in Array.from({ length: 8 })" :key="index" />
        </div>
        <div v-if="hasMore" class="my-4 flex items-center justify-center self-stretch">
          <Button
            v-on:click="loadMore()"
            :disabled="loading"
            class="flex items-center justify-start gap-[7px] rounded-md border border-lime-400 bg-lime-400 px-[10.50px] py-[7px] text-sm"
          >
            <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
            <span>Show More</span>
          </Button>
        </div>
        <div
          v-if="!loading && events.length == 0"
          class="items-startflex h-[33px] justify-center rounded-md border border-yellow-200 shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
        >
          <div
            class="flex h-[31px] shrink grow basis-0 items-center justify-center gap-[7px] bg-yellow-50/95 px-[10.50px] py-[7px] shadow-[0px_4px_8px_0px_rgba(9,7,0,0.04)]"
          >
            <div class="relative overflow-hidden">
              <SvgIcon name="info" />
            </div>
            <div>
              <span class="font-['Inter'] text-sm font-medium text-yellow-600"
                >No events were found</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </PlainLayout>
</template>
