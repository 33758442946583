<script setup lang="ts">
import MensFile from "@/assets/files/4C_Predictions_March_Madness_Mens_Bracket_Final.pdf";
import WomensFile from "@/assets/files/4C_Predictions_March_Madness_Womens_Bracket_Final.pdf";
import MensSPFile from "@/assets/files/Sean_Perry_March_Madness_Mens_Bracket_Final.pdf";
import WomensSPFile from "@/assets/files/Sean_Perry_March_Madness_Womens_Bracket_Final.pdf";
import LogoImage from "@/assets/images/4c-image.png";
import SpImage from "@/assets/images/sp-image.png";
import { useAuthStore } from "@/stores/authStore";
import { showGlobalToast } from "@/utils/toast";
import { watch } from "vue";
import { useRoute } from "vue-router";
import DownloadIcon from "./icons/DownloadIcon.vue";

const authStore = useAuthStore();
const route = useRoute();

const downloadFile = (filePath: string, fileName: string, skipAuth: boolean = false) => {
  if (!authStore.user && !skipAuth) {
    authStore.setDownloadOnAuth({
      filePath,
      fileName,
    });
    authStore.setActiveModal("join");
    authStore.setAuthModalVisible(true);
    return;
  }

  const link = document.createElement("a");
  link.href = filePath;
  link.setAttribute("download", fileName);
  link.click();
  showGlobalToast("success", "", `${fileName} downloaded`);
};

watch(
  () => route.query.download,
  () => {
    if (route.query.download) {
      const file = (route.query.download as string).toLowerCase();
      switch (file) {
        case "mens":
          downloadFile(MensFile, "4C_Predictions_March_Madness_Mens_Bracket_Final.pdf", true);
          break;
        case "womens":
          downloadFile(WomensFile, "4C_Predictions_March_Madness_Womens_Bracket_Final.pdf", true);
          break;
        case "mens-sp":
          downloadFile(MensSPFile, "Sean_Perry_March_Madness_Mens_Bracket_Final.pdf", true);
          break;
        case "womens-sp":
          downloadFile(WomensSPFile, "Sean_Perry_March_Madness_Womens_Bracket_Final.pdf", true);
          break;
      }
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="mt-2 grid grid-cols-1 gap-2 lg:grid-cols-4 lg:gap-4">
    <div
      class="inline-flex items-center justify-center gap-2 self-stretch overflow-hidden rounded-xl bg-white p-4 outline outline-1 outline-offset-[-1px] outline-slate-200"
    >
      <div class="flex h-[42px] flex-1 items-center justify-start gap-2">
        <div
          :style="{
            backgroundImage: `url(${LogoImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
          }"
          class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
        ></div>
        <div class="justify-start text-start text-sm font-bold leading-none text-slate-700">
          4C March Madness Men's Bracket.
        </div>
      </div>
      <Button
        @click="downloadFile(MensFile, '4C_Predictions_March_Madness_Mens_Bracket_Final.pdf')"
        class="flex !h-[35px] !w-[35px] items-center justify-center rounded-md border-sky-500 bg-sky-500 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="relative h-3.5 w-3.5">
          <DownloadIcon class="h-3.5 w-3.5" />
        </div>
      </Button>
    </div>

    <div
      class="inline-flex items-center justify-center gap-2 self-stretch overflow-hidden rounded-xl bg-white p-4 outline outline-1 outline-offset-[-1px] outline-slate-200"
    >
      <div class="flex h-[42px] flex-1 items-center justify-start gap-2">
        <div
          :style="{
            backgroundImage: `url(${LogoImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
          }"
          class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
        ></div>
        <div class="justify-start text-start text-sm font-bold leading-none text-slate-700">
          4C March Madness Women's Bracket.
        </div>
      </div>
      <Button
        @click="downloadFile(WomensFile, '4C_Predictions_March_Madness_Womens_Bracket_Final.pdf')"
        class="flex !h-[35px] !w-[35px] items-center justify-center rounded-md border-sky-500 bg-sky-500 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="relative h-3.5 w-3.5">
          <DownloadIcon class="h-3.5 w-3.5" />
        </div>
      </Button>
    </div>

    <div
      class="inline-flex items-center justify-center gap-2 self-stretch overflow-hidden rounded-xl bg-white p-4 outline outline-1 outline-offset-[-1px] outline-slate-200"
    >
      <div class="flex h-[42px] flex-1 items-center justify-start gap-2">
        <div
          :style="{
            backgroundImage: `url(${SpImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
          }"
          class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
        ></div>
        <div class="justify-start text-start text-sm font-bold leading-none text-slate-700">
          Sean Perry's March Madness Men's Bracket.
        </div>
      </div>
      <Button
        @click="downloadFile(MensSPFile, 'Sean_Perry_March_Madness_Mens_Bracket_Final.pdf')"
        class="flex !h-[35px] !w-[35px] items-center justify-center rounded-md border-sky-500 bg-sky-500 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="relative h-3.5 w-3.5">
          <DownloadIcon class="h-3.5 w-3.5" />
        </div>
      </Button>
    </div>

    <div
      class="inline-flex items-center justify-center gap-2 self-stretch overflow-hidden rounded-xl bg-white p-4 outline outline-1 outline-offset-[-1px] outline-slate-200"
    >
      <div class="flex h-[42px] flex-1 items-center justify-start gap-2">
        <div
          :style="{
            backgroundImage: `url(${SpImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
          }"
          class="inline-flex h-[42px] w-[42px] flex-col items-center justify-center rounded-md"
        ></div>
        <div class="justify-start text-start text-sm font-bold leading-none text-slate-700">
          Sean Perry's March Madness Women's Bracket.
        </div>
      </div>
      <Button
        @click="downloadFile(WomensSPFile, 'Sean_Perry_March_Madness_Womens_Bracket_Final.pdf')"
        class="flex !h-[35px] !w-[35px] items-center justify-center rounded-md border-sky-500 bg-sky-500 outline outline-1 outline-offset-[-1px] outline-sky-500"
      >
        <div class="relative h-3.5 w-3.5">
          <DownloadIcon class="h-3.5 w-3.5" />
        </div>
      </Button>
    </div>
  </div>
</template>
