<template>
  <div
    v-if="!eventTypesStore.loading"
    class="scroll-container inline-flex w-full gap-6 overflow-hidden overflow-x-auto transition-all duration-300"
  >
    <div
      v-for="item in navItems"
      :key="item.title"
      class="flex items-center justify-start gap-[7px] rounded-md py-[7px]"
    >
      <router-link
        v-if="item.link && !item.disabled"
        :to="item.link"
        :class="`text-nowrap text-sm ${isSelectedItem(item) ? 'text-primary' : 'text-white'} hover:text-primary`"
        >{{ item.title }}</router-link
      >
      <div
        v-if="item.disabled"
        :to="item.link"
        :class="`flex items-center gap-1 text-nowrap text-sm ${isSelectedItem(item) ? 'text-primary' : 'text-[#a1a1aa]'}`"
      >
        <span>{{ item.title }}</span>
        <Tag
          value="Coming Soon"
          class="rounded-[6px] bg-[#84cc16] py-0 text-[11px] text-[#18181b]"
        ></Tag>
      </div>
      <span v-if="!item.link" class="text-nowrap text-sm text-white">{{ item.title }}</span>
      <div v-if="item.icon" class="relative h-3.5 w-3.5 overflow-hidden">
        <SvgIcon :name="item.icon" />
      </div>
    </div>
  </div>
  <div
    v-if="eventTypesStore.loading"
    class="inline-flex min-h-[22px] w-full gap-6 overflow-hidden overflow-x-auto transition-all duration-300"
  >
    <Skeleton
      v-for="(item, index) in Array.from({ length: 5 })"
      :key="index"
      height="6px"
      :style="{ backgroundColor: '#000' }"
      width="80px"
      class="flex items-center justify-start gap-[7px] rounded-md"
    />
  </div>
</template>

<script setup lang="ts">
import { useEventStore } from "@/stores/eventStore";
import { useEventTypesStore } from "@/stores/eventTypesStore";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import SvgIcon from "../components/SvgIcon.vue";

const eventTypesStore = useEventTypesStore();
const eventStore = useEventStore();
const navItems = ref();
const selectedType = ref("");
const route = useRoute();
const currentEventType = ref(null);

const setNavigation = async () => {
  const defaultNavItems = [
    {
      title: "Home",
      slug: "",
      disabled: false,
      icon: null,
      link: "/",
      routeName: "home",
    },
    {
      title: "All Ai Models",
      slug: "models",
      disabled: false,
      icon: null,
      link: "/models",
      routeName: "models",
    },
    {
      title: "Creators",
      slug: "creators",
      disabled: false,
      icon: "creators",
      link: "/creators",
      routeName: "creators",
    },
    {
      title: "|",
      slug: null,
      disabled: false,
      icon: "",
      link: null,
    },
  ];

  const disabledNavItems = [
    {
      title: "Free Predictions",
      slug: "free-predictions",
      link: "/free-predictions",
      routeName: "free-predictions",
    },
    {
      title: "Crypto",
      slug: "",
      disabled: true,
      icon: null,
      link: "/",
    },
    // {
    //   title: "Politics",
    //   slug: "",
    //   disabled: true,
    //   icon: null,
    //   link: "/",
    // },
    {
      title: "Stocks",
      slug: "",
      disabled: true,
      icon: null,
      link: "/",
    },
  ];

  navItems.value = [
    ...defaultNavItems,
    ...eventTypesStore.types.map((item) => {
      return {
        title: item.name,
        slug: item.slug,
        disabled: false,
        icon: null,
        link: `/events/${item.slug}`,
      };
    }),
    ...disabledNavItems,
  ];
};

watch(
  () => route.params.type,
  () => {
    if (route.params.type && route.meta?.type != "models") {
      selectedType.value = route.params.type as string;
    }
  },
  { immediate: true },
);

watch(
  () => route.meta,
  () => {
    if (!selectedType.value && route.meta?.type) {
      selectedType.value = route.meta?.type as string;
    }
  },
  { immediate: true },
);

watch(
  () => eventTypesStore.types,
  (types) => {
    if (types.length > 0) {
      setNavigation();
    }
  },
  { immediate: true },
);

watch(
  () => eventStore.currentEvent,
  () => {
    currentEventType.value = eventTypesStore.getChildTypeById(
      eventStore.currentEvent?.event_sub_type_id,
    );
    setNavigation();
  },
  { immediate: true },
);

function isSelectedItem(item) {
  return (
    route.name.toString().toLowerCase() == item.routeName ||
    (selectedType.value && item.slug == selectedType.value) ||
    (selectedType.value && item.slug == eventTypesStore.currentActiveType?.slug) ||
    (selectedType.value && item.slug == currentEventType?.value?.parent.slug)
  );
}

onMounted(() => {
  setNavigation();
});
</script>
