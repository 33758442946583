<template>
  <PlainLayout>
    <div className="max-w-4xl mx-auto px-6 py-4 md:py-12">
      <div class="mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-lg md:px-10">
        <h1 class="mb-6 text-center text-3xl font-bold">Submit a bug</h1>
        <p class="mb-4 text-sm text-slate-700">
          Spotted a glitch in the matrix? Let us know, and if it’s a real bug, you might just score
          a reward! 🚀
        </p>
        <form @submit.prevent="submit">
          <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
            <div
              class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
            >
              <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
                Email Address
              </div>
              <InputText
                v-model="emailAddress"
                :readonly="authStore.isAuthenticated"
                :class="{ 'p-invalid': v$.emailAddress.$invalid && v$.emailAddress.$dirty }"
              />
              <div class="mt-2 self-stretch text-xs font-semibold leading-none text-slate-700">
                Message
              </div>
              <Textarea
                v-model="message"
                rows="5"
                :class="{ 'p-invalid': v$.message.$invalid && v$.message.$dirty }"
                placeholder="Enter bug details..."
                class="w-full border-slate-300 bg-white px-[10.50px] py-[7px] text-sm font-normal text-slate-500 shadow-[0px_1px_2px_0px_rgba(18,18,23,0.05)]"
              />
            </div>
          </div>
          <Button type="submit" class="mt-4 w-full px-12 py-[7px] md:w-auto">
            <i v-if="loading" class="pi pi-spin pi-spinner"></i>
            <div class="text-sm font-medium text-slate-700">Submit</div>
          </Button>
        </form>
      </div>
    </div>
  </PlainLayout>
</template>
<script setup lang="ts">
import PlainLayout from "../layouts/PlainLayout.vue";

import { userApi } from "@/api/userApi";
import { useAuthStore } from "@/stores/authStore";
import { showGlobalToast } from "@/utils/toast";
import { useVuelidate } from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { computed, ref, watch } from "vue";

const authStore = useAuthStore();
const loading = ref(false);
const emailAddress = ref("");
const message = ref("");

const rules = computed(() => ({
  emailAddress: { required, email },
  message: { required, minLength: minLength(3) },
}));

const v$ = useVuelidate(rules, {
  emailAddress,
  message,
});

watch(
  () => authStore.user,
  () => {
    if (authStore.user) {
      emailAddress.value = authStore.user?.email;
    }
  },
  { immediate: true },
);

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    loading.value = true;
    try {
      const result = await userApi.submitABug({
        email: emailAddress.value,
        message: message.value,
      });

      if (result) {
        showGlobalToast("success", "Thank you", "Your message has be submitted");
      }
    } finally {
      loading.value = false;
    }
  }
};
</script>
