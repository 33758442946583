<template>
  <div>
    <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1841_19539)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.07578 2.05975C7.34033 1.7952 7.34033 1.36628 7.07578 1.10173C6.81123 0.837183 6.38231 0.837183 6.11777 1.10173L0.698412 6.52109C0.433863 6.78564 0.433863 7.21455 0.698412 7.4791L6.11777 12.8985C6.38231 13.163 6.81123 13.163 7.07578 12.8985C7.34033 12.6339 7.34033 12.205 7.07578 11.9404L2.81285 7.67751H13.8226C14.1967 7.67751 14.5 7.37422 14.5 7.00009C14.5 6.62597 14.1967 6.32267 13.8226 6.32267H2.81285L7.07578 2.05975Z"
          fill="#020617"
        />
      </g>
      <defs>
        <clipPath id="clip0_1841_19539">
          <rect x="0.5" width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
