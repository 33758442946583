<template>
  <form @submit.prevent="submit">
    <div class="flex w-full flex-col items-start justify-start gap-2.5 self-stretch">
      <div
        class="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-[7px]"
      >
        <div class="self-stretch text-xs font-semibold leading-none text-slate-700">
          New Password
        </div>
        <Password
          v-model="password"
          :feedback="false"
          :class="{ 'p-invalid': v$.password.$invalid && v$.password.$dirty }"
          toggleMask
          placeholder="Enter your password"
          class="h-[33px] w-full border-slate-300 bg-white text-sm font-normal text-slate-500"
        />
      </div>
    </div>
    <Button type="submit" class="mt-4 w-full px-[10.50px] py-[7px]">
      <i v-if="authStore.isLoading" class="pi pi-spin pi-spinner"></i>
      <div class="text-sm font-medium text-slate-700">Reset Password</div>
    </Button>
  </form>
</template>

<script setup lang="ts">
import { authApi } from "@/api/authApi";
import { useAuthStore } from "@/stores/authStore";
import { showGlobalToast } from "@/utils/toast";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const authStore = useAuthStore();
const route = useRoute();

const hash = ref(route.params.hash);
const email = ref(route.query.email);
const password = ref("");

const router = useRouter();

const rules = computed(() => ({
  password: { required },
}));

const v$ = useVuelidate(rules, {
  password,
});

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    authStore.setIsLoading(true);
    try {
      const result = await authApi.reset({
        token: hash.value,
        email: email.value,
        password: password.value,
      });

      if (result.status === 204) {
        router.replace({ name: "home" }).then(() => {
          showGlobalToast(
            "success",
            "Password changed",
            "You can now login using your new password.",
          );
          authStore.setAuthModalVisible(true);
          authStore.setActiveModal("login");
        });
      }
    } finally {
      authStore.setIsLoading(false);
    }
  }
};
</script>
