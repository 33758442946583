<template>
  <PlainLayout>
    <EventList />
  </PlainLayout>
</template>

<script setup lang="ts">
import EventList from "@/ui/components/events/EventList.vue";
import PlainLayout from "@/ui/layouts/PlainLayout.vue";
import StringHelper from "@/utils/stringHelper";
import { inject, watch } from "vue";
import { useRoute } from "vue-router";

const updateMeta = inject("updateMeta") as (meta) => void;
const route = useRoute();

watch(
  () => route.params.type,
  () => {
    if (route.params.type) {
      const type = route.params.type as string;
      updateMeta({
        title: `4CPredictions - ${StringHelper.ucFirst(type)} Events`,
      });
    }
  },
  { immediate: true },
);
</script>
