import { authApi } from "@/api/authApi";
import type { User } from "@/types/user";
import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const session_user = useLocalStorage("4c_ath_tkn", null);
  const user = ref<User | null>(session_user.value ? JSON.parse(session_user.value).user : null);
  const isAuthenticated = computed(() => !!user.value);
  const isLoaded = ref(false);
  const isLoading = ref(false);
  const activeAuthModal = ref("join");
  const authModalVisible = ref(false);
  const authAndGoPay = ref(false);
  const downloadOnAuth = ref(null);
  const userIp = ref(null);
  const selectedModel = ref(null)

  // New property to store the redirect URL
  const redirectUrl = ref<string>("");

  function setDownloadOnAuth(value: Record<string, string>) {
    downloadOnAuth.value = value;
  }

  function setSelectedModel(value: string) {
    selectedModel.value = value;
  }

  function setActiveModal(value: string) {
    activeAuthModal.value = value;
  }

  function setAuthAndGoPay(value: boolean) {
    authAndGoPay.value = value;
  }

  function setAuthModalVisible(value: boolean) {
    authModalVisible.value = value;
  }

  function setIsLoading(loading: boolean) {
    isLoading.value = loading;
  }

  // New setter to update the redirect URL
  function setRedirectUrl(url: string) {
    redirectUrl.value = url;
  }

  async function getUserIP() {
    if (userIp.value) {
        return userIp.value;
    }
    try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        userIp.value = data.ip
        return data.ip;
    } catch ({}) {
        userIp.value = null;
        return null;
    }
}

  const setUser = (userData: User | null) => {
    user.value = userData;
    if (!user.value) {
      session_user.value = null;
    }
  };

  const fetchUser = async () => {
    await getUserIP();
    try {
      const userData = await authApi.fetchUser();
      setUser(userData);
    } catch (error) {
      setUser(null);
      console.log((error as Error).toString());
    }
    isLoaded.value = true;
  };

  const logout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
      console.log((error as Error).toString());
    }
    setUser(null);
  };

  watch(
    () => session_user.value,
    () => {
      if (!session_user.value) {
        setUser(null);
      }
    },
    { immediate: true },
  );

  return {
    user,
    isAuthenticated,
    isLoaded,
    isLoading,
    activeAuthModal,
    authModalVisible,
    redirectUrl,
    userIp,
    authAndGoPay,
    downloadOnAuth,
    selectedModel,
    setRedirectUrl,    // Expose the setter
    setIsLoading,
    setUser,
    logout,
    fetchUser,
    setActiveModal,
    setAuthModalVisible,
    setAuthAndGoPay,
    setDownloadOnAuth,
    setSelectedModel,
  };
});
