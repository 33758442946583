<template>
  <div
    @click="last10PredictionsModalVisible = true"
    class="mt-1 flex w-full cursor-pointer items-start justify-between px-4 md:hidden"
  >
    <div class="inline-flex items-start justify-start gap-1">
      <div class="flex items-center justify-start gap-2">
        <div
          class="justify-start text-xs font-bold uppercase leading-3 tracking-tight text-slate-500"
        >
          Last 10 Predictions
        </div>
      </div>
      <i class="pi pi-info-circle h-2.5 w-2.5 text-[12px] font-bold text-slate-500"></i>
    </div>
    <div class="inline-flex h-4 max-w-[150px] items-center justify-between">
      <div
        v-for="(pd, index) in ModelHelper.getCleanLastPredictions(last10PredictionResults)"
        :key="index"
        :class="pd === true ? 'bg-[#22C55E]' : pd === null ? 'bg-slate-300' : 'bg-[#EF4444]'"
        class="mr-[1px] inline-flex h-3 w-3 flex-col items-center justify-center rounded-[10.50px]"
      ></div>
    </div>
  </div>

  <Dialog
    v-model:visible="last10PredictionsModalVisible"
    class="w-full max-w-[380px] md:max-w-[600px]"
    :modal="true"
    :closable="false"
    :closeOnEscape="true"
    :dismissableMask="true"
  >
    <template #header>
      <div class="inline-flex w-full items-start justify-between gap-4">
        <div
          class="shrink grow basis-0 font-['Inter'] text-lg font-bold leading-loose text-slate-700"
        >
          Last 10 Predictions
        </div>
        <Button
          @click="last10PredictionsModalVisible = false"
          class="flex h-[33px] w-[33px] items-center justify-center rounded-md border border-slate-200 bg-transparent p-0"
        >
          <div class="relative h-3.5 w-3.5 overflow-hidden">
            <SvgIcon name="close" />
          </div>
        </Button>
      </div>
    </template>
    <div class="py-2">
      This shows how the model has performed in its last 10 predictions. Green means a correct
      prediction, while red means an incorrect one.
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ModelHelper } from "@/utils/modelHelper";
import { ref } from "vue";
import SvgIcon from "../SvgIcon.vue";

defineProps<{
  last10PredictionResults: boolean[] | null;
}>();

const last10PredictionsModalVisible = ref(false);
</script>
