<script setup lang="ts">
import { useErrorStore } from "@/stores/errorStore";
import { useRouter } from "vue-router";
import PlainLayout from "../../layouts/PlainLayout.vue";

const errorStore = useErrorStore();
const router = useRouter();

const goHome = () => {
  errorStore.clearError();
  router.push({ name: "home" });
};
</script>

<template>
  <PlainLayout>
    <div class="flex h-full flex-col items-center justify-center py-24 text-center">
      <h1 class="text-4xl font-bold text-gray-800">404</h1>
      <p class="text-lg text-gray-600">{{ errorStore.error ?? "Page not found" }}</p>
      <Button @click="goHome" class="mt-4 h-8 !text-sm !font-medium">Go home</Button>
    </div>
  </PlainLayout>
</template>
